import {
  IReservationEquipmentDto,
  ReservationDetailsState,
  ReservationSettingsState,
  ServiceSettingsState,
  UserTrainingReservationData,
} from '../slice/types';
import { GlobalSettingsType } from '../components/useGlobalSettingsHook';

export function getTutoringRequired({
  equipmentSettings,
  globalSettings,
  reservationSettings,
  assistedTrainingData,
  isTrainingSignUp,
}: {
  equipmentSettings?: Pick<
    ServiceSettingsState,
    'IsEquipmentTutoring' | 'ForceTutoring' | 'IsAllAdmin'
  >;
  globalSettings: Pick<
    GlobalSettingsType,
    'assistedReservationsEnabled' | 'tutoringModulesEnabled'
  >;
  reservationSettings?: Pick<ReservationSettingsState, 'IsForceTutoring'>;
  assistedTrainingData: UserTrainingReservationData | null;
  isTrainingSignUp?: boolean;
}) {
  console.debug('foo gett', assistedTrainingData);
  return (
    equipmentsData?: ReservationDetailsState['EquipmentsData'],
    accServices?: ReservationDetailsState['AccServices'],
  ) => {
    const isEquipmentTutoring = equipmentsData?.some(
      instrument =>
        (instrument as IReservationEquipmentDto).IsEquipmentTutoring,
    );

    if (!isEquipmentTutoring) {
      return false;
    }

    let tutoringAllowed =
      globalSettings.assistedReservationsEnabled &&
      globalSettings.tutoringModulesEnabled &&
      !isTrainingSignUp;

    if (!tutoringAllowed) {
      return false;
    }

    if (equipmentSettings?.IsAllAdmin === true) {
      return undefined;
    }

    let forceTutoring =
      equipmentsData?.some(
        instrument => (instrument as IReservationEquipmentDto).ForceTutoring,
      ) ||
      equipmentSettings?.ForceTutoring ||
      reservationSettings?.IsForceTutoring;
    var overrideTraining = accServices?.some(
      accService => accService.OverrideTraining,
    );
    if (!forceTutoring) {
      return undefined;
    }

    let hasValidTraining = overrideTraining
      ? false
      : assistedTrainingData === null
      ? false
      : assistedTrainingData.Equipments.some(f => f.RequiresAdminApproval)
      ? false
      : true;

    if (hasValidTraining) {
      return undefined;
    }
    return true;
  };
}
