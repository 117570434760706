import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { offlineServiceStateActions as actions } from '.';
import { appSettingsActions } from 'app/slice';
import { OtherServicesApi as api } from 'api/OtherServicesApi';
import {
  ConsumableInitData,
  ConsumableMilestoneData,
  ConsumableServicesResponse,
  ConvertConsumablesToInsertEntity,
  ConvertConsumablesToMilestoneCharge,
  ConvertDtoToConnectedModel,
  ConvertInitDataToConnectedModel,
  ConvertInventoryBatchToEntity,
  ConvertModelToInsertEntity,
  ConvertModelToUpdateEntity,
  ConvertModelToUpdateRenewStockEntity,
  ConvertRenewStockToInsertEntity,
  getDefaultBatch,
  getDefaultService,
  InventoryBatchParams,
  InventoryBatchResponse,
  InventoryBatchState,
  OfflineServiceQueryStringParameters,
  OfflineServicesResponse,
  OfflineServiceTypeChangeResult,
  OtherServiceDetailsState,
  RenewStockParams,
  RenewStockState,
  ReportConsumablesState,
  ReportedFrom,
  ServiceChangeStateParameters,
  StockRenewalParams,
  StockRenewalState,
} from './types';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { OtherServiceDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { AxiosError } from 'axios';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import {
  ConnectedFiltersInit,
  IConnectedFiltersDto,
} from 'types/IConnectedFiltersDto';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { connectedFiltersActions } from 'app/components/Forms/FormConnectedFilters/slice';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { selectConnectedFiltersData } from 'app/components/Forms/FormConnectedFilters/slice/selectors';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { dateUtils } from 'utils/date-utils';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectBarcodeScanTarget,
  selectGlobalSetting,
  selectOffsetDate,
} from 'app/slice/selectors';
import { toEntity } from 'utils/entity-utils';
import { MultiDetailsProps } from 'app/Layout/FrontendLayout/components/Snackbar/Actions';
import { BARCODE_SCANNED } from 'react-usb-barcode-scanner';
import { parseBarcode, ScanTarget } from 'app/slice/types';
import { AllowedSettings } from 'utils/globalSettings';
import { tryParseInt } from 'utils/string-utils';
import { AppSettings } from 'types/AppSettings';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { IMyAssetsRow } from 'app/pages/MyAssetsPage/IMyAssetsRow';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { httpClient } from 'api/HttpClient';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { InventoryBatchProps } from '../../InventoryBatch';
import { RenewStockProps } from '../../RenewStock';
import { v4 as uuid } from 'uuid';
import { requestSamplesActions } from 'app/pages/Samples/RequestSamplesPage/slice';
import { getModelStateErrors } from 'api/utils';
import { selectChargableServices } from './selectors';

function* doInitDetails(
  action: PayloadAction<{
    query: OfflineServiceQueryStringParameters;
    service?: IOtherServices;
    globalSettings: GlobalSettingsType;
    editCreatable?: boolean;
  }>,
) {
  try {
    let data: IOtherServices | null = action.payload.service || null;
    const date = yield select(state => selectOffsetDate(state, new Date()));
    let hasError: boolean = false;
    const user: AuthenticatedUser | undefined = yield select(
      selectAuthenticatedUser,
    );
    let serviceType: IOfflineServiceFilterDto | null = null;
    if (data === null) {
      const result = yield call(api.initOfflineService, action.payload.query);
      console.debug('service result: ', result);
      let response = result as OfflineServicesResponse;
      if (response.ErrorMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.ErrorMessages.map(item => {
              return {
                key: `initOfflineErr_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: `initOfflineWarn_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.SuccessMessages.map(item => {
              return {
                key: `initOfflineSucc_${uuid()}`,
                message: item,
                variant: 'success',
              };
            }),
          ),
        );
      }
      data =
        response.data ??
        getDefaultService(
          user,
          dateUtils.formatISO(dateUtils.dateOrStringToDate(date)),
        );
      hasError = !response.IsValid;
      serviceType = response.serviceType as IOfflineServiceFilterDto;
    }
    if (serviceType === null && data.ServiceTypeID > 0) {
      serviceType = yield call(
        api.getServiceType,
        data.ServiceTypeID,
        'publicActiveAndInactiveUrl',
      );
    }
    yield put(
      connectedFiltersActions.extendSettingsState({
        services:
          data.ServiceTypeID > 0
            ? [
                {
                  Id: data.ServiceTypeID,
                  Name: data.ServiceType,
                  ServiceGroupId: data.ServiceGroupId,
                  ServiceTypeId: ServiceType.Offline,
                  Active: true,
                  BudgetsTurnedOn: data.BudgetsTurnedOn,
                  HideProject: data.HideProjects,
                } as IServiceTypeFilterDto,
              ]
            : [],
        globalSettings: action.payload.globalSettings,
        isEdit: (tryParseInt(action.payload.query.id) ?? 0) > 0,
        settings: {},
        data: ConvertDtoToConnectedModel(data),
      }),
    );
    yield put(
      connectedFiltersActions.saveState(ConvertDtoToConnectedModel(data)),
    );
    yield put(
      actions.initDetails_Success({
        hasErrors: hasError,
        hasCharges: data.HasCharges,
        data: data,
        isEdit: (tryParseInt(action.payload.query.id) ?? 0) > 0,
        serviceType: serviceType,
      }),
    );
    if (
      data.ServiceTypeID > 0 &&
      !(data.Id > 0) &&
      !action.payload.editCreatable
    ) {
      const parameters = {
        ServiceTypes: [data.ServiceTypeID],
        ReservationId: data.ReservationId,
        UsageId: data.UsageId,
        BookedBy: data.BookedBy,
        ADGroup: data.UserGroup,
        Start: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(data.ServiceDate),
        ),
        BudgetId: data.BudgetID,
        FundingTypeId: data.FundingType,
      } as ServiceChangeStateParameters;
      yield put(actions.initServiceChangeStateData(parameters));
    }
  } catch (error: unknown) {
    yield put(actions.initDetails_Error(error));
  }
}
function* doInitServiceChangeStateData(
  action: PayloadAction<ServiceChangeStateParameters>,
) {
  try {
    let res = yield call(api.getServiceChangeState, action.payload);
    let response = res as OfflineServiceTypeChangeResult;

    yield put(actions.initServiceChangeStateData_Success(response));
  } catch (error: unknown) {
    yield put(actions.initServiceChangeStateData_Error());
  }
}
function* doCreate(
  action: PayloadAction<{
    model: OtherServiceDetailsState;
    saveCreatable?: boolean;
  }>,
) {
  const connectedModel: IConnectedFiltersDto = yield select(
    selectConnectedFiltersData,
  );
  const httpPayloads: IOtherServices = ConvertModelToInsertEntity(
    action.payload.model,
    connectedModel,
  );
  try {
    const result = yield call(api.insertOtherService, httpPayloads);
    let response = result as OfflineServicesResponse;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: `serviceInsertError_${uuid()}`,
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (responseSuccess.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: `serviceInsertSuccess_${uuid()}`,
            message: responseSuccess[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: true,
              itemName: i18next.t(translations.OfflineService),
              detailsType: RenderPageType.OtherServiceDetails,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: String(Number(response.Id) ?? -1),
                },
                initialService: response.data,
              } as OtherServiceDetailsProps,
            },
            variant: 'success',
          }),
        );
      }
      if (responseWarnings.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseWarnings.map(item => {
              return {
                key: `serviceInsertWarning_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = responseErrors.length > 0;
    if (action.payload.saveCreatable === true && !hasErrors) {
      yield put(actions.setReservationService(response.data ?? undefined));
    }
    yield put(
      actions.createService_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: `serviceInsert_${uuid()}`,
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createService_Error(Error));
  }
}
function* doUpdate(
  action: PayloadAction<{
    current: OtherServiceDetailsState;
    original: OtherServiceDetailsState;
    saveCreatable?: boolean;
  }>,
) {
  const connectedModel: IConnectedFiltersDto = yield select(
    selectConnectedFiltersData,
  );
  let updatedService = ConvertModelToUpdateEntity(
    action.payload.current,
    action.payload.original,
    connectedModel,
  );
  let hasErrors = false;
  try {
    if (action.payload.saveCreatable) {
      yield put(actions.setReservationService(updatedService));
    } else {
      const results = yield call(
        api.updateOtherService,
        updatedService,
        action.payload.current.Id,
      );
      let response = results as OfflineServicesResponse;
      let respErrors = response.ErrorMessages;
      if (respErrors.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            respErrors.map(item => {
              return {
                key: `serviceUpdateErr_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      } else {
        if (response.SuccessMessages.length > 0) {
          yield put(
            appSettingsActions.addNotification({
              key: `serviceUpdateSuccess_${uuid()}`,
              message: response.SuccessMessages[0],
              messageType: SnackBarMessageType.openSidepanelDetails,
              messageTypeProps: {
                Id: response.Id ?? undefined,
                created: false,
                itemName: i18next.t(translations.OfflineService),
                detailsType: RenderPageType.OtherServiceDetails,
                detailsTypeProps: {
                  useSidePanel: true,
                  queryParams: {
                    id: String(Number(response.Id) ?? -1),
                  },
                  initialService: response.data,
                } as OtherServiceDetailsProps,
              },
              variant: 'success',
            }),
          );
        }
        if (response.WarningMessages.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              response.WarningMessages.map(item => {
                return {
                  key: `serviceUpdateWarn_${uuid()}`,
                  message: item,
                  variant: 'warning',
                };
              }),
            ),
          );
        }
        yield put(layoutActions.setRefreshTable(true));
      }
      hasErrors = response.ErrorMessages.length > 0;
    }
    yield put(
      actions.updateService_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const modelState = getModelStateErrors(error);

    if (modelState !== undefined) {
      const messages = Object.values(modelState).flat();
      for (const message of messages) {
        yield put(
          appSettingsActions.addNotification({
            variant: 'error',
            message: message,
          }),
        );
      }
    } else {
      const message =
        (error as AxiosError)?.response?.data?.error?.innererror?.message ??
        ((error as AxiosError)?.response?.status === 403
          ? i18next.t(translations.Forbidden)
          : undefined) ??
        i18next.t(translations.errormessage);
      yield put(
        appSettingsActions.addNotification({
          key: `serviceUpdateErr_${uuid()}`,
          message: message,
          variant: 'error',
        }),
      );
    }
    yield put(actions.updateService_Error(Error));
  }
}
function* doSetAnyValue(
  action: PayloadAction<{
    fieldKey: keyof OtherServiceDetailsState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setAnyValueSuccess(action.payload));
}
function* doSetConsumableValue(
  action: PayloadAction<{
    fieldKey: keyof ReportConsumablesState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setConsumableValueSuccess(action.payload));
}
function* doSetRenewalValue(
  action: PayloadAction<{
    fieldKey: keyof StockRenewalState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setStockRenewalValueSuccess(action.payload));
}
function* doGetCredit(action: PayloadAction<OtherServiceDetailsState>) {
  const connectedModel: IConnectedFiltersDto = yield select(
    selectConnectedFiltersData,
  );
  const httpPayloads: IOtherServices = ConvertModelToInsertEntity(
    action.payload,
    connectedModel,
  );
  try {
    const result = yield call(api.calculateCredit, httpPayloads);
    yield put(
      actions.getCredit_Success({
        credit: result.TotalCost,
        balance: result.Balance,
      }),
    );
  } catch {
    yield put(actions.getCredit_Success({ credit: 0, balance: null }));
  }
}
function* doGetTotalCredit(action: PayloadAction<IOtherServices[]>) {
  const connectedModel: IConnectedFiltersDto = yield select(
    selectConnectedFiltersData,
  );
  const httpPayloads: IOtherServices[] = ConvertConsumablesToInsertEntity(
    action.payload,
    connectedModel,
  );
  try {
    const result = yield call(api.calculateTotalCredit, httpPayloads);
    yield put(
      actions.getTotalCredit_Success({
        credit: result.TotalCost,
        balance: result.Balance,
      }),
    );
  } catch {
    yield put(actions.getTotalCredit_Success({ credit: 0, balance: null }));
  }
}
function* doInitConsumables(
  action: PayloadAction<{
    query: OfflineServiceQueryStringParameters;
    globalSettings: GlobalSettingsType;
    services?: IOtherServices[];
    reportFrom?: ReportedFrom;
  }>,
) {
  try {
    let data: IOtherServices[] = !!action.payload.services
      ? action.payload.services
      : [];
    const user: AuthenticatedUser | undefined = yield select(
      selectAuthenticatedUser,
    );
    let initData: ConsumableInitData | undefined = undefined;
    if (data.length < 1) {
      const result = yield call(
        api.initConsumableServices,
        action.payload.query,
      );
      let response = result as ConsumableServicesResponse;
      if (response.ErrorMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.ErrorMessages.map(item => {
              return {
                key: `initOfflineErr_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: `initOfflineWarn_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.SuccessMessages.map(item => {
              return {
                key: `initOfflineSucc_${uuid()}`,
                message: item,
                variant: 'success',
              };
            }),
          ),
        );
      }
      data = response.data;
      initData = response.consumableInitData;
    }
    const connectedData =
      data.length > 0
        ? ConvertDtoToConnectedModel(data[0])
        : !!initData
        ? ConvertInitDataToConnectedModel(initData)
        : ConnectedFiltersInit(
            undefined,
            undefined,
            toEntity(user?.Id, user?.Name),
            action.payload.globalSettings
              .reservationUserGroupGroupByBudgetUserGroup
              ? undefined
              : toEntity(
                  user?.ActiveUserGroup?.Id,
                  user?.ActiveUserGroup?.Name,
                ),
            undefined,
            undefined,
            undefined,
            null,
            undefined,
            undefined,
            undefined,
          );
    yield put(
      connectedFiltersActions.extendSettingsState({
        services:
          data.length > 0
            ? data.map(f => {
                return {
                  Id: f.ServiceTypeID,
                  Name: f.ServiceType,
                  ServiceGroupId: f.ServiceGroupId,
                  ServiceTypeId: ServiceType.Offline,
                  Active: true,
                  BudgetsTurnedOn: f.BudgetsTurnedOn,
                  HideProject: f.HideProjects,
                } as IServiceTypeFilterDto;
              })
            : [],
        globalSettings: action.payload.globalSettings,
        isEdit: false,
        settings: {},
        data: connectedData,
        reportFrom: action.payload.reportFrom,
      }),
    );
    yield put(connectedFiltersActions.saveState(connectedData));
    yield put(
      actions.initConsumables_Success({
        data: data, //action.payload.reportFrom === 'milestone' ? [] : data,
        connectedData: connectedData,
      }),
    );
    if (data.length > 0 && action.payload.reportFrom !== 'milestone') {
      const parameters = {
        ServiceTypes: data.map(f => f.ServiceTypeID),
        ReservationId: null,
        UsageId: null,
        BookedBy: data[0].BookedBy,
        ADGroup: data[0].UserGroup,
        Start: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(data[0].ServiceDate),
        ),
        BudgetId: data[0].BudgetID,
        FundingTypeId: data[0].FundingType,
      } as ServiceChangeStateParameters;
      yield put(actions.initServiceChangeStateData(parameters));
    }
  } catch (error: unknown) {
    yield put(actions.initConsumables_Error(error));
  }
}
function* doCreateConsumables(
  action: PayloadAction<{
    Services: IOtherServices[];
    DiscountFactor?: number | null;
    Remarks?: string | null;
    reportFrom?: ReportedFrom;
    milestoneData?: ConsumableMilestoneData;
  }>,
) {
  const connectedModel: IConnectedFiltersDto = yield select(
    selectConnectedFiltersData,
  );
  const httpPayloads: IOtherServices[] = ConvertConsumablesToInsertEntity(
    action.payload.Services,
    connectedModel,
    action.payload.DiscountFactor,
    action.payload.Remarks,
  );
  if (
    action.payload.reportFrom === 'milestone' &&
    action.payload.milestoneData !== undefined
  ) {
    const chargedOriginals = yield select(selectChargableServices);
    const charges = ConvertConsumablesToMilestoneCharge(
      action.payload.Services,
      chargedOriginals ?? [],
      connectedModel,
      action.payload.milestoneData,
      action.payload.Remarks,
    );
    if (action.payload.Services.some(f => Number(f.Quantity) === 0)) {
      yield put(
        appSettingsActions.addNotifications(
          action.payload.Services.filter(f => Number(f.Quantity) === 0).map(
            item => {
              return {
                key: `serviceInsertError_${uuid()}`,
                message: `${item.ServiceType} ${
                  i18next.t(translations.err_Quantity_required) as string
                }`,
                variant: 'error',
              };
            },
          ),
        ),
      );
      yield put(actions.createConsumables_Error(Error));
      return;
    }
    yield put(requestSamplesActions.insertMilestoneCharges(charges));
    yield put(
      actions.createConsumables_Success({
        hasErrors: false,
      }),
    );
  } else {
    try {
      const result = yield call(api.insertConsumableServices, httpPayloads);
      let response = result as ConsumableServicesResponse;
      let responseErrors = response.ErrorMessages;
      let responseWarnings = response.WarningMessages;
      let responseSuccess = response.SuccessMessages;
      if (responseErrors.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseErrors.map(item => {
              return {
                key: `serviceInsertError_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      } else {
        if (responseSuccess.length > 0) {
          yield put(
            appSettingsActions.addNotification({
              key: `serviceInsertSuccess_${uuid()}`,
              message: responseSuccess[0],
              messageType: SnackBarMessageType.openSidepanelDetails,
              messageTypeProps: {
                Id: undefined,
                created: true,
                multiple: true,
                itemName: i18next.t(translations.OfflineService),
                itemEndName: 'logged',
                multiDetails: response.data.map(f => {
                  return {
                    Id: f.Id,
                    detailsType: RenderPageType.OtherServiceDetails,
                    detailsTypeProps: {
                      useSidePanel: true,
                      queryParams: {
                        id: String(Number(f.Id) ?? -1),
                      },
                      initialService: f,
                    } as OtherServiceDetailsProps,
                  } as MultiDetailsProps;
                }),
              },
              variant: 'success',
            }),
          );
        }
        if (responseWarnings.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              responseWarnings.map(item => {
                return {
                  key: `serviceInsertWarning_${uuid()}`,
                  message: item,
                  variant: 'warning',
                };
              }),
            ),
          );
        }
        yield put(layoutActions.setRefreshTable(true));
      }
      let hasErrors = responseErrors.length > 0;
      yield put(
        actions.createConsumables_Success({
          hasErrors: hasErrors,
        }),
      );
    } catch (error: unknown) {
      const message =
        (error as AxiosError)?.response?.data?.error?.innererror?.message ??
        ((error as AxiosError)?.response?.status === 403
          ? i18next.t(translations.Forbidden)
          : undefined) ??
        i18next.t(translations.errormessage);
      yield put(
        appSettingsActions.addNotification({
          key: `serviceInsert_${uuid()}`,
          message: message,
          variant: 'error',
        }),
      );
      yield put(actions.createConsumables_Error(Error));
    }
  }
}
async function getAssetByInternalCode(barcode: string) {
  try {
    var response: IODataQueryResponse<IMyAssetsRow> = await httpClient.get(
      '/api/odata/v4/MyAssets',
      {
        $top: 1,
        $select: 'ServiceId',
        $filter: `${new Condition<IMyAssetsRow>(
          'InternalCode',
          ODataOperators.Equals,
          barcode,
        ).toString()} and ${new Condition<IMyAssetsRow>(
          'ServiceTypeId',
          ODataOperators.Equals,
          2,
        ).toString()}`,
      },
    );
    var serviceTypeId = response.value[0]?.ServiceId;
    return serviceTypeId;
  } catch (error) {}
}
function* doBarcodeScan(action: PayloadAction<{ data: string }>) {
  const MIN_LENGTH_DEFAULT = 6;
  const minBarcodeLengthString: string | undefined = yield select(state =>
    selectGlobalSetting(state, AllowedSettings.MinBarcodeLength),
  );
  const MinBarcodeLength =
    tryParseInt(minBarcodeLengthString) ?? MIN_LENGTH_DEFAULT;
  // prevent accidental barcode detection on fast user input
  if (action.payload.data.length < MinBarcodeLength) {
    return;
  }
  const scannTarget: ScanTarget | undefined = yield select(
    selectBarcodeScanTarget,
  );
  if (scannTarget === undefined || scannTarget !== ScanTarget.Consumables) {
    return;
  }
  const appSettings: AppSettings = yield select(selectAppSettings);
  yield put(
    appSettingsActions.addNotification({
      variant: 'info',
      message:
        i18next.t(translations.BarcodeScanModalBody) +
        ' ' +
        action.payload.data,
    }),
  );
  try {
    // in case and the barcode is a valid internal url - get InternalCode
    let scanedBarcode = parseBarcode(action.payload.data, appSettings);

    let internalId = action.payload.data;
    if (scanedBarcode !== undefined) {
      internalId = scanedBarcode.InternalCode ?? action.payload.data;
    }
    if (internalId === undefined || internalId === null) {
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: i18next.t(translations.BarcodeNotFoundEx),
        }),
      );
    } else {
      const serviceId = yield call(getAssetByInternalCode, internalId);
      if (serviceId !== undefined) {
        yield put(actions.setSelectedServiceType([serviceId]));
        yield put(actions.setBarcodeScanned(true));

        return;
      }
      yield put(
        appSettingsActions.addNotification({
          variant: 'error',
          message: i18next.t(translations.BarcodeNotFoundEx),
        }),
      );
    }
  } catch (error) {
    console.error(`${action.type}`, action.payload, error);
  }
}
function* doSetIsBarcodeScanned(action: PayloadAction<boolean | undefined>) {
  yield put(actions.setBarcodeScanned_Success(action.payload));
}
function* doInitStockRenewal(
  action: PayloadAction<{
    query: StockRenewalParams;
    services?: IOtherServices[];
  }>,
) {
  try {
    let data: IOtherServices[] = !!action.payload.services
      ? action.payload.services
      : [];
    const date = yield select(state => selectOffsetDate(state, new Date()));
    if (data.length < 1) {
      const result = yield call(
        api.initStockRenewalServices,
        action.payload.query,
      );
      let response = result as ConsumableServicesResponse;
      if (response.ErrorMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.ErrorMessages.map(item => {
              return {
                key: `initOfflineErr_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: `initOfflineWarn_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.SuccessMessages.map(item => {
              return {
                key: `initOfflineSucc_${uuid()}`,
                message: item,
                variant: 'success',
              };
            }),
          ),
        );
      }
      data = response.data;
    }
    yield put(
      actions.initStockRenewal_Success({
        data: data,
        date: dateUtils.formatISO(dateUtils.dateOrStringToDate(date)),
      }),
    );
  } catch (error: unknown) {
    yield put(actions.initStockRenewal_Error(error));
  }
}
function* doCreateStockRenewals(action: PayloadAction<StockRenewalState>) {
  const httpPayloads: IOtherServices[] = action.payload.Services;
  try {
    const result = yield call(api.insertStockRenewalServices, httpPayloads);
    let response = result as ConsumableServicesResponse;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: `stockrenewalInsertError_${uuid()}`,
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (responseSuccess.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: `stockrenewalInsertSuccess_${uuid()}`,
            message: responseSuccess[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: undefined,
              created: true,
              multiple: true,
              itemName: i18next.t(translations.RenewStock),
              multiDetails: response.data.map(f => {
                return {
                  Id: f.Id,
                  detailsType: RenderPageType.RenewStock,
                  detailsTypeProps: {
                    useSidePanel: true,
                    queryParams: {
                      id: String(Number(f.Id) ?? -1),
                    },
                    initialService: f,
                  } as RenewStockProps,
                } as MultiDetailsProps;
              }),
            },
            variant: 'success',
          }),
        );
      }
      if (responseWarnings.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseWarnings.map(item => {
              return {
                key: `stockrenewalInsertWarning_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
    }
    let hasErrors = responseErrors.length > 0;
    yield put(
      actions.createStockRenewals_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: `stockrenewalInsert_${uuid()}`,
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createStockRenewals_Error(Error));
  }
}
function* doInitRenewStock(
  action: PayloadAction<{
    query: RenewStockParams;
    service?: IOtherServices;
  }>,
) {
  try {
    let data: IOtherServices | null = action.payload.service || null;
    let hasError: boolean = false;
    const date = yield select(state => selectOffsetDate(state, new Date()));
    const user: AuthenticatedUser | undefined = yield select(
      selectAuthenticatedUser,
    );
    let serviceType: IOfflineServiceFilterDto | null = null;
    if (data === null) {
      const result = yield call(
        api.initRenewStockService,
        action.payload.query,
      );
      console.debug('service result: ', result);
      let response = result as OfflineServicesResponse;
      if (response.ErrorMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.ErrorMessages.map(item => {
              return {
                key: `initOfflineErr_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: `initOfflineWarn_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.SuccessMessages.map(item => {
              return {
                key: `initOfflineSucc_${uuid()}`,
                message: item,
                variant: 'success',
              };
            }),
          ),
        );
      }
      data =
        response.data ??
        getDefaultService(
          user,
          dateUtils.formatISO(dateUtils.dateOrStringToDate(date)),
        );
      hasError = !response.IsValid;
      serviceType = response.serviceType as IOfflineServiceFilterDto;
    }
    if (serviceType === null && data.ServiceTypeID > 0) {
      serviceType = yield call(api.getServiceType, data.ServiceTypeID);
    }
    yield put(
      actions.initRenewStock_Success({
        hasErrors: hasError,
        data: data,
        isEdit: (tryParseInt(action.payload.query.id) ?? 0) > 0,
        serviceType: serviceType,
      }),
    );
  } catch (error: unknown) {
    yield put(actions.initRenewStock_Error(error));
  }
}
function* doCreateRenewStock(
  action: PayloadAction<{
    model: RenewStockState;
    editCreatable: boolean;
    preventRefreshTable?: boolean;
    autoGenerateBatch?: boolean;
    saveCreatable?: boolean;
  }>,
) {
  const httpPayloads: IOtherServices = ConvertRenewStockToInsertEntity(
    action.payload.model,
    action.payload.autoGenerateBatch,
  );
  if (action.payload.editCreatable) {
    yield put(actions.setEditCreatableService(httpPayloads));
    yield put(
      actions.createRenewStock_Success({
        hasErrors: false,
      }),
    );
  } else {
    try {
      const result = yield call(api.insertOtherService, httpPayloads);
      let response = result as OfflineServicesResponse;
      let responseErrors = response.ErrorMessages;
      let responseWarnings = response.WarningMessages;
      let responseSuccess = response.SuccessMessages;
      if (responseErrors.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseErrors.map(item => {
              return {
                key: `RenewStockInsertError_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      } else {
        if (responseSuccess.length > 0) {
          yield put(
            appSettingsActions.addNotification({
              key: `RenewStockInsertSuccess_${uuid()}`,
              message: responseSuccess[0],
              messageType: SnackBarMessageType.openSidepanelDetails,
              messageTypeProps: {
                Id: response.Id ?? undefined,
                created: true,
                itemName: i18next.t(translations.RenewStock),
                detailsType: RenderPageType.RenewStock,
                detailsTypeProps: {
                  useSidePanel: true,
                  queryParams: {
                    id: String(Number(response.Id) ?? -1),
                  },
                  initialService: response.data,
                } as RenewStockProps,
              },
              variant: 'success',
            }),
          );
        }
        if (responseWarnings.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              responseWarnings.map(item => {
                return {
                  key: `RenewStockInsertWarning_${uuid()}`,
                  message: item,
                  variant: 'warning',
                };
              }),
            ),
          );
        }
        if (!action.payload.preventRefreshTable) {
          yield put(layoutActions.setRefreshTable(true));
        }
      }
      let hasErrors = responseErrors.length > 0;
      yield put(
        actions.createRenewStock_Success({
          hasErrors: hasErrors,
        }),
      );
      if (action.payload.saveCreatable) {
        yield put(actions.getCreatableService(response.data ?? undefined));
      }
    } catch (error: unknown) {
      const message =
        (error as AxiosError)?.response?.data?.error?.innererror?.message ??
        ((error as AxiosError)?.response?.status === 403
          ? i18next.t(translations.Forbidden)
          : undefined) ??
        i18next.t(translations.errormessage);
      yield put(
        appSettingsActions.addNotification({
          key: `RenewStockInsert_${uuid()}`,
          message: message,
          variant: 'error',
        }),
      );
      yield put(actions.createRenewStock_Error(Error));
    }
  }
}
function* doUpdateRenewStock(
  action: PayloadAction<{
    current: RenewStockState;
    original: RenewStockState;
    editCreatable: boolean;
    autoGenerateBatch?: boolean;
  }>,
) {
  let updatedService = ConvertModelToUpdateRenewStockEntity(
    action.payload.current,
    action.payload.original,
    action.payload.autoGenerateBatch,
  );
  if (action.payload.editCreatable) {
    yield put(actions.setEditCreatableService(updatedService));
    yield put(
      actions.updateRenewStock_Success({
        hasErrors: false,
      }),
    );
  } else {
    try {
      const results = yield call(
        api.updateOtherService,
        updatedService,
        action.payload.current.Id,
      );
      let response = results as OfflineServicesResponse;
      let respErrors = response.ErrorMessages;
      if (respErrors.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            respErrors.map(item => {
              return {
                key: `serviceUpdateErr_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      } else {
        if (response.SuccessMessages.length > 0) {
          yield put(
            appSettingsActions.addNotification({
              key: `serviceUpdateSuccess_${uuid()}`,
              message: response.SuccessMessages[0],
              messageType: SnackBarMessageType.openSidepanelDetails,
              messageTypeProps: {
                Id: response.Id ?? undefined,
                created: false,
                itemName: i18next.t(translations.RenewStock),
                detailsType: RenderPageType.RenewStock,
                detailsTypeProps: {
                  useSidePanel: true,
                  queryParams: {
                    id: String(Number(response.Id) ?? -1),
                  },
                  initialService: response.data,
                } as RenewStockProps,
              },
              variant: 'success',
            }),
          );
        }
        if (response.WarningMessages.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              response.WarningMessages.map(item => {
                return {
                  key: `serviceUpdateWarn_${uuid()}`,
                  message: item,
                  variant: 'warning',
                };
              }),
            ),
          );
        }
        yield put(layoutActions.setRefreshTable(true));
      }
      let hasErrors = response.ErrorMessages.length > 0;
      yield put(
        actions.updateRenewStock_Success({
          hasErrors: hasErrors,
        }),
      );
    } catch (error: unknown) {
      const message =
        (error as AxiosError)?.response?.data?.error?.innererror?.message ??
        ((error as AxiosError)?.response?.status === 403
          ? i18next.t(translations.Forbidden)
          : undefined) ??
        i18next.t(translations.errormessage);
      yield put(
        appSettingsActions.addNotification({
          key: `serviceUpdateErr_${uuid()}`,
          message: message,
          variant: 'error',
        }),
      );
      yield put(actions.updateRenewStock_Error(Error));
    }
  }
}
function* doInitInventoryBatch(
  action: PayloadAction<{
    query: InventoryBatchParams;
    batch?: IInventoryBatchDto;
  }>,
) {
  try {
    let data: IInventoryBatchDto | null = action.payload.batch || null;
    let hasError: boolean = false;
    const newDate = yield select(state => selectOffsetDate(state, new Date()));
    let serviceType: IOfflineServiceFilterDto | null = null;
    if (data === null) {
      const result = yield call(api.initInventoryBatch, action.payload.query);
      let response = result as InventoryBatchResponse;
      if (response.ErrorMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.ErrorMessages.map(item => {
              return {
                key: `initBatchErr_${uuid()}`,
                message: item,
                variant: 'error',
              };
            }),
          ),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: `initBatchWarn_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.SuccessMessages.map(item => {
              return {
                key: `initBatchSucc_${uuid()}`,
                message: item,
                variant: 'success',
              };
            }),
          ),
        );
      }
      data =
        response.data ??
        getDefaultBatch(
          dateUtils.formatISO(dateUtils.dateOrStringToDate(newDate)),
        );
      hasError = !response.IsValid;
      serviceType = response.serviceType as IOfflineServiceFilterDto;
    }
    if (serviceType === null && data.OfflineServiceTypeId > 0) {
      serviceType = yield call(api.getServiceType, data.OfflineServiceTypeId);
    }
    yield put(
      actions.initInventoryBatch_Success({
        hasErrors: hasError,
        data: data,
        isEdit: (tryParseInt(action.payload.query.id) ?? 0) > 0,
        serviceType: serviceType,
        newDate: dateUtils.formatISO(dateUtils.dateOrStringToDate(newDate)),
      }),
    );
  } catch (error: unknown) {
    yield put(actions.initInventoryBatch_Error(error));
  }
}
function* doCreateInventoryBatch(
  action: PayloadAction<{
    model: InventoryBatchState;
    saveCreatable?: boolean;
    preventRefreshTable?: boolean;
  }>,
) {
  const httpPayloads: IInventoryBatchDto = ConvertInventoryBatchToEntity(
    action.payload.model,
  );

  try {
    const result = yield call(api.insertInventoryBatch, httpPayloads);
    let response = result as InventoryBatchResponse;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: `batchInsertError_${uuid()}`,
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (responseSuccess.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: `batchInsertSuccess_${uuid()}`,
            message: responseSuccess[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: true,
              itemName: i18next.t(translations.InventoryBatch),
              detailsType: RenderPageType.InventoryBatch,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: String(Number(response.Id) ?? -1),
                },
                initialBatch: response.data,
              } as InventoryBatchProps,
            },
            variant: 'success',
          }),
        );
      }
      if (responseWarnings.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseWarnings.map(item => {
              return {
                key: `batchInsertWarning_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
    }
    let hasErrors = responseErrors.length > 0;
    if (action.payload.saveCreatable === true) {
      yield put(actions.setInventoryBatchEditable(response.data ?? undefined));
    } else {
      if (!action.payload.preventRefreshTable) {
        yield put(layoutActions.setRefreshTable(true));
      }
    }
    yield put(
      actions.createInventoryBatch_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: `batchInsert_err_${uuid()}`,
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.createInventoryBatch_Error(Error));
  }
}
function* doUpdateInventoryBatch(
  action: PayloadAction<{
    current: InventoryBatchState;
    original: InventoryBatchState;
    saveCreatable?: boolean;
    preventRefreshTable?: boolean;
  }>,
) {
  let updatedService = ConvertInventoryBatchToEntity(action.payload.current);

  try {
    const results = yield call(
      api.updateInventoryBatch,
      updatedService,
      action.payload.current.Id,
    );
    let response = results as InventoryBatchResponse;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: `batchUpdateErr_${uuid()}`,
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (response.SuccessMessages.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            key: `batchUpdateSuccess_${uuid()}`,
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: false,
              itemName: i18next.t(translations.InventoryBatch),
              detailsType: RenderPageType.InventoryBatch,
              detailsTypeProps: {
                useSidePanel: true,
                queryParams: {
                  id: String(Number(response.Id) ?? -1),
                },
                initialBatch: response.data,
              } as InventoryBatchProps,
            },
            variant: 'success',
          }),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: `batchUpdateWarn_${uuid()}`,
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      //yield put(layoutActions.setRefreshTable(true));
    }
    if (action.payload.saveCreatable === true) {
      yield put(actions.setInventoryBatchEditable(response.data ?? undefined));
    } else {
      if (!action.payload.preventRefreshTable) {
        yield put(layoutActions.setRefreshTable(true));
      }
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateInventoryBatch_Success({
        hasErrors: hasErrors,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: `serviceUpdateErr_${uuid()}`,
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateInventoryBatch_Error(Error));
  }
}
function* doSetRenewStockValue(
  action: PayloadAction<{
    fieldKey: keyof RenewStockState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setRenewStockValueSuccess(action.payload));
}
function* doSetInventoryBatchValue(
  action: PayloadAction<{
    fieldKey: keyof InventoryBatchState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setInventoryBatchValueSuccess(action.payload));
}
export function* offlineServiceStateSaga() {
  yield takeLatest(actions.initDetails.type, doInitDetails);
  yield takeLatest(actions.createService.type, doCreate);
  yield takeLatest(actions.updateService.type, doUpdate);
  yield takeLatest(actions.setAnyValue.type, doSetAnyValue);
  yield takeLatest(actions.setConsumableValue.type, doSetConsumableValue);
  yield takeLatest(actions.createConsumables.type, doCreateConsumables);
  yield takeLatest(actions.getCredit.type, doGetCredit);
  yield takeLatest(actions.getTotalCredit.type, doGetTotalCredit);
  yield takeLatest(
    actions.initServiceChangeStateData.type,
    doInitServiceChangeStateData,
  );
  yield takeLatest(actions.initConsumables.type, doInitConsumables);
  yield takeLatest(actions.setStockRenewalValue.type, doSetRenewalValue);
  yield takeLatest(actions.setRenewStockValue.type, doSetRenewStockValue);
  yield takeLatest(
    actions.setInventoryBatchValue.type,
    doSetInventoryBatchValue,
  );
  yield takeLatest(actions.initStockRenewal.type, doInitStockRenewal);
  yield takeLatest(actions.createStockRenewals.type, doCreateStockRenewals);
  yield takeLatest(actions.initRenewStock.type, doInitRenewStock);
  yield takeLatest(actions.createRenewStock.type, doCreateRenewStock);
  yield takeLatest(actions.updateRenewStock.type, doUpdateRenewStock);
  yield takeLatest(actions.initInventoryBatch.type, doInitInventoryBatch);
  yield takeLatest(actions.createInventoryBatch.type, doCreateInventoryBatch);
  yield takeLatest(actions.updateInventoryBatch.type, doUpdateInventoryBatch);
  yield takeLatest(BARCODE_SCANNED, doBarcodeScan);
  yield takeLatest(actions.setBarcodeScanned.type, doSetIsBarcodeScanned);
}
