import { translations } from 'locales/translations';
import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';

export function getServiceRequestBlockedByInvoicedMessage(
  requestDetails: Pick<IServiceRequestDto, 'Service' | 'Status'>,
) {
  const isMilestoneAndIncomplete =
    requestDetails.Service.MilestonesAddonsType === 'Milestones' &&
    requestDetails.Status.InternalStatusId !== 'Completed';
  const variant: 'warning' | 'error' = isMilestoneAndIncomplete
    ? 'warning'
    : 'error';
  const messageKey = isMilestoneAndIncomplete
    ? translations.requestblockedbyinvoice
    : translations.requestblockedbyinvoice_complete;
  return { variant, messageKey };
}
