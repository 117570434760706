import { FieldHookConfig, useField } from 'formik';
import { Entity } from 'types/common';
import { DepartmentPicker } from '../pickers/AutocompletePickers/DepartmentPicker';
import { IFieldProps } from './FormRender/IFormFieldSetting';

export const FormDepartmentPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <DepartmentPicker
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value}
      error={meta?.error !== undefined}
      variant="filled"
      helperText={meta.error}
      fullWidth={props.fullWidth}
      info={props.info}
      predicates={props.predicates}
      disabled={props.disabled}
    />
  );
};
