/**
 *
 * FormInstitutePicker
 *
 */
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from '../FormRender/IFormFieldSetting';
import { Entity } from 'types/common';
import { InstitutePicker } from 'app/components/pickers/AutocompletePickers/InstitutePicker';

export const FormInstitutePicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number> | null> & IFieldProps) => {
  const [field, meta, helpers] = useField<Entity<number> | null>(props);
  return (
    <>
      <InstitutePicker
        name={props.name}
        placeholder={props.placeholder}
        predicates={props.predicates}
        label={label}
        variant="filled"
        onChange={value => {
          helpers.setValue(value, true);
          if (props.onChange) {
            props.onChange(value);
          }
        }}
        info={props.info}
        onBlur={field?.onBlur}
        value={field.value}
        error={meta?.error !== undefined}
        helperText={meta.error}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
      />
    </>
  );
};
