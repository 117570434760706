/**
 *
 * AsyncPicker
 *
 */
import { Entity } from 'types/common';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { ServiceGroupsPicker } from '../pickers/MultiSelectPickers/ServiceGroupsPicker';
import { IServiceGroupDto } from 'api/odata/generated/entities/IServiceGroupDto';
import { GroupUrl } from '../pickers/ServiceGroupPicker';

export const FormServiceGroupsPicker = ({
  label,
  ...props
}: FieldHookConfig<Entity<number>[] | undefined> &
  IFieldProps & { urlType?: GroupUrl }) => {
  const [field, meta, helpers] = useField<Entity<number>[] | undefined>(props);
  return (
    <ServiceGroupsPicker
      name={props.name}
      placeholder={props.placeholder}
      label={label}
      onChange={value => {
        helpers.setValue(value, true);
        if (props.onChange) {
          props.onChange(value);
        }
      }}
      onBlur={field?.onBlur}
      value={field.value as IServiceGroupDto[]}
      error={meta?.error !== undefined}
      variant="filled"
      helperText={meta.error}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      info={props.info}
      urlType={props.urlType}
    />
  );
};
