import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import {
  AutocompletePicker,
  AutocompletePickerProps,
} from 'app/components/BasicPickers/AutocompletePicker';
import { getAutoCompleteLoadDataFn } from 'app/components/BasicPickers/Utils/autoCompletePickerUtils';
import { selectGlobalServiceGroupFilter } from 'app/slice/selectors';
import { useSelector } from 'react-redux';
import { Entity, Identifiable } from 'types/common';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';

export type OfflineServiceTypeUrl =
  | 'base'
  | 'public'
  | 'publicActiveAndInactiveUrl'
  | 'relatedServices';
export interface OfflineServiceTypePickerProps
  extends Omit<AutocompletePickerProps<IOfflineServiceFilterDto>, 'loadData'> {
  urlType: OfflineServiceTypeUrl;
}

const url = '/api/odata/v4/OfflineServiceFilter';
const publicActiveAndInactiveUrl =
  '/api/odata/v4/OfflineServiceFilter/AllOfflineServicesActiveAndInactive';
const publicUrl = '/api/odata/v4/OfflineServiceFilter/AllOfflineServices';
const relatedUrl = '/api/odata/v4/OfflineServiceFilter/RelatedOfflineServices';

export const loadOfflineServiceData = (
  predicates: (string | Condition<IOfflineServiceFilterDto>)[] | undefined,
  urlType: OfflineServiceTypeUrl,
  globalServiceGroupFilter: Entity<number>[],
  select?: (keyof IOfflineServiceFilterDto)[],
  expand?: string,
) => {
  const sourceUrl =
    urlType === 'public'
      ? publicUrl
      : urlType === 'publicActiveAndInactiveUrl'
      ? publicActiveAndInactiveUrl
      : urlType === 'relatedServices'
      ? relatedUrl
      : url;
  return getAutoCompleteLoadDataFn<IOfflineServiceFilterDto>({
    url: sourceUrl,
    predicates: predicates,
    // TODO: narrow down used properties list
    select:
      !!select && select.length > 0
        ? select
        : [
            'Id',
            'Name',
            'ServiceGroupId',
            'ServiceTypeId',
            'Active',
            'IntQuantityOnly',
            'HideProjects',
            'UnitTypeID',
            'UnitTypeName',
            'AllowToUser',
            'Inventory',
            'DefaultQuantity',
            'ReservationOverlappingInventory',
            'BudgetsTurnedOn',
            'IsAdmin',
            'StaffOnly',
            'NotLessThanZero',
            'TrackInventory',
            'InventoryBatchesEnabled',
            'RelatedReferences',
          ],
    expand: expand,
    globalServiceGroupFilter:
      !!globalServiceGroupFilter && globalServiceGroupFilter.length > 0
        ? [
            new Condition<IOfflineServiceFilterDto>(
              'ServiceGroupId',
              ODataOperators.Includes,
              globalServiceGroupFilter.map(f => f.Id),
            ),
          ]
        : [],
  });
};

export function OfflineServiceTypePicker(props: OfflineServiceTypePickerProps) {
  const globalServiceGroupFilter = useSelector(selectGlobalServiceGroupFilter);
  const innerLoadData = loadOfflineServiceData(
    props.predicates,
    props.urlType,
    globalServiceGroupFilter || [],
    undefined,
    props.expandedColumns,
  );
  return (
    <AutocompletePicker
      loadData={innerLoadData}
      mini={props.mini ? true : undefined}
      size={props.size}
      additionalItem={props.additionalItem}
      id={props.id || 'offServicetypeId'}
      {...props}
    />
  );
}

export function UsageRelatedFilter(
  Ids: number[],
  excludedIds: number[],
): (string | Condition<IOfflineServiceFilterDto>)[] {
  const predicates: (string | Condition<IOfflineServiceFilterDto>)[] = [];
  if (Ids.length > 0) {
    let ids = Ids.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'Id',
        ODataOperators.Includes,
        ids,
      ),
    );
  }
  if (excludedIds.length > 0) {
    let ids = excludedIds.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'Id',
        ODataOperators.Excludes,
        ids,
      ),
    );
  }
  if (predicates.length > 0) {
    // predicates.push(
    //   new Condition<IOfflineServiceFilterDto>(
    //     'AllowToUser',
    //     ODataOperators.Equals,
    //     true,
    //   ),
    // );
    return predicates;
  }
  return [];
}

export function ReservationsRelatedFilter(
  equipmentGroups: number[],
  optionalServiceTypes: Array<Identifiable<number>>,
  hideProject?: boolean,
  allServicesAllowed?: boolean,
  budgetId?: number,
  budgetServiceGroupServicesAllowed?: boolean,
): (string | Condition<IOfflineServiceFilterDto>)[] {
  const predicates: (string | Condition<IOfflineServiceFilterDto>)[] = [];
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'Active',
      ODataOperators.Equals,
      true,
    ),
  );
  if (equipmentGroups.length > 0) {
    let ids = equipmentGroups.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'ServiceGroupId',
        ODataOperators.Includes,
        ids,
      ),
    );
  }
  if (!!hideProject) {
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'HideProjects',
        ODataOperators.Equals,
        hideProject,
      ),
    );
  }
  let byAdmin = new Condition<IOfflineServiceFilterDto>(
    'IsAdmin',
    ODataOperators.Equals,
    true,
  );
  let byUser = new Condition<IOfflineServiceFilterDto>(
    'StaffOnly',
    ODataOperators.Equals,
    false,
  );
  predicates.push(`(${byAdmin} or ${byUser})`);
  if (!!budgetId && budgetId > 0 && !budgetServiceGroupServicesAllowed) {
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'AllowedBudgets',
        ODataOperators.Any,
        budgetId,
      ),
    );
  }
  if (!allServicesAllowed) {
    if (optionalServiceTypes.length > 0) {
      let ids = optionalServiceTypes.map(f => {
        return { Id: f.Id } as Identifiable<number>;
      });
      predicates.push(
        new Condition<IOfflineServiceFilterDto>(
          'Id',
          ODataOperators.Includes,
          ids,
        ),
      );
    }
  }
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
export function UsageDetailsRelatedFilter(
  equipmentGroupId: number,
  optionalServiceTypes: Array<Identifiable<number>>,
  hideProject?: boolean,
  budgetId?: number,
  budgetServiceGroupServicesAllowed?: boolean,
): (string | Condition<IOfflineServiceFilterDto>)[] {
  const predicates: (string | Condition<IOfflineServiceFilterDto>)[] = [];
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'Active',
      ODataOperators.Equals,
      true,
    ),
  );
  if (equipmentGroupId > 0) {
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'ServiceGroupId',
        ODataOperators.Equals,
        equipmentGroupId,
      ),
    );
  }
  if (!!hideProject) {
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'HideProjects',
        ODataOperators.Equals,
        hideProject,
      ),
    );
  }
  let byAdmin = new Condition<IOfflineServiceFilterDto>(
    'IsAdmin',
    ODataOperators.Equals,
    true,
  );
  let byUser = new Condition<IOfflineServiceFilterDto>(
    'StaffOnly',
    ODataOperators.Equals,
    false,
  );
  predicates.push(`(${byAdmin} or ${byUser})`);
  if (!!budgetId && budgetId > 0 && !budgetServiceGroupServicesAllowed) {
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'AllowedBudgets',
        ODataOperators.Any,
        budgetId,
      ),
    );
  }

  if (optionalServiceTypes.length > 0) {
    let ids = optionalServiceTypes.map(f => {
      return { Id: f.Id } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'Id',
        ODataOperators.Includes,
        ids,
      ),
    );
  }

  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
export function OfflineServiceRelatedFilter(
  relatedGroups: number[],
  hideProject?: boolean,
  budgetId?: number,
  budgetServiceGroupServicesAllowed?: boolean,
): (string | Condition<IOfflineServiceFilterDto>)[] {
  const predicates: (string | Condition<IOfflineServiceFilterDto>)[] = [];
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'Active',
      ODataOperators.Equals,
      true,
    ),
  );
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'ServiceTypeId',
      ODataOperators.Equals,
      2,
    ),
  );
  if (relatedGroups.length > 0) {
    let ids = relatedGroups.map(f => {
      return { Id: f } as Identifiable<number>;
    });
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'ServiceGroupId',
        ODataOperators.Includes,
        ids,
      ),
    );
  }
  if (!!hideProject) {
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'HideProjects',
        ODataOperators.Equals,
        hideProject,
      ),
    );
  }
  let byAdmin = new Condition<IOfflineServiceFilterDto>(
    'IsAdmin',
    ODataOperators.Equals,
    true,
  );
  let byUser = `(${new Condition<IOfflineServiceFilterDto>(
    'StaffOnly',
    ODataOperators.Equals,
    false,
  )} and ${new Condition<IOfflineServiceFilterDto>(
    'AllowToUser',
    ODataOperators.Equals,
    true,
  )})`;
  predicates.push(`(${byAdmin} or ${byUser})`);
  if (!!budgetId && budgetId > 0 && !budgetServiceGroupServicesAllowed) {
    predicates.push(
      new Condition<IOfflineServiceFilterDto>(
        'AllowedBudgets',
        ODataOperators.Any,
        budgetId,
      ),
    );
  }

  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}

export function RenewStockRelatedFilter(): (
  | string
  | Condition<IOfflineServiceFilterDto>
)[] {
  const predicates: (string | Condition<IOfflineServiceFilterDto>)[] = [];
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'Active',
      ODataOperators.Equals,
      true,
    ),
  );
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'ServiceTypeId',
      ODataOperators.Equals,
      2,
    ),
  );
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'TrackInventory',
      ODataOperators.Equals,
      true,
    ),
  );

  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}

export function InventoryBatchRelatedFilter(): (
  | string
  | Condition<IOfflineServiceFilterDto>
)[] {
  const predicates: (string | Condition<IOfflineServiceFilterDto>)[] = [];
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'Active',
      ODataOperators.Equals,
      true,
    ),
  );
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'ServiceTypeId',
      ODataOperators.Equals,
      2,
    ),
  );
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'TrackInventory',
      ODataOperators.Equals,
      true,
    ),
  );
  predicates.push(
    new Condition<IOfflineServiceFilterDto>(
      'InventoryBatchesEnabled',
      ODataOperators.Equals,
      true,
    ),
  );
  if (predicates.length > 0) {
    return predicates;
  }
  return [];
}
