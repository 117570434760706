import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { assertExhaustive } from 'utils/assertExhaustive';
import { IConsumableServiceDto } from 'api/odata/generated/entities/IConsumableServiceDto';
import { IRequestDetailsModel } from '../../RequestSamplesPage/slice/types';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { dateUtils } from 'utils/date-utils';
import { newDate } from 'app/pages/ReservationDetails/Details/components/utils';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { MandatoryOfflineTypeEnum } from 'enums/MandatoryOfflineTypeEnum';
import { IServiceRequestMilestoneChargeDto } from 'api/odata/generated/entities/IServiceRequestMilestoneChargeDto';

export interface showAddonChargeProps {
  ServiceRequestInternalStatus: InternalServiceRequestStatusesUnion;
  Charged?: boolean;
  ServiceRequestAddonTypes: AddonTypesUnion;
  isAdmin: boolean;
}
export function showAddonCharge(props: showAddonChargeProps) {
  if (props.Charged === true) {
    return false;
  }
  if (props.ServiceRequestInternalStatus === 'Completed') {
    return false;
  }

  if (!props.isAdmin) {
    const userEditableStatuses: Array<InternalServiceRequestStatusesUnion> = [
      'Draft',
      'ReturnedToUser',
    ];
    if (!userEditableStatuses.includes(props.ServiceRequestInternalStatus)) {
      return false;
    }
  }

  switch (props.ServiceRequestAddonTypes) {
    case 'Addons':
    case 'Milestones':
      return props.isAdmin;
    case 'UserAddons':
      return true;
    case 'NA':
      return false;
    default:
      assertExhaustive(props.ServiceRequestAddonTypes);
  }
}

export function showManualCharge(props: showAddonChargeProps) {
  if (props.ServiceRequestAddonTypes === 'Milestones') {
    return !props.Charged && props.isAdmin;
  } else {
    return false;
  }
}

export const getConsumableIsMandatory = (
  consumable: IConsumableServiceDto,
  isAdmin: boolean,
  status?: InternalServiceRequestStatusesUnion,
) => {
  if (consumable.Mandatory === MandatoryOfflineTypeEnum.Mandatory_Users) {
    return true;
  } else if (
    consumable.Mandatory === MandatoryOfflineTypeEnum.Mandatory_OnCompletion
  ) {
    return (
      !!status &&
      !['Draft', 'ReturnedToUser', 'Pending'].includes(status) &&
      isAdmin
    );
  } else {
    return false;
  }
};
export const ConsumableTypeToService = (
  consumable: IConsumableServiceDto,
  request?: IRequestDetailsModel,
  user?: AuthenticatedUser,
  isAdmin?: boolean,
  chargeId?: number,
) => {
  let data: IOtherServices = {
    Id: 0,
    BookedBy:
      request?.CreatedFor.Id ?? request?.CreatedBy.Id ?? user?.Id ?? null,
    UserDisplayName:
      request?.CreatedFor.Name ?? request?.CreatedBy.Name ?? user?.Name ?? null,
    BudgetID: request?.Budget?.Id ?? null,
    Budget: request?.Budget?.Name ?? null,
    Quantity: consumable.DefaultQuantity,
    DefaultQuantity: consumable.DefaultQuantity,
    FundingType: request?.FundingTypeId || null,
    IntQuantityOnly: consumable.IntQuantityOnly,
    DiscountFactor: 1,
    PurchaseOrder: request?.PurchaseOrder ?? null,
    Mandatory: getConsumableIsMandatory(
      consumable,
      isAdmin ?? false,
      request?.Status?.InternalStatusId,
    ),
    Remarks: null,
    ReservationId: null,
    ServiceDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(newDate(null)),
    ),
    ServiceTypeID: consumable.Id,
    ServiceType: consumable.Name,
    ServiceGroupId: consumable.ServiceGroup.Id,
    Units: consumable.UnitType.Name,
    UnitTypeId: consumable.UnitType.Id,
    UserGroup: request?.UserGroup?.Id ?? null,
    UserGroupName: request?.UserGroup?.Name ?? null,
    AllowToUser: true,
    BudgetsTurnedOn: consumable.BudgetsTurnedOn,
    StaffOnly: false,
    TrackInventory: consumable.TrackInventory,
    NotLessThanZero: consumable.NotLessThanZero,
    InventoryBatchesEnabled: consumable.InventoryBatchesEnabled,
    InventoryBatchId: null,
    InventoryBatchName: null,
    InventoryBatchAmount: null,
    // Added missing properties
    InsertedBy: user?.Id ?? null,
    InsertedAt: new Date(),
    ServiceGroup: null,
    Institute: null,
    BudgetExperimentId: null,
    BudgetExperimentName: null,
    ExternalCustomerId: null,
    ExternalCustomerName: null,
    InstituteProjectId: null,
    InstituteProjectName: null,
    Reference: null,
    IsDeleted: false,
    SyncReservation: false,
    SyncUsage: false,
    HasCharges: false,
    RenewStockInventory: false,
    AutoGenerateNewBatches: false,
    Inventory: 0,
    ReservationOverlappingInventory: null,
    DepartmentId: null,
    HideProjects: consumable.HideProjects,
    InstituteId: null,
    InstituteTypeId: null,
    UsageId: null,
    ServiceRate: consumable.Rate,
    ChargeId: chargeId,
  };
  return data;
};
export const ChargeToService = (
  charge: IServiceRequestMilestoneChargeDto,
  request?: IRequestDetailsModel,
  user?: AuthenticatedUser,
  isAdmin?: boolean,
) => {
  let data: IOtherServices = {
    Id: 0,
    BookedBy:
      request?.CreatedFor.Id ?? request?.CreatedBy.Id ?? user?.Id ?? null,
    UserDisplayName:
      request?.CreatedFor.Name ?? request?.CreatedBy.Name ?? user?.Name ?? null,
    BudgetID: request?.Budget?.Id ?? null,
    Budget: request?.Budget?.Name ?? null,
    Quantity: charge.Quantity,
    DefaultQuantity: charge.Service?.DefaultQuantity ?? null,
    FundingType: request?.FundingTypeId || null,
    IntQuantityOnly: charge.Service?.IntQuantityOnly ?? false,
    DiscountFactor: 1,
    PurchaseOrder: request?.PurchaseOrder ?? null,
    Mandatory:
      charge.Service !== null
        ? getConsumableIsMandatory(
            charge.Service,
            isAdmin ?? false,
            request?.Status?.InternalStatusId,
          )
        : false,
    Remarks: null,
    ReservationId: null,
    ServiceDate: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(newDate(null)),
    ),
    ServiceTypeID: charge.OfflineServiceTypeId ?? -1,
    ServiceType: charge.Service?.Name ?? null,
    ServiceGroupId: charge.Service?.ServiceGroup?.Id ?? null,
    Units: charge.UnitType?.Name ?? null,
    UnitTypeId: charge.UnitType?.Id ?? null,
    UserGroup: request?.UserGroup?.Id ?? null,
    UserGroupName: request?.UserGroup?.Name ?? null,
    AllowToUser: true,
    BudgetsTurnedOn: charge.Service?.BudgetsTurnedOn ?? false,
    StaffOnly: false,
    TrackInventory: charge.Service?.TrackInventory ?? false,
    NotLessThanZero: charge.Service?.NotLessThanZero ?? true,
    InventoryBatchesEnabled: charge.Service?.InventoryBatchesEnabled ?? false,
    InventoryBatchId: null,
    InventoryBatchName: null,
    InventoryBatchAmount: null,
    // Added missing properties
    InsertedBy: user?.Id ?? null,
    InsertedAt: new Date(),
    ServiceGroup: null,
    Institute: null,
    BudgetExperimentId: null,
    BudgetExperimentName: null,
    ExternalCustomerId: null,
    ExternalCustomerName: null,
    InstituteProjectId: null,
    InstituteProjectName: null,
    Reference: null,
    IsDeleted: false,
    SyncReservation: false,
    SyncUsage: false,
    HasCharges: false,
    RenewStockInventory: false,
    AutoGenerateNewBatches: false,
    Inventory: 0,
    ReservationOverlappingInventory: null,
    DepartmentId: null,
    HideProjects: charge.Service?.HideProjects ?? false,
    InstituteId: null,
    InstituteTypeId: null,
    UsageId: null,
    ServiceRate: charge.Rate ?? 0,
    ChargeId: charge.Id,
  };
  return data;
};
export const ChargesConsumables = (
  charges: IServiceRequestMilestoneChargeDto[],
  consumables: IConsumableServiceDto[],
  request?: IRequestDetailsModel,
  user?: AuthenticatedUser,
  isAdmin?: boolean,
): IOtherServices[] => {
  if (consumables.length === 0) {
    if (
      charges.length === 0 ||
      !charges.some(c => c.OfflineServiceTypeId !== null)
    ) {
      return [];
    } else {
      return charges
        .filter(c => c.OfflineServiceTypeId !== null)
        .map(c => ChargeToService(c, request, user, isAdmin));
    }
  } else {
    let entered = charges
      .filter(c => c.OfflineServiceTypeId !== null)
      .map(c => {
        let consumable = consumables.find(f => f.Id === c.OfflineServiceTypeId);
        return consumable
          ? ChargeToService(
              Object.assign({}, c, { Service: consumable }),
              request,
              user,
              isAdmin,
            )
          : ChargeToService(c, request, user, isAdmin);
      });
    let missing = consumables
      .filter(
        f =>
          f.Mandatory === MandatoryOfflineTypeEnum.Mandatory_Default ||
          getConsumableIsMandatory(
            f,
            isAdmin ?? false,
            request?.Status?.InternalStatusId,
          ),
      )
      .filter(
        ext =>
          charges.length === 0 ||
          (charges.length > 0 &&
            !charges.some(f => f.OfflineServiceTypeId === ext.Id)),
      )
      .map(item => ConsumableTypeToService(item, request, user, isAdmin));
    return entered.concat(missing);
  }
};
