import { FormDepartmentPicker } from 'app/components/Forms/FormDepartmentPicker';
import { FormInstitutePicker } from 'app/components/Forms/FormInstitutePicker';
import { FormPermissionLevelPicker } from 'app/components/Forms/FormPermissionLevelPicker';
import { FormListener } from 'app/components/Forms/FormRender/FormRenderer';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { FormTimeSlotTypePicker } from 'app/components/Forms/FormTimeSlotTypePicker';
import { FormTrainingLevelPicker } from 'app/components/Forms/FormTrainingLevelPicker';
import { FormUserGroupsPicker } from 'app/components/Forms/FormUserGroupsPicker';
import { EntityStringSchema } from 'app/components/Forms/Schemas';
import { Formik } from 'formik';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICoresDirectoryRow } from 'types/ICoresDirectoryRow';
import * as yup from 'yup';
import { GroupPermissionsModel } from '.';

export interface UpdateGroupPermissionsFormProps {
  groups: ICoresDirectoryRow[];
  handleSubmitForm: (submit: any) => void;
  onSubmit: (model: GroupPermissionsModel) => void;
}

export function UpdateGroupPermissionsForm(
  props: UpdateGroupPermissionsFormProps,
) {
  //PROPS
  const { t } = useTranslation();

  //STATES
  const [instituteChanged, setInstituteChanged] =
    React.useState<boolean>(false);
  const [departmentChanged, setDepartmentChanged] =
    React.useState<boolean>(false);
  const [userGroupChanged, setUserGroupChanged] =
    React.useState<boolean>(false);

  //FUNCTIONS
  const handleInstituteChange = () => {
    setInstituteChanged(true);
  };

  const handleDepartmentChange = () => {
    setDepartmentChanged(true);
  };

  const handleUserGroupChange = () => {
    setUserGroupChanged(true);
  };

  const handleFormChange = React.useCallback(
    (values, isValid, dirty, setValue) => {
      if (instituteChanged) {
        setValue('Department', undefined, false);
        setValue('AdGroups', undefined, false);
        setInstituteChanged(false);
      }
      if (departmentChanged) {
        setValue('AdGroups', undefined, false);
        setDepartmentChanged(false);
      }
      if (userGroupChanged) {
        setValue('Institute', undefined, false);
        setUserGroupChanged(false);
      }
    },
    [departmentChanged, instituteChanged, userGroupChanged],
  );

  //SCHEMA
  const schema: yup.SchemaOf<GroupPermissionsModel> = yup.object({
    Institute: yup
      .mixed()
      .label(t(translations.Institute) as string)
      .notRequired(),
    Department: yup
      .mixed()
      .label(t(translations.Department) as string)
      .notRequired(),
    AdGroups: yup
      .array()
      .of(EntityStringSchema)
      .label(t(translations.UserGroup) as string)
      .min(1)
      .required(t(translations.err_UserGroupRequired) as string),
    PermissionLevel: yup
      .mixed()
      .label(t(translations.PermissionLevel) as string)
      .required(t(translations.err_SelectPermissionLevelException) as string),
    TimeSlotType: yup
      .mixed()
      .label(t(translations.TimeSlotType) as string)
      .required(t(translations.err_TimeSlotTypeRequired) as string),
    TrainingLevel: yup
      .mixed()
      .label(t(translations.TrainingLevel) as string)
      .notRequired(),
  });
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={
        {
          Institute: undefined,
          Department: undefined,
          AdGroups: [],
          PermissionLevel: undefined,
          TimeSlotType: undefined,
          TrainingLevel: undefined,
        } as GroupPermissionsModel
      }
      validationSchema={schema}
    >
      {formik => {
        props.handleSubmitForm(formik.submitForm);
        return (
          <StyledForm>
            <FormListener
              onFormChange={handleFormChange}
              fields={['Institute', 'Department']}
            />
            <FormLeftSection>
              <FormFieldsSection
                titleSection={t(
                  translations.SetPermissionsOnSelectedServiceGroups,
                )}
              >
                <FormRow fullRow>
                  <FormInstitutePicker
                    name="Institute"
                    id="InstituteId"
                    label={t(translations.Institute)}
                    disabled={formik.isSubmitting}
                    onChange={handleInstituteChange}
                    fullWidth
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormDepartmentPicker
                    name="Department"
                    id="DepartmentId"
                    label={t(translations.Department)}
                    disabled={formik.isSubmitting}
                    onChange={handleDepartmentChange}
                    fullWidth
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormUserGroupsPicker
                    name="AdGroups"
                    id="UserGroupId"
                    label={t(translations.UserGroup)}
                    disabled={formik.isSubmitting}
                    onChange={handleUserGroupChange}
                    fullWidth
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormPermissionLevelPicker
                    name="PermissionLevel"
                    id="permissionlvlId"
                    label={t(translations.PermissionLevel)}
                    disabled={formik.isSubmitting}
                    fullWidth
                  />
                </FormRow>
                <FormRow fullRow>
                  <FormTimeSlotTypePicker
                    name="TimeSlotType"
                    id="tstId"
                    label={t(translations.TimeSlotType)}
                    disabled={formik.isSubmitting}
                    fullWidth
                  />
                </FormRow>
                <FormRow>
                  <FormTrainingLevelPicker
                    id="TrainingLevelId"
                    name="TrainingLevel"
                    label={t(translations.TrainingLevel)}
                    //onChange={handleTrLevelChange}
                    /*                     predicates={TrainingLevelFilter(
                      formik.values.Equipments as IServiceFilterDto[],
                      trainingLevelPermissionsEnabled,
                      userPermissionsEnabled,
                    )} */
                    disabled={formik.isSubmitting}
                    fullWidth
                  />
                </FormRow>
              </FormFieldsSection>
            </FormLeftSection>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
