import { Chip, ChipProps, Theme, useTheme } from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { BasicTypography } from 'app/components/Typography/BasicTypography';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';
import { Entity } from 'types/common';
import { DetectIsMobile } from 'utils/mobileDetect';
import { FilterChip, FilterDisplayProps } from '..';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { FilterValueType } from '../../FilterValueType';
import clsx from 'clsx';

const InstrumentsFilterRoot = styled.div`
  &.filter-root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    border: 1px solid ${bookitColors.grayscale.grayBorders};
    padding: 0px 12px;
    border-radius: 8px;
    padding: 0px 12px;
    &.more-padding {
      padding: 6px 12px;
    }
    & .display-mobile-text {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 10ch;
    }
  }
`;
const InstrumentFilterChip = styled(
  ({
    color,
    theme,
    ...other
  }: {
    color?: string;
    theme: Theme;
  } & ChipProps) => <Chip {...other} />,
)`
  &.instrument-display-root {
    gap: ${props => props.theme.spacing(1)}px;
    max-width: 28ch;
    border-radius: 8px;
    color: white;
    background-color: ${props => (!!props.color ? props.color : '#ccc')};
    height: 28px;
    &::first-child {
      margin: 0;
    }
    & .instrument-display-text {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 20ch;
      color: inherit;
    }
  }
`;
function IsNotEmptyArray(
  entity: FilterValueType | undefined,
): entity is Extract<FilterValueType, any[]> {
  return Array.isArray(entity) && !!entity.length;
}
export function InstrumentsFilterDisplay<TRow>(
  props: FilterDisplayProps<TRow>,
) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = DetectIsMobile();
  const value = props.filter.value;
  const items = IsNotEmptyArray(value) ? (value as Array<Entity<number>>) : [];

  const handleDelete = event => {
    props.onDelete(props.filter);
  };
  return items.length > 0 ? (
    <InstrumentsFilterRoot
      className={clsx('filter-root', { 'more-padding': items.length > 7 })}
    >
      <BasicTypography
        component="span"
        noWrap
        variant="boldM"
        className={isMobile ? 'display-mobile-text' : undefined}
      >
        {t(props.filter.displayNameKey)}
        {':'}
        {props.filter.isInversed ? ' Not' : ''}
      </BasicTypography>

      {items
        .filter(chip => chip.Name !== undefined)
        .map(chip => (
          <InstrumentFilterChip
            theme={theme}
            key={chip.Id}
            className={'instrument-display-root'}
            onClick={props.openFilter}
            color={(chip as any)?.Color}
            label={
              <>
                {chip.Name !== undefined && chip.Name.length > 20 ? (
                  <Tooltip title={chip.Name} placement="bottom-start" arrow>
                    <span>
                      <BasicTypography
                        component="span"
                        noWrap
                        variant="bodyS"
                        className="instrument-display-text"
                      >
                        {chip.Name}
                      </BasicTypography>
                    </span>
                  </Tooltip>
                ) : (
                  <BasicTypography
                    component="span"
                    variant="bodyS"
                    noWrap
                    className="instrument-display-text"
                  >
                    {chip.Name}
                  </BasicTypography>
                )}
              </>
            }
            //   onDelete={handleDelete}
          />
        ))}
      <IconButton onClick={handleDelete} ariaLabel="remove">
        <Icon icon="times" />
      </IconButton>
    </InstrumentsFilterRoot>
  ) : (
    <FilterChip
      theme={theme}
      className={'filter-display-root'}
      onClick={props.openFilter}
      label={
        <>
          <BasicTypography
            component="span"
            noWrap
            className={isMobile ? 'filter-display-mobile-text' : undefined}
          >
            {t(props.filter.displayNameKey)}
          </BasicTypography>
          :&ensp;
          <BasicTypography
            component="span"
            noWrap
            className={isMobile ? 'filter-display-mobile-value' : undefined}
          >
            {t(translations.NA)}
          </BasicTypography>
        </>
      }
      onDelete={handleDelete}
    />
  );
}
