import { PageTypeProps, RenderPageType } from '../../slice/type';
import * as React from 'react';
import {
  TrainingDetails,
  TrainingDetailsProps,
} from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { StartQuiz, StartQuizProps } from 'app/pages/QuizPage/StartQuiz';
import {
  QuizDetails,
  QuizDetailsProps,
} from 'app/pages/QuizDetailsPage/QuizDetails';
import { AssetPopUp, AssetPopUpProps } from 'app/pages/AssetPopUp';
import {
  LocationDetails,
  LocationDetailsProps,
} from 'app/pages/LocationsPage/Details';
import {
  WorkOrderDetails,
  WorkOrderDetailsProps,
} from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { AddPayment, PaymentProps } from 'app/pages/AddPaymentPage';
import { AddCharge, ChargeProps } from 'app/pages/AddChargePage';
import {
  BillingActionsDetails,
  BillingActionsDetailsProps,
} from 'app/pages/BillingActions/BillingActionsDetailsPage/Details';
import {
  SavedViewDetails,
  SavedViewDetailsProps,
} from 'app/pages/SavedViewsPage/SavedViewPage/Details';
import { LabelTypePage } from 'app/pages/LabelTypesPage/LabelTypeEdit/Loadable';
import { LabelTypeEditProps } from 'app/pages/LabelTypesPage/LabelTypeEdit';
import {
  InstrumentRestrictions,
  InstrumentRestrictionsProps,
} from 'app/pages/InstrumentRestrictionsPage/Details/InstrumentRestrictions';
import {
  ReservationDetails,
  ReservationDetailsProps,
} from 'app/pages/ReservationDetails/Details';
import {
  InvoiceTemplatePopUp,
  InvoiceTemplatePopUpProps,
} from 'app/pages/InvoiceTemplates/InvoiceTemplatePopUp';
import {
  OtherServiceDetails,
  OtherServiceDetailsProps,
} from 'app/pages/OtherServiceDetails/Details';
import {
  PricePeriodDetails,
  PricePeriodDetailsProps,
} from 'app/pages/PriceSheetPeriodDetails/Details';
import {
  ArticleDetails,
  ArticleDetailsProps,
} from 'app/pages/ArticlesPage/ArticleDetails';
import {
  CoreSelection,
  CoreSelectionProps,
} from 'app/pages/CoreSelectionPage/CoreSelection';
import {
  AssetsSelection,
  AssetsSelectionProps,
} from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import {
  NewArticleDetailsForm,
  NewArticleDetailsFormProps,
} from 'app/pages/ArticlesPage/NewArticleDetailsForm';
import {
  EditArticleDetailsForm,
  EditArticleDetailsFormProps,
} from 'app/pages/ArticlesPage/EditArticleDetailsForm';
import {
  UserAttributesDetails,
  UserAttributesDetailsProps,
} from 'app/pages/UserAttributes/UserAttributesDetailsPage/Details';
import {
  ServiceRequestDetails,
  ServiceRequestDetailsProps,
} from 'app/pages/Samples/RequestSamplesPage';
import {
  ServiceRequestTicketDetails,
  ServiceRequestTicketDetailsProps,
} from 'app/pages/ServiceRequestTicketDetailsPage/ServiceRequestTicketDetails';
import {
  ReportConsumables,
  ReportConsumablesProps,
} from 'app/pages/OtherServiceDetails/ReportConsumables';
import {
  CalendarMessagesDetails,
  CalendarMessagesDetailsProps,
} from 'app/pages/CalendarMessages/CalendarMessagesDetails/Details';
import {
  ServiceRequestMilestoneChargeDetails,
  ServiceRequestMilestoneChargeDetailsProps,
} from 'app/pages/Samples/components/ServiceRequestMilestoneChargeDetails';
import {
  ServiceRequestMilestoneDetails,
  ServiceRequestMilestoneDetailsProps,
} from 'app/pages/Samples/components/ServiceRequestMilestoneDetails';
import {
  StockRenewal,
  StockRenewalProps,
} from 'app/pages/OtherServiceDetails/StockRenewal';
import {
  RenewStock,
  RenewStockProps,
} from 'app/pages/OtherServiceDetails/RenewStock';
import {
  InventoryBatch,
  InventoryBatchProps,
} from 'app/pages/OtherServiceDetails/InventoryBatch';
import {
  InvoiceBatchesComments,
  InvoiceBatchesCommentsProps,
} from 'app/pages/InvoiceBatches/InvoiceBatchesComments';
import {
  InventoryLog,
  InventoryLogProps,
} from 'app/pages/AssetsInventoryLog/InventoryLog';
import {
  ArticleAssociationForm,
  ArticleAssociationFormProps,
} from 'app/pages/ArticlesPage/ArticleAssociationForm';
import {
  RequestStatusesDetails,
  RequestStatusesDetailsProps,
} from 'app/pages/RequestStatuses/RequestStatusesDetailsPage/Details';
import {
  MultipleReservations,
  MultipleReservationsProps,
} from 'app/pages/ReservationDetails/Details/MultipleReservations';
import {
  SignUpWaitingListDetails,
  SignUpWaitingListDetailsProps,
} from 'app/pages/ReservationWaitingListPage/SignUpWaitingListDetails';
import { InvoiceDetails, InvoiceDetailsProps } from 'app/pages/InvoiceDetails';
import {
  SamplePlatesDetails,
  SamplePlatesDetailsProps,
} from 'app/pages/Samples/SamplePlatesDetailsPage/Details';
import { AssetFile, AssetFileProps } from 'app/pages/AssetFile';
import {
  UsageDetails,
  UsageDetailsProps,
} from 'app/pages/UsageDetails/Details';
import {
  RegionDetails,
  RegionDetailsProps,
} from 'app/pages/Regions/RegionsDetailsPage/Details';
import { UserDetails, UserDetailsProps } from 'app/pages/UserDetails/Details';
import {
  SubscribersDetails,
  SubscribersDetailsProps,
} from 'app/pages/SubscribersDetailsPage/Details';
import {
  CostCentersDetails,
  CostCentersDetailsProps,
} from 'app/pages/CostCentersDetailsPage/Details';
import {
  OfflineTypeReferencesDetails,
  OfflineTypeReferencesDetailsProps,
} from 'app/pages/OfflineTypeReferencesDetails/Details';
import {
  OfflineTypeReferencesPopUp,
  OfflineTypeReferencesPopUpProps,
} from 'app/pages/OfflineTypeReferencesPage/OfflineTypeReferencesPopUp';
import {
  CustomFormPreview,
  CustomFormPreviewProps,
} from 'app/pages/CustomFormPreviewPage/CustomFormPreview';

export interface SidePanelRendererProps {
  renderPageType?: RenderPageType;
  renderPageProps: PageTypeProps;
}

export function SidePanelRenderer(props: SidePanelRendererProps) {
  const { renderPageType, renderPageProps } = props;

  switch (renderPageType) {
    case RenderPageType.TrainingDetails:
      return <TrainingDetails {...(renderPageProps as TrainingDetailsProps)} />;
    case RenderPageType.QuizCreate:
      return <StartQuiz {...(renderPageProps as StartQuizProps)} />;
    case RenderPageType.QuizzDetails:
      return <QuizDetails {...(renderPageProps as QuizDetailsProps)} />;
    case RenderPageType.LocationDetails:
      return <LocationDetails {...(renderPageProps as LocationDetailsProps)} />;
    case RenderPageType.AssetDetails:
      return <AssetPopUp {...(renderPageProps as AssetPopUpProps)} />;
    case RenderPageType.WorkOrderDetails:
      return (
        <WorkOrderDetails {...(renderPageProps as WorkOrderDetailsProps)} />
      );
    case RenderPageType.AddCharge:
      return <AddCharge {...(renderPageProps as ChargeProps)} />;
    case RenderPageType.AddPayment:
      return <AddPayment {...(renderPageProps as PaymentProps)} />;
    case RenderPageType.InvoiceDetails:
      return <InvoiceDetails {...(renderPageProps as InvoiceDetailsProps)} />;
    case RenderPageType.AddBillingAction:
      return (
        <BillingActionsDetails
          {...(renderPageProps as BillingActionsDetailsProps)}
        />
      );
    case RenderPageType.SavedViewDetails:
      return (
        <SavedViewDetails {...(renderPageProps as SavedViewDetailsProps)} />
      );
    case RenderPageType.LabelType:
      return <LabelTypePage {...(renderPageProps as LabelTypeEditProps)} />;
    case RenderPageType.OtherServiceDetails:
      return (
        <OtherServiceDetails
          {...(renderPageProps as OtherServiceDetailsProps)}
        />
      );
    case RenderPageType.RestrictionDetails:
      return (
        <InstrumentRestrictions
          {...(renderPageProps as InstrumentRestrictionsProps)}
        />
      );
    case RenderPageType.ReservationDetails:
      return (
        <ReservationDetails {...(renderPageProps as ReservationDetailsProps)} />
      );
    case RenderPageType.PricePeriodsDetails:
      return (
        <PricePeriodDetails {...(renderPageProps as PricePeriodDetailsProps)} />
      );
    case RenderPageType.Article:
      return <ArticleDetails {...(renderPageProps as ArticleDetailsProps)} />;
    case RenderPageType.ArticleNew:
      return (
        <NewArticleDetailsForm
          {...(renderPageProps as NewArticleDetailsFormProps)}
        />
      );
    case RenderPageType.ArticleEdit:
      return (
        <EditArticleDetailsForm
          {...(renderPageProps as EditArticleDetailsFormProps)}
        />
      );
    case RenderPageType.InvoiceTemplatePopUp:
      return (
        <InvoiceTemplatePopUp
          {...(renderPageProps as InvoiceTemplatePopUpProps)}
        />
      );
    case RenderPageType.CoreSelection:
      return <CoreSelection {...(renderPageProps as CoreSelectionProps)} />;
    case RenderPageType.AssetsSelection:
      return <AssetsSelection {...(renderPageProps as AssetsSelectionProps)} />;
    case RenderPageType.UserAttributesDetails:
      return (
        <UserAttributesDetails
          {...(renderPageProps as UserAttributesDetailsProps)}
        />
      );
    case RenderPageType.ServiceRequestDetails:
      return (
        <ServiceRequestDetails
          {...(renderPageProps as ServiceRequestDetailsProps)}
        />
      );
    case RenderPageType.ServiceRequestTicketDetails:
      return (
        <ServiceRequestTicketDetails
          {...(renderPageProps as ServiceRequestTicketDetailsProps)}
        />
      );
    case RenderPageType.CalendarMessagesDetails:
      return (
        <CalendarMessagesDetails
          {...(renderPageProps as CalendarMessagesDetailsProps)}
        />
      );
    case RenderPageType.ReportConsumables:
      return (
        <ReportConsumables {...(renderPageProps as ReportConsumablesProps)} />
      );
    case RenderPageType.ServiceRequestMilestone:
      return (
        <ServiceRequestMilestoneDetails
          {...(renderPageProps as ServiceRequestMilestoneDetailsProps)}
        />
      );
    case RenderPageType.ServiceRequestMilestoneCharge:
      return (
        <ServiceRequestMilestoneChargeDetails
          {...(renderPageProps as ServiceRequestMilestoneChargeDetailsProps)}
        />
      );
    case RenderPageType.StockRenewal:
      return <StockRenewal {...(renderPageProps as StockRenewalProps)} />;
    case RenderPageType.RenewStock:
      return <RenewStock {...(renderPageProps as RenewStockProps)} />;
    case RenderPageType.InventoryBatch:
      return <InventoryBatch {...(renderPageProps as InventoryBatchProps)} />;
    case RenderPageType.InvoiceBatchesComments:
      return (
        <InvoiceBatchesComments
          {...(renderPageProps as InvoiceBatchesCommentsProps)}
        />
      );
    case RenderPageType.AssetInventoryLog:
      return <InventoryLog {...(renderPageProps as InventoryLogProps)} />;
    case RenderPageType.ArticleForm:
      return (
        <ArticleAssociationForm
          {...(renderPageProps as ArticleAssociationFormProps)}
        />
      );
    case RenderPageType.RequestStatusesDetails:
      return (
        <RequestStatusesDetails
          {...(renderPageProps as RequestStatusesDetailsProps)}
        />
      );
    case RenderPageType.MultiReservations:
      return (
        <MultipleReservations
          {...(renderPageProps as MultipleReservationsProps)}
        />
      );
    case RenderPageType.SignUpWaitingListDetails:
      return (
        <SignUpWaitingListDetails
          {...(renderPageProps as SignUpWaitingListDetailsProps)}
        />
      );
    case RenderPageType.SamplePlatesDetails:
      return (
        <SamplePlatesDetails
          {...(renderPageProps as SamplePlatesDetailsProps)}
        />
      );
    case RenderPageType.AssetFile:
      return (
        <AssetFile {...(renderPageProps as AssetFileProps)} useSidePanel />
      );
    case RenderPageType.UsageDetails:
      return <UsageDetails {...(renderPageProps as UsageDetailsProps)} />;
    case RenderPageType.RegionDetails:
      return <RegionDetails {...(renderPageProps as RegionDetailsProps)} />;
    case RenderPageType.UserProfile:
      return (
        <UserDetails
          screenType="UserProfile"
          {...(renderPageProps as UserDetailsProps)}
        />
      );
    case RenderPageType.SubscribersDetails:
      return (
        <SubscribersDetails {...(renderPageProps as SubscribersDetailsProps)} />
      );
    case RenderPageType.CostCentersDetails:
      return (
        <CostCentersDetails {...(renderPageProps as CostCentersDetailsProps)} />
      );
    case RenderPageType.OfflineTypeRefDetails:
      return (
        <OfflineTypeReferencesDetails
          {...(renderPageProps as OfflineTypeReferencesDetailsProps)}
        />
      );
    case RenderPageType.OfflineTypeRefs:
      return (
        <OfflineTypeReferencesPopUp
          {...(renderPageProps as OfflineTypeReferencesPopUpProps)}
        />
      );
    case RenderPageType.CustomFormPreview:
      return (
        <CustomFormPreview {...(renderPageProps as CustomFormPreviewProps)} />
      );
    default:
      return <React.Fragment> </React.Fragment>;
  }
}
