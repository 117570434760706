import { Divider, TableContainer } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TableCardGrid } from '../TableCardGrid';
import clsx from 'clsx';
import {
  TableSkeletonRoot,
  TableRowsSkeletonRoot,
  HeaderSkeleton,
  CellSkeleton,
  ShadowSkeleton,
  CardSkeleton,
  CalendarSkeletonRoot,
} from './styled';

/* 
xs (for phones - screens less than 768px wide)
sm (for tablets - screens equal to or greater than 768px wide)
md (for small laptops - screens equal to or greater than 992px wide)
lg (for laptops and desktops - screens equal to or greater than 1200px wide) 
*/

const headerHeight = 48;
const toolbarHeight = 30;
const searchBarHeight = 30;
//const tableHeaderHeight = 30;
//const tableRowHeight = 30;
//const tableColumnHeight = 438;
//const tableFooterHeight = 30;

const numOfHeaderItems = 7;
const numOfColumns = 7;
const numOfRows = 11;
const numberOfCards = 12;
const numOfCalendarRows = 24;

const RenderTableRowsItems = () => {
  return [...Array(numOfRows).keys()].map(key => (
    //Row item
    <div
      className="item-cell"
      key={key}
      role="alert"
      aria-live="polite"
      aria-busy="true"
    >
      <div className={'item-content'}>
        <CellSkeleton variant="rect" />
      </div>
    </div>
  ));
};
const RenderCalendarRowsItems = () => {
  return [...Array(numOfCalendarRows).keys()].map(key => (
    //Row item
    <div
      className="item-cell"
      key={key}
      role="alert"
      aria-live="polite"
      aria-busy="true"
    >
      <div className={'item-content'}>
        <CellSkeleton variant="rect" />
      </div>
    </div>
  ));
};

const RenderTableHeaderCells = () => {
  return [...Array(numOfHeaderItems).keys()].map(key => (
    <div className="table-skeleton-header-cell" key={key}>
      <div className="table-skeleton-header-content">
        <div className="table-skeleton-header-item">
          <HeaderSkeleton variant="rect" />
        </div>
      </div>
    </div>
  ));
};

const RenderTableRows = () => {
  return [...Array(numOfColumns).keys()].map(key => (
    //TableRow
    <div className="table-skeleton-row-cell" key={key}>
      <div className="table-skeleton-row-content">
        <div className="table-skeleton-row-item">{RenderTableRowsItems()}</div>
      </div>
    </div>
  ));
};
const RenderCalendarRows = () => {
  return [...Array(numOfColumns).keys()].map(key => (
    //TableRow
    <div className="table-skeleton-row-cell" key={key}>
      <div className="table-skeleton-row-content">
        <div className="table-skeleton-row-item">
          {RenderCalendarRowsItems()}
        </div>
      </div>
    </div>
  ));
};

/* const RenderFooter = classes => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4} className={classes.tableFooterContainer}>
        <Grid container spacing={2}>
          <Grid item xs md>
            <Skeleton variant="rect" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="rect" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="rect" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
          <Grid item xs md>
            <Skeleton variant="circle" className={classes.tableFooter} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}; */
export function TableCardsSkeleton({ isMobile }: { isMobile: boolean }) {
  return (
    <TableCardGrid>
      {[...Array(numberOfCards).keys()].map(key => (
        <CardSkeleton
          key={key}
          className={clsx('card-content', {
            'card-vertical': !isMobile,
            'card-horizontal': isMobile,
          })}
        >
          <Skeleton
            variant="rect"
            width="100%"
            height="100%"
            className={'card-skelet'}
          />
        </CardSkeleton>
      ))}
    </TableCardGrid>
  );
}
export interface TableRowsSkeletonProps {
  id?: string;
  isMobile: boolean;
  isCards: boolean;
}
export function TableRowsSkeleton(props: TableRowsSkeletonProps) {
  const { isMobile, isCards } = props;
  //RENDER
  return (
    <TableRowsSkeletonRoot className={'table-rows-skeleton-root'} id={props.id}>
      {/* Table */}
      {/* TableHeaders */}

      {isCards ? (
        <TableCardsSkeleton isMobile={isMobile} />
      ) : (
        <TableContainer>
          <div className="table-skeleton-grid" key="headerKey">
            {RenderTableHeaderCells()}
          </div>
          {/* TableBody */}
          <div className="table-skeleton-grid" key="bodyKey">
            {RenderTableRows()}
          </div>
        </TableContainer>
      )}

      {/* TableFooter */}
      {/* {RenderFooter(classes)} */}
    </TableRowsSkeletonRoot>
  );
}
export function CalendarSkeleton(props: TableRowsSkeletonProps) {
  //RENDER
  return (
    <CalendarSkeletonRoot className={'calendar-skeleton-root'} id={props.id}>
      <div className="calendar-conteiner">
        <div className="table-skeleton-grid" key="headerKey">
          {RenderTableHeaderCells()}
        </div>
        {/* TableBody */}
        <div className="table-skeleton-grid" key="bodyKey">
          {RenderCalendarRows()}
        </div>
      </div>
    </CalendarSkeletonRoot>
  );
}

export function TableSkeleton(props: TableRowsSkeletonProps) {
  const { isCards, isMobile } = props;
  //RENDER
  return (
    <TableSkeletonRoot
      className={'table-skeleton-root'}
      role="alert"
      aria-live="polite"
      aria-busy="true"
      id={props.id}
    >
      {/* Header */}
      <div className="table-skeleton-header">
        <div className="table-skeleton-header-cell">
          <ShadowSkeleton variant="text" height={headerHeight} />
        </div>
      </div>
      {/* Toolbar */}
      <div className="table-skeleton-toolbar">
        <div className="table-skeleton-toolbar-cell">
          <div className="table-skeleton-toolbar-item">
            <ShadowSkeleton variant="rect" height={toolbarHeight} />
          </div>
          <div className="table-skeleton-toolbar-item-2">
            <ShadowSkeleton variant="rect" height={toolbarHeight} />
          </div>
        </div>
      </div>
      <div className="table-skeleton-divider">
        <Divider />
      </div>
      {/* Search bar */}
      <div className="table-skeleton-search-bar">
        <div className="table-skeleton-search-bar-item">
          <ShadowSkeleton variant="rect" height={searchBarHeight} />
        </div>
        <div className="table-skeleton-search-bar-item">
          <ShadowSkeleton variant="rect" height={searchBarHeight} />
        </div>
        <div className="table-skeleton-search-bar-item">
          <ShadowSkeleton variant="rect" height={searchBarHeight} />
        </div>
        <div className="table-skeleton-search-bar-item-2">
          <ShadowSkeleton variant="rect" height={searchBarHeight} />
        </div>
      </div>
      {/* Table */}
      {/* TableHeaders */}
      <TableRowsSkeletonRoot className={'table-rows-skeleton-root'}>
        {isCards ? (
          <TableCardsSkeleton isMobile={isMobile} />
        ) : (
          <TableContainer>
            <div className="table-skeleton-grid">
              {RenderTableHeaderCells()}
            </div>
            {/* TableBody */}
            <div className="table-skeleton-grid">{RenderTableRows()}</div>
          </TableContainer>
        )}
      </TableRowsSkeletonRoot>
    </TableSkeletonRoot>
  );
}
