import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export interface GroupsIconProps {
  groupsCount: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selected?: boolean;
}

export function GroupsIcon(props: GroupsIconProps) {
  const { t } = useTranslation();
  const { onClick, groupsCount, selected } = props;
  return (
    <IconButton
      onClick={onClick}
      shape="circle"
      variant="textGray"
      size="xs"
      title={t(translations.SwitchToOtherLaboratory)}
    >
      {groupsCount > 1 ? (
        <GroupIcon className="fa-layers fa-fw">
          <Icon icon={[selected ? 'far' : 'fal', 'pencil']} size="lg" />
          <span className="fa-layers-counter">{`+${groupsCount - 1}`}</span>
        </GroupIcon>
      ) : (
        <Icon icon={[selected ? 'far' : 'fal', 'pencil']} size="lg" />
      )}
    </IconButton>
  );
}
// const GroupIcon = styled('span')(({ theme }) => ({
//   fontSize: '20px',
//   color: bookitColors.grayscale.graySubtitles,
//   width: '20px',
//   height: '20px',
//   '& .fa-layers-counter': {
//     whiteSpace: 'nowrap',
//     fontSize: '22px',
//     right: '21px',
//     top: '-2px',
//     backgroundColor: 'inherit',
//     color: 'inherit',
//     transform: 'scale(var(--fa-counter-scale, 0.6))',
//     padding: 'var(--fa-counter-padding, 0.25em 0.25em)',
//   },
// }));
const GroupIcon = styled.span`
  color: ${bookitColors.grayscale.graySubtitles};
  & .fa-layers-counter {
    white-space: nowrap;
    font-size: 18px;
    right: 16px;
    top: -10px;
    background-color: inherit;
    color: inherit;
    transform: scale(var(--fa-counter-scale, 0.6));
    padding: var(--fa-counter-padding, 0.25em 0.25em);
  }
`;
