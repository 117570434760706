import * as React from 'react';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { CustomFormField } from '../CustomFormFields';
import {
  CustomFormValueType,
  getFieldsVisibilities,
  getSerializableIsRequired,
  isRequired,
} from '../CustomFormUtils';
import { ValidationSchemaProvider } from 'app/components/Forms/ValidationSchemaProvider';
import { FormFieldsSection, FormRow } from 'app/components/Forms/FormsLayout';
import { sortBy } from 'lodash';
import { FormFieldsPerRowVariant } from 'enums/FormFieldsPerRowVariant';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import { CustomFormAsSection } from '../CustomFormAsSection';
import { StyledFormProps } from 'app/components/Forms/FormsLayout/StyledForm';

export interface CustomFormContentProps {
  formType: CustomFormTypeEnum;
  title?: string;
  formik: FormikProps<Record<string, unknown>>;
  schema: Yup.SchemaOf<object>;
  formFields: IFormFieldDto[];
  validationEnabled?: boolean;
  isAdmin?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  fieldsPerRow?: FormFieldsPerRowVariant;
  onFieldChange?: (value: any, formField: IFormFieldDto) => void;
  visibleOnCompletion?: () => boolean;
  sectionProps?: Omit<StyledFormProps, 'onSubmit' | 'ref' | 'children'>;
}
export function CustomFormContent({
  formType,
  title,
  formik,
  schema,
  formFields,
  validationEnabled,
  isAdmin,
  readonly,
  disabled,
  fieldsPerRow = FormFieldsPerRowVariant.TwoFields,
  onFieldChange,
  visibleOnCompletion,
  sectionProps,
}: CustomFormContentProps) {
  const isVisibleField = (
    field: IFormFieldDto,
    isAdmin: boolean,
    values: Record<string, any> | undefined,
    visibleOnCompletion?: () => boolean,
  ): boolean => {
    let visibilities = getFieldsVisibilities(
      formFields,
      isAdmin,
      values,
      visibleOnCompletion,
    );
    if (visibilities) {
      return visibilities[field.Id];
    }
    return true;
  };
  // const sortedActiveFormFields = React.useMemo(
  //   () =>
  //     sortBy(
  //       formFields.filter(f => f.Active === true),
  //       f => f.Index,
  //     ),
  //   [formFields],
  // );
  const sortedActiveFormFields = React.useMemo(() => {
    let values = formik.values as Record<string, any> | undefined;
    return sortBy(
      formFields.filter(f => f.Active === true),
      f => f.Index,
    ).map(f => {
      let parentDepValue = null;
      let parentReqValue = null;

      if (f.DependencyFieldId !== null) {
        if (!!values && Object.keys(values).length > 0) {
          parentDepValue = values[String(f.DependencyFieldId)];
        }
      }
      if (f.RequiredFieldToCompare !== null) {
        if (!!values && Object.keys(values).length > 0) {
          parentReqValue = values[String(f.RequiredFieldToCompare)];
        }
      }
      return {
        fieldRequired:
          isRequired(
            f,
            isAdmin || false,
            formik?.values?.[f.Id],
            parentDepValue,
            parentReqValue,
          ) &&
          getSerializableIsRequired(
            f,
            formik?.values?.[f.Id] as CustomFormValueType,
          ),
        field: f,
      };
    });
  }, [formFields, formik.values, isAdmin]);
  return (
    <ValidationSchemaProvider
      schema={schema}
      skipValidation={!validationEnabled}
    >
      <CustomFormAsSection
        asSection={true}
        fieldsPerRow={fieldsPerRow}
        {...sectionProps}
      >
        <FormFieldsSection
          titleSection={title}
          useRequest={formType === CustomFormTypeEnum.Request}
          useCustomFormSection={
            fieldsPerRow === FormFieldsPerRowVariant.ThreeFields
          }
        >
          {sortedActiveFormFields.map((formField, index) => {
            // paragraphs & form sections deserve to start from the new line in 2 column layout (full screen)
            const fullRow =
              (formField.field.Type !== null &&
                ['Paragraph', 'FormSection'].includes(formField.field.Type)) ||
              formField.field.SingleColumnView ||
              fieldsPerRow === FormFieldsPerRowVariant.OneField;
            return (
              <FormRow
                key={formField.field.Id}
                hide={
                  isVisibleField(
                    formField.field,
                    isAdmin || false,
                    formik.values,
                    visibleOnCompletion,
                  ) === false
                }
                fullRow={fullRow}
              >
                <CustomFormField
                  name={String(formField.field.Id)}
                  id={String(formField.field.Id)}
                  key={index}
                  formField={formField.field}
                  readonly={readonly}
                  disabled={readonly || formik.isSubmitting || disabled}
                  required={formField.fieldRequired}
                  onChange={
                    !!onFieldChange
                      ? value => onFieldChange(value, formField.field)
                      : undefined
                  }
                />
              </FormRow>
            );
          })}
        </FormFieldsSection>
      </CustomFormAsSection>
    </ValidationSchemaProvider>
  );
}
