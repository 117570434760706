import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectglobalSettings,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { BudgetFields } from 'enums/BudgetFields';
import { UserReservationsRestricted } from 'enums/enums';
import { MultipleInstrumentReservations } from 'enums/MultipleInstrumentReservations';
import { ServiceGroupAdminsManageUsersEnum } from 'enums/ServiceGroupAdminsManageUsersEnum';
import { intersection } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { IsBudgetFieldEnabled, IsModuleEnabled } from 'types/AppSettings';
import { isInRole } from 'types/AuthenticatedUser';
import { KnownModules } from 'types/KnownModules';
import { AllowedSettings } from 'utils/globalSettings/AllowedSettings';
export interface GlobalSettingsType {
  loadCompleted: boolean;
  reservationRestricted: boolean;
  reservationUserGroupGroupByBudgetUserGroup: boolean;
  userGroupReservationRestricted: boolean;
  onlyOwnUserGroupsAllowed: boolean;
  userDefaultBudgetEnabled: boolean;
  defaultBudgetEnabled: boolean;
  hideNonDefaultBudgets: boolean;
  isBudgetsWithoutUserGroup: boolean;
  budgetModuleEnabled: boolean;
  priceSheetPricing: boolean;
  billingModuleEnabled: boolean;
  budgetLimitedByUsers: boolean;
  budgetExperimentModuleEnabled: boolean;
  fundingTypeBudgetEnabled: boolean;
  purchaseOrderBudgetEnabled: boolean;
  purchaseOrderLengthSetting: number | undefined;
  allowPartialProjectCharges: boolean;
  allowPartialSingleProjectCharges: boolean;
  trainingSessionEnabled: boolean;
  assistedReservationsEnabled: boolean;
  resCustomFormEnabled: boolean;
  resMultipleFormsEnabled: boolean;
  multipleInstrumentsEnabled: boolean;
  reservationTerminationEnabled: boolean;
  crossInstrumentTrainingEnabled: boolean;
  userAndGroupPermissionsEnabled: boolean;
  tutoringModulesEnabled: boolean;
  tutoringEnabled: boolean;
  offlineServiceWithReservation: boolean;
  userIsMainAdmin: boolean;
  instituteProjectsEnabled: boolean;
  projectsAreOptional: boolean;
  showReferenceFieldInReservationDetails: boolean;
  accServicesEnabled: boolean;
  showCalcPriceOnOfflineService: boolean;
  allowUsersToViewTheirCharges: boolean;
  allowOngoingReservationModificationEnabled: boolean;
  showCalculatePrice: boolean;
  allowAddRemoveOnEditReservation: boolean;
  allowToRemoveFromMultipleReservation: boolean;
  draftReservationsEnabled: boolean;
  hideReservationCollaborators: boolean;
  MandatoryOfflineServicesOnTrainingSignUps: boolean;
  DoNotAllowReservationUserChangeByStaff: boolean;
  AssemblyPartsEnabled: boolean;
  confidentialBudgetsEnabled: boolean;
  AllowWorkflowBooking: boolean;
  ModifyReservationNameForUsers: boolean;
  ShowDefaultUserGroupOnUserProfileEnabled: boolean;
  ServiceRequestReservationEnabled: boolean;
  LoanDeskEnabled: boolean;
  ServiceGroupAdminsCanManageAllUsers: boolean;
  ServiceGroupAdminsCanManageAdUsers: boolean;
  EnableCoreAffilationManagment: boolean;
  UserServiceGroupCommentsEnabled: boolean;
  UserLabTechCommentsEnabled: boolean;
  DisplayEditUserDetails: boolean;
  MultipleAdGroupsEnabled: boolean;
  OpenUserProfileOnSidePanel: boolean;
  maximumEmailRecipients: number;
  hideRepetitiveReservations?: boolean;
}

function useGlobalSettingsHook(): GlobalSettingsType {
  const adminRoles = React.useMemo(
    () => [
      Roles.Administrators,
      Roles.EquipmentAdministrator,
      Roles.GroupAdministrators,
      Roles.LabTech,
      Roles.InstituteCoord,
    ],
    [],
  );
  const privateBudgetsRoles = React.useMemo(
    () => [Roles.Administrators, Roles.HeadOfLab],
    [],
  );
  const User = useSelector(selectAuthenticatedUser);
  const userRoles: number[] | undefined = User?.Roles;
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const [reservationRestricted, setReservationRestricted] =
    React.useState<boolean>(false);
  const [budgetModuleEnabled, setBudgetModuleEnabled] =
    React.useState<boolean>(false);
  const [priceSheetPricing, setPriceSheetPricing] =
    React.useState<boolean>(false);
  const [purchaseOrderLengthSetting, setPurchaseOrderLengthSetting] =
    React.useState<number | undefined>();
  const [hideRepetitiveReservations, setHideRepetitiveReservations] =
    React.useState<boolean | undefined>();
  const [billingEnabled, setBillingEnabled] = React.useState<boolean>(false);
  const [userIsMainAdmin, setUserIsMainAdmin] = React.useState<boolean>(false);
  const userSettings = useSelector(selectUserProfileSettings);
  const [userServiceGroupCommentsEnabled, setUserServiceGroupCommentsEnabled] =
    React.useState<boolean>(false);
  const [userLabTechCommentsEnabled, setUserLabTechCommentsEnabled] =
    React.useState<boolean>(false);
  const [
    allowOngoingReservationModificationEnabled,
    setAllowOngoingReservationModificationEnabled,
  ] = React.useState<boolean>(false);
  const [
    doNotAllowReservationUserChangeByStaff,
    setDoNotAllowReservationUserChangeByStaff,
  ] = React.useState<boolean>(false);
  const [instituteProjectsEnabled, setInstituteProjectsEnabled] =
    React.useState<boolean>(false);
  const [reservationTerminationEnabled, setReservationTerminationEnabled] =
    React.useState<boolean>(false);
  const [displayEditUserDetails, setDisplayEditUserDetails] =
    React.useState<boolean>(false);
  const [multipleAdGroupsEnabled, setMultipleAdGroupsEnabled] =
    React.useState<boolean>(false);
  const [draftReservationsEnabled, setDraftReservationsEnabled] =
    React.useState<boolean>(false);
  const [
    MandatoryOfflineServicesOnTrainingSignUps,
    setMandatoryOfflineServicesOnTrainingSignUps,
  ] = React.useState<boolean>(false);
  const [crossInstrumentTrainingEnabled, setCrossInstrumentTrainingEnabled] =
    React.useState<boolean>(false);
  const [userAndGroupPermissionsEnabled, setUserAndGroupPermissionsEnabled] =
    React.useState<boolean>(false);
  const [
    serviceGroupAdminsCanManageAdUsers,
    setServiceGroupAdminsCanManageAdUsers,
  ] = React.useState<boolean>(false);
  const [openUserProfileOnSidePanel, setOpenUserProfileOnSidePanel] =
    React.useState<boolean>(false);

  const [
    serviceGroupAdminsCanManageAllUsers,
    setServiceGroupAdminsCanManageAllUsers,
  ] = React.useState<boolean>(false);
  const [tutoringModulesEnabled, setTutoringModulesEnabled] =
    React.useState<boolean>(false);
  const [tutoringEnabled, setTutoringEnabled] = React.useState<boolean>(false);
  const [accServicesEnabled, setAccServicesEnabled] =
    React.useState<boolean>(false);
  const [allowUsersToViewTheirCharges, setAllowUsersToViewTheirCharges] =
    React.useState<boolean>(false);

  const [
    showReferenceFieldInReservationDetails,
    setShowReferenceFieldInReservationDetails,
  ] = React.useState<boolean>(false);

  const [offlineServiceWithReservation, setOfflineServiceWithReservation] =
    React.useState<boolean>(false);
  const [multipleInstrumentsEnabled, setMultipleInstrumentsEnabled] =
    React.useState<boolean>(false);
  const [trainingSessionEnabled, setTrainingSessionEnabled] =
    React.useState<boolean>(false);
  const [reservationFormEnabled, setReservationFormEnabled] =
    React.useState<boolean>(false);
  const [resMultipleFormsEnabled, setResMultipleFormsEnabled] =
    React.useState<boolean>(false);
  const [assistedReservationsEnabled, setAssistedReservationsEnabled] =
    React.useState<boolean>(false);
  const [showCalcPriceOnOfflineService, setShowCalcPriceOnOfflineService] =
    React.useState<boolean>(false);
  const [showCalculatePrice, setShowCalculatePrice] =
    React.useState<boolean>(false);
  const [allowPartialProjectCharges, setAllowPartialProjectCharges] =
    React.useState<boolean>(false);
  const [
    allowPartialSingleProjectCharges,
    setAllowPartialSingleProjectCharges,
  ] = React.useState<boolean>(false);

  const [budgetExperimentModuleEnabled, setBudgetExperimentModuleEnabled] =
    React.useState<boolean>(false);
  const [fundingTypeBudgetEnabled, setFundingTypeBudgetEnabled] =
    React.useState<boolean>(false);
  const [purchaseOrderBudgetEnabled, setPurchaseOrderBudgetEnabled] =
    React.useState<boolean>(false);
  const [isBudgetsWithoutUserGroup, setIsBudgetsWithoutUserGroup] =
    React.useState<boolean>(false);
  const [requestReservationEnabled, setRequestReservationEnabled] =
    React.useState<boolean>(false);
  const [budgetLimitedByUsers, setIsBudgetLimitedByUsers] =
    React.useState<boolean>(false);
  const [hideNonDefaultBudgets, setHideNonDefaultBudgets] =
    React.useState<boolean>(false);
  const [assemblyPartsEnabled, setAssemblyPartsEnabled] =
    React.useState<boolean>(false);
  const [userDefaultBudgetEnabled, setUserDefaultBudgetEnabled] =
    React.useState<boolean>(false);
  const [
    showDefaultUserGroupOnUserProfileEnabled,
    setShowDefaultUserGroupOnUserProfileEnabled,
  ] = React.useState<boolean>(false);
  const [defaultBudgetEnabled, setDefaultBudgetEnabled] =
    React.useState<boolean>(false);
  const [userGroupReservationRestricted, setUserGroupReservationRestricted] =
    React.useState<boolean>(false);
  const [
    reservationUserGroupGroupByBudgetUserGroup,
    setReservationUserGroupGroupByBudgetUserGroup,
  ] = React.useState<boolean>(false);
  const [onlyOwnUserGroupsAllowed, setOnlyOwnUserGroupsAllowed] =
    React.useState<boolean>(false);
  const [allowAddRemoveOnEditReservation, setAllowAddRemoveOnEditReservation] =
    React.useState<boolean>(false);
  const [
    allowToRemoveFromMultipleReservation,
    setAllowToRemoveFromMultipleReservation,
  ] = React.useState<boolean>(false);
  const [hideReservationCollaborators, setHideReservationCollaborators] =
    React.useState(false);
  const [isConfidentialBudgetsEnabled, setIsConfidentialBudgetsEnabled] =
    React.useState(false);
  const [allowWorkflowBookingEnabled, setAllowWorkflowBookingEnabled] =
    React.useState(false);
  const [modifyReservationNameForUsers, setModifyReservationNameForUsers] =
    React.useState(false);
  const [loanDeskEnabled, setLoanDeskEnabled] = React.useState(false);
  const [enableCoreAffilationManagment, setEnableCoreAffilationManagment] =
    React.useState(false);
  const [loadCompleted, setLoadCompleted] = React.useState<boolean>(false);
  const [maximumEmailRecipients, setMaximumEmailRecipients] =
    React.useState<number>(50);
  const [projectsAreOptional, setProjectsAreOptional] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    setIsBudgetsWithoutUserGroup(() => {
      return !IsBudgetFieldEnabled(appSettings, BudgetFields.UserGroup);
    });
    setIsConfidentialBudgetsEnabled(() => {
      return IsBudgetFieldEnabled(appSettings, BudgetFields.Confidential);
    });
    setUserIsMainAdmin(() => {
      return isInRole(User, Roles.Administrators);
    });
    setAllowAddRemoveOnEditReservation(() => {
      return IsModuleEnabled(
        appSettings,
        KnownModules.MultipleInstruments_AddRemoveOnEditReservation,
      );
    });
    setAllowToRemoveFromMultipleReservation(() => {
      return (
        settings.GetBoolean(
          AllowedSettings[AllowedSettings.AllowToRemoveFromMultipleReservation],
        ) === true
      );
    });
    setTrainingSessionEnabled(() => {
      return (
        settings.GetBoolean(
          AllowedSettings[AllowedSettings.HideTrainingSessionsSettings],
        ) === false
      );
    });
    setAllowWorkflowBookingEnabled(() => {
      return (
        settings.GetBoolean(
          AllowedSettings[AllowedSettings.AllowWorkflowBooking],
        ) === true
      );
    });
    setModifyReservationNameForUsers(() => {
      return (
        settings.GetBoolean(
          AllowedSettings[AllowedSettings.ModifyReservationNameForUsers],
        ) === true
      );
    });
    setInstituteProjectsEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.InstituteProjects);
    });
    setProjectsAreOptional(
      settings.GetBooleanByKey(AllowedSettings.ProjectsAreOptional),
    );
    setMultipleInstrumentsEnabled(() => {
      const multipleInstrumentsSetting: string | null =
        settings.GetNullableByKey(
          AllowedSettings.MultipleInstrumentReservations,
        );
      return (
        multipleInstrumentsSetting !== null &&
        MultipleInstrumentReservations[multipleInstrumentsSetting] !==
          MultipleInstrumentReservations.NotAllowed
      );
    });
    setReservationTerminationEnabled(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.TerminateReservation],
      );
    });
    setAccServicesEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.AccServices);
    });
    setAllowOngoingReservationModificationEnabled(() => {
      return IsModuleEnabled(
        appSettings,
        KnownModules.AllowOngoingReservationModification,
      );
    });
    setDoNotAllowReservationUserChangeByStaff(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.DoNotAllowReservationUserChangeByStaff],
      );
    });
    setUserServiceGroupCommentsEnabled(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.UserServiceGroupComments],
      );
    });
    setUserLabTechCommentsEnabled(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.UserLabTechComments],
      );
    });
    setShowReferenceFieldInReservationDetails(() => {
      return !settings.GetBoolean(
        AllowedSettings[AllowedSettings.HideReferenceFieldInReservationDetails],
      );
    });
    setAllowUsersToViewTheirCharges(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.AllowUsersToViewCharges],
      );
    });
    setAssistedReservationsEnabled(() => {
      return !settings.GetBoolean(
        AllowedSettings[AllowedSettings.HideAssistedReservationsSettings],
      );
    });
    setIsBudgetLimitedByUsers(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.BudgetLimited_ByUsers],
      );
    });
    setBudgetModuleEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.Budgets);
    });
    setPriceSheetPricing(() => {
      return IsModuleEnabled(appSettings, KnownModules.PriceSheetPricing);
    });
    setBillingEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.Billing);
    });
    setPurchaseOrderLengthSetting(() => {
      const stringValue = settings.TryGet(
        AllowedSettings[AllowedSettings.PurchaseOrderLengthSetting],
      );
      if (stringValue === null) {
        return undefined;
      }
      const intValue = parseInt(stringValue);
      if (isNaN(intValue)) {
        return undefined;
      }
      return intValue;
    });

    setDraftReservationsEnabled(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.DraftReservationsEnabled],
      );
    });
    setMandatoryOfflineServicesOnTrainingSignUps(() => {
      return settings.GetBoolean(
        AllowedSettings[
          AllowedSettings.MandatoryOfflineServicesOnTrainingSignUps
        ],
      );
    });
    setOfflineServiceWithReservation(() => {
      return IsModuleEnabled(
        appSettings,
        KnownModules.OfflineServiceWithReservation,
      );
    });
    setReservationRestricted(() => {
      const resRestrictedSetting: string | null = settings.GetNullableByKey(
        AllowedSettings.UserReservationsRestricted,
      );
      return (
        resRestrictedSetting !== null &&
        UserReservationsRestricted[resRestrictedSetting] ===
          UserReservationsRestricted.UserOwnRestricted
      );
    });
    setUserGroupReservationRestricted(() => {
      const resUserGroupRestrictedSetting: string | null =
        settings.GetNullableByKey(AllowedSettings.UserReservationsRestricted);
      return (
        resUserGroupRestrictedSetting !== null &&
        UserReservationsRestricted[resUserGroupRestrictedSetting] ===
          UserReservationsRestricted.UserGroupRestricted
      );
    });
    setUserAndGroupPermissionsEnabled(() => {
      return (
        IsModuleEnabled(appSettings, KnownModules.UserPermission) ||
        IsModuleEnabled(appSettings, KnownModules.GroupPermissions)
      );
    });

    setServiceGroupAdminsCanManageAdUsers(
      () =>
        settings.GetString(AllowedSettings.ServiceGroupAdminsCanManageUsers) ===
        ServiceGroupAdminsManageUsersEnum[
          ServiceGroupAdminsManageUsersEnum
            .ServiceGroupAdminsCanManageUsers_CanAssignOnlySSOusers
        ],
    );
    setServiceGroupAdminsCanManageAllUsers(
      () =>
        settings.GetString(AllowedSettings.ServiceGroupAdminsCanManageUsers) ===
        ServiceGroupAdminsManageUsersEnum[
          ServiceGroupAdminsManageUsersEnum
            .ServiceGroupAdminsCanManageUsers_CanAssignAnyUser
        ],
    );
    setEnableCoreAffilationManagment(() =>
      settings.GetBooleanByKey(
        AllowedSettings.EnableServiceGroupAffilationManagment,
      ),
    );
    setReservationUserGroupGroupByBudgetUserGroup(() =>
      settings.GetBoolean(
        AllowedSettings[
          AllowedSettings.ReservationUserGroupGroupByBudgetUserGroup
        ],
      ),
    );
    setCrossInstrumentTrainingEnabled(() =>
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.CrossInstrumentTraining],
      ),
    );

    setOnlyOwnUserGroupsAllowed(() => {
      return !(intersection(userRoles, adminRoles).length > 0);
    });
    setUserDefaultBudgetEnabled(() =>
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.ShowDefaultBudgetOnUserProfile],
      ),
    );
    setShowDefaultUserGroupOnUserProfileEnabled(() =>
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.ShowDefaultUserGroupOnUserProfile],
      ),
    );
    setDisplayEditUserDetails(() =>
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.DisplayEditUserDetails],
      ),
    );
    setOpenUserProfileOnSidePanel(() =>
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.OpenUserProfileOnSidePanel],
      ),
    );
    setShowCalcPriceOnOfflineService(
      () =>
        settings.GetBoolean(
          AllowedSettings[AllowedSettings.HideCalcPriceOnOfflineService],
        ) !== true,
    );
    setShowCalculatePrice(
      () =>
        IsModuleEnabled(appSettings, KnownModules.Billing) ||
        (IsModuleEnabled(appSettings, KnownModules.Invoices) &&
          IsModuleEnabled(appSettings, KnownModules.PriceSheetPricing)),
    );
    setDefaultBudgetEnabled(() => {
      return IsBudgetFieldEnabled(appSettings, BudgetFields.Default);
    });
    setFundingTypeBudgetEnabled(() => {
      return IsBudgetFieldEnabled(appSettings, BudgetFields.FundingType);
    });
    setPurchaseOrderBudgetEnabled(() => {
      return IsBudgetFieldEnabled(
        appSettings,
        BudgetFields.PurchaseOrderOption,
      );
    });

    setTutoringModulesEnabled(() => {
      return (
        IsModuleEnabled(appSettings, KnownModules.Tutoring) ||
        IsModuleEnabled(appSettings, KnownModules.TutoringHours)
      );
    });
    setTutoringEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.Tutoring);
    });
    setAssemblyPartsEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.EquipmentAssemblies);
    });
    setHideNonDefaultBudgets(() => {
      return (
        IsModuleEnabled(appSettings, KnownModules.HideNonDefaultBudgets) &&
        !(
          intersection(userRoles, privateBudgetsRoles).length > 0 ||
          (isInRole(User, Roles.GroupAdministrators) &&
            settings.GetBoolean(
              AllowedSettings[AllowedSettings.SGACreateProjects],
            ))
        )
      );
    });
    setBudgetExperimentModuleEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.BudgetExperiments);
    });

    setAllowPartialProjectCharges(() => {
      return (
        IsModuleEnabled(appSettings, KnownModules.Invoices) &&
        IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents) &&
        settings.GetBoolean(
          AllowedSettings[AllowedSettings.AllowPartialMultipleProjectCharges],
        ) &&
        budgetModuleEnabled
      );
    });
    setAllowPartialSingleProjectCharges(() => {
      return (
        IsModuleEnabled(appSettings, KnownModules.Invoices) &&
        IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents) &&
        settings.GetBoolean(
          AllowedSettings[AllowedSettings.AllowPartialSingleProjectCharges],
        ) &&
        budgetModuleEnabled
      );
    });
    setReservationFormEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.ReservationsCustomForm);
    });
    setResMultipleFormsEnabled(() => {
      return (
        reservationFormEnabled &&
        IsModuleEnabled(appSettings, KnownModules.MultipleCustomForms)
      );
    });
    setHideReservationCollaborators(
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.HideReservationCollaborators],
      ),
    );
    setRequestReservationEnabled(
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.ServiceRequestReservations],
      ),
    );
    setLoanDeskEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.LoanDesk);
    });
    setMultipleAdGroupsEnabled(() => {
      return IsModuleEnabled(appSettings, KnownModules.MultipleAdGroups);
    });
    setMaximumEmailRecipients(() => {
      const stringValue = settings.TryGet(
        AllowedSettings[AllowedSettings.MaximumEmailRecipients],
      );
      if (stringValue === null) {
        return 50;
      }
      const intValue = parseInt(stringValue);
      if (isNaN(intValue)) {
        return 50;
      }
      return intValue;
    });
    setHideRepetitiveReservations(() => {
      return settings.GetBoolean(
        AllowedSettings[AllowedSettings.HideRepetitiveReservations],
      );
    });

    console.debug('Global Settings Called');
    setLoadCompleted(() => true);
  }, [
    User,
    adminRoles,
    appSettings,
    budgetModuleEnabled,
    privateBudgetsRoles,
    reservationFormEnabled,
    reservationRestricted,
    settings,
    userGroupReservationRestricted,
    userRoles,
    userSettings,
  ]);

  const r: GlobalSettingsType = {
    loadCompleted,
    reservationRestricted,
    reservationUserGroupGroupByBudgetUserGroup,
    userGroupReservationRestricted,
    onlyOwnUserGroupsAllowed,
    projectsAreOptional,
    userDefaultBudgetEnabled,
    defaultBudgetEnabled,
    hideNonDefaultBudgets,
    isBudgetsWithoutUserGroup,
    budgetModuleEnabled,
    priceSheetPricing,
    billingModuleEnabled: billingEnabled,
    purchaseOrderLengthSetting,
    budgetLimitedByUsers,
    budgetExperimentModuleEnabled: budgetExperimentModuleEnabled,
    fundingTypeBudgetEnabled,
    purchaseOrderBudgetEnabled,
    allowPartialProjectCharges,
    allowPartialSingleProjectCharges,
    trainingSessionEnabled,
    assistedReservationsEnabled,
    resCustomFormEnabled: reservationFormEnabled,
    resMultipleFormsEnabled,
    multipleInstrumentsEnabled,
    reservationTerminationEnabled,
    crossInstrumentTrainingEnabled,
    userAndGroupPermissionsEnabled: userAndGroupPermissionsEnabled,
    tutoringModulesEnabled,
    tutoringEnabled,
    offlineServiceWithReservation,
    userIsMainAdmin,
    instituteProjectsEnabled,
    showReferenceFieldInReservationDetails,
    accServicesEnabled,
    showCalcPriceOnOfflineService,
    allowUsersToViewTheirCharges,
    allowOngoingReservationModificationEnabled,
    showCalculatePrice,
    allowAddRemoveOnEditReservation,
    allowToRemoveFromMultipleReservation,
    draftReservationsEnabled,
    hideReservationCollaborators,
    MandatoryOfflineServicesOnTrainingSignUps,
    DoNotAllowReservationUserChangeByStaff:
      doNotAllowReservationUserChangeByStaff,
    AssemblyPartsEnabled: assemblyPartsEnabled,
    confidentialBudgetsEnabled: isConfidentialBudgetsEnabled,
    AllowWorkflowBooking: allowWorkflowBookingEnabled,
    ModifyReservationNameForUsers: modifyReservationNameForUsers,
    ShowDefaultUserGroupOnUserProfileEnabled:
      showDefaultUserGroupOnUserProfileEnabled,
    ServiceRequestReservationEnabled: requestReservationEnabled,
    LoanDeskEnabled: loanDeskEnabled,
    ServiceGroupAdminsCanManageAdUsers: serviceGroupAdminsCanManageAdUsers,
    ServiceGroupAdminsCanManageAllUsers: serviceGroupAdminsCanManageAllUsers,
    EnableCoreAffilationManagment: enableCoreAffilationManagment,
    UserServiceGroupCommentsEnabled: userServiceGroupCommentsEnabled,
    UserLabTechCommentsEnabled: userLabTechCommentsEnabled,
    DisplayEditUserDetails: displayEditUserDetails,
    MultipleAdGroupsEnabled: multipleAdGroupsEnabled,
    OpenUserProfileOnSidePanel: openUserProfileOnSidePanel,
    maximumEmailRecipients,
    hideRepetitiveReservations,
  };
  return r;
}

export default useGlobalSettingsHook;
