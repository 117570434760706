import { IEquipmentAccServicesDto } from 'api/odata/generated/entities/IEquipmentAccServicesDto';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { isNullOrUndefined } from 'util';
import { dateUtils } from 'utils/date-utils';
import {
  ReservationDetailsState,
  ReservationSettingsState,
  ReservationEquipment,
  CustomFormValueModel,
  ReservationUpdateModel,
  OfflineservicesModel,
} from './types';

export function ConvertModelToUpdateEntity(
  original: ReservationDetailsState,
  current: ReservationDetailsState,
  settings: ReservationSettingsState | undefined,
  offlineServices: IOtherServices[],
  user: AuthenticatedUser | undefined,
  terminate?: boolean,
) {
  let entity = {
    Id: original.Id,
    Services: current.EquipmentsData.map(item => {
      return {
        EquipmentId: item.Id,
        EquipmentName: item.Name,
        AccServiceIds:
          current.AccServices.length > 0
            ? current.AccServices.filter(
                a => (a as IEquipmentAccServicesDto).EquipmentId === item.Id,
              ).map(a => a.Id)
            : [],
        AssemblyId: null,
        ReservationId: original.Id,
      } as ReservationEquipment;
    }),
    StartTime: dateUtils.formatISO(
      dateUtils.dateOrStringToDate(current.StartTime),
    ),
    EndTime: dateUtils.formatISO(dateUtils.dateOrStringToDate(current.EndTime)),
    Remarks: current.Remarks || null,
    SampleRunId: current.SampleRunId,
    SampleIds: current.SampleIds,
    BudgetId: current.Budget?.Id,
    BudgetExperimentId: current.BudgetExperiment?.Id,
    Collaborators: current.CollaboratedUsers.map(f => f.Id),
    ExternalCustomerId: current.ExternalCustomer?.Id,
    FundingType: isNullOrUndefined(current.FundingType)
      ? null
      : current.FundingType?.Id,
    InstituteProjectId: current.InstituteProject?.Id,
    Name: current.DisplayName,
    PartialAttendance: current.PartialAttendance,
    PartialAttendanceStart: current.PartialAttendanceStart || null,
    PartialAttendanceEnd: current.PartialAttendanceEnd || null,
    Tutoring: current.Tutoring,
    FullDayReservation: current.FullDayReservation,
    Username: current.BookedBy?.Id,
    StaffUser: current.StaffUser?.Id,
    UserGroupId: current.ADGroup?.Id,
    TrainingSignUp: current.TrainingSignUp,
    TrainingSessionId: current.TrainingSession?.Id,
    PurchaseOrder: current.PurchaseOrder,
    Reference: current.Reference,
    Status: current.Status?.Id,
    TransferFromId: current.TransferFromId,
    UserRequestedByApproval: settings?.UserRequestedByApproval,
    UserRequestedBackground: false,
    TransferredTo: current.TransferredTo?.Id,
    WorkflowBooking: current.WorkflowBooking || null,
    RecurringUpdateOption: settings?.RecurringUpdateOption || 1,
    AllStartTimes: [],
    BudgetIds: [],
    OfflineServices: offlineServices.map(f => {
      return {
        OfflineServiceTypeId: f.ServiceTypeID,
        ServiceTypeName: f.ServiceType,
        UnitsName: f.Units,
        Factor: f.DiscountFactor,
        Quantity: f.Quantity,
        Remarks: f.Remarks,
        InventoryBatchId: f.InventoryBatchId,
        BudgetId: f.BudgetID,
        BudgetExperimentId: f.BudgetExperimentId,
        UserGroup: f.UserGroup,
      } as OfflineservicesModel;
    }),
    TransferInProgress: settings?.TransferInProgress,
    WaitingTransferApproval: current.WaitingTransferApproval,
    InsertedAt: settings?.Entered,
    InsertedBy: settings?.RecordDate,
    UpdatedAt: new Date(),
    UpdatedBy: user?.Id,
    CancelledAt: settings?.Cancelled,
    CustomFormValues: current.FormValues.map(f => {
      return {
        FieldId: f.FormFieldId,
        Value: f.Value,
        DisplayValue: f.DisplayValue,
      } as CustomFormValueModel;
    }),
    Parts: [],
    TutorName: settings?.TutorName,
    Terminated: terminate ?? false,
    TotalCost: settings?.TotalCost ?? 0,
    SavedForLater:
      settings?.SaveForLater || current.Status?.Id === ReservationStatus.Draft,
  } as ReservationUpdateModel;
  return entity;
}
