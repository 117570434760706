import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import * as React from 'react';
import { openExportLink, toRootedURL } from 'utils/url-utils';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import cliTruncate from 'cli-truncate';
import { Body } from 'app/components/Typography';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import prettyBytes from 'pretty-bytes';
import { useSelector } from 'react-redux';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { FilePreview } from './FilePreview/Loadable';
import clsx from 'clsx';
import { DetectIsMobile } from 'utils/mobileDetect';
import styled from 'styled-components';

export interface FileViewProps {
  file: IFormFileValue;
  onRemove: (value: any) => void;
  withShrinkLabel?: boolean;
}

export function FileView(props: FileViewProps) {
  const { file, onRemove, withShrinkLabel } = props;
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const fileName =
    file.DisplayValue !== null && file.DisplayValue !== undefined
      ? file.DisplayValue
      : '';
  const fileSize =
    file.Size == null
      ? null
      : prettyBytes(file.Size, { maximumFractionDigits: 2 });

  const fileUrl =
    file.PostedFile === null || file.PostedFile === undefined
      ? toRootedURL(`/file.ashx?id=${file.Value}&ap=true`)
      : file.PostedFile.webkitRelativePath;

  const downloadClick = async () => {
    if (file.PostedFile === null || file.PostedFile === undefined) {
      await openExportLink(fileUrl);
    }
  };
  // const fileDownloadClick = async () => {
  //   fileDownload(res.data, filename)
  // };

  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  return (
    <React.Fragment>
      <PreviwWrapper
        className={clsx('previw-root', {
          shrinkLabel: withShrinkLabel,
        })}
      >
        <div className={'preview-icon-text'}>
          {file.Value !== null ? (
            <Tooltip title={t(translations.Download)}>
              <div className={'preview-icon'} onClick={() => downloadClick()}>
                <FilePreview
                  fileName={fileName}
                  fileUrl={fileUrl}
                  fileDisplayValue={file.DisplayValue}
                />
              </div>
            </Tooltip>
          ) : (
            <div className={'preview-icon'}>
              <Icon icon="link" />
            </div>
          )}
          <div className={'preview-text'}>
            <Body bold={true} size="small">
              {cliTruncate(fileName, sidePanelExpanded && !isMobile ? 40 : 25, {
                position: 'middle',
              })}
            </Body>

            {fileSize && (
              <Body size="small" color="secondary">
                {fileSize}
              </Body>
            )}
          </div>
        </div>
        <div className={'preview-button'}>
          <IconButton size="xs" onClick={() => onRemove(fileName)}>
            <Icon icon="times" className={'preview-button-icon'} />
          </IconButton>
        </div>
      </PreviwWrapper>
    </React.Fragment>
  );
}
const PreviwWrapper = styled.div`
  &.previw-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    height: 48px;
    padding: 0px 10px 0px 0px;

    // &.shrinkLabel {
    //   align-items: flex-end;
    // }

    & .preview-icon-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      gap: 12px;
      width: 100%;

      & .preview-icon {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px;
        gap: 10px;
        cursor: pointer;
        background-color: var(--base-white-c0);
        border-radius: 8px;
        color: var(--grayscale-gray-components-c7);

        & svg {
          width: 24px;
          height: 24px;
        }

        & img {
          height: 24px;
        }

        .shrinkLabel & {
          padding: 0;
        }
      }

      & .preview-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        width: 100%;
      }
    }

    & .preview-button {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2;
      gap: 10px;
      & .preview-button-icon {
        color: var(--grayscale-gray-components-c7);
        width: 16px;
        height: 16px;
        fontsize: 16px;
      }
    }
  }
`;
