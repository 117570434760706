import { Identifiable } from 'types/common';

export function isNullOrUndefined<T>(
  value: T | null | undefined,
): value is null | undefined {
  return value === null || value === undefined;
}

export function isEmptyOrWhitespace(s: string | null | undefined): boolean {
  return s === null || s === undefined || s === '' || s.trim() === '';
}
export function toUndefinedIfEmptyOrWhitespace(s: string | null | undefined) {
  return isEmptyOrWhitespace(s) ? undefined : s;
}
export function toLowerCaseSafely(s: string | null | undefined): string | null {
  return s === null || s === undefined ? null : s.toLowerCase();
}

export const valOrNull = <T>(val: T | undefined | null) => {
  return val ?? null;
};

//for use in filter to mark all as not undefined
export const notUndefined = <T>(val: T | undefined): val is T => {
  return val !== undefined;
};

export type PartialExceptId<T extends Identifiable<unknown>> = Pick<T, 'Id'> &
  Partial<Omit<T, 'Id'>>;

export function toNumberOrNull(value: any): number | null {
  if (value === null || value === undefined) {
    return null;
  }
  if (typeof value === 'string') {
    if (isNaN(parseInt(value))) {
      return null;
    } else {
      return parseInt(value);
    }
  } else if (typeof value === 'number') {
    return value;
  } else {
    return null;
  }
}

export function hasStringProperty<P extends string>(
  entity: any,
  propName: P,
): entity is Record<P, string> {
  return (
    propName in entity &&
    entity[propName] !== undefined &&
    entity[propName] !== null &&
    typeof entity[propName] === 'string'
  );
}
export function toIdentifiable<T extends unknown>(value: T): Identifiable<T> {
  return { Id: value };
}
export function toIdentifiableArray<T>(values: T[]): Identifiable<T>[] {
  return values.map(value => toIdentifiable(value));
}
