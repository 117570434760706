/**
 *
 * BudgetsRestrictedRoute
 *
 */
import { UserAccessToBudgetsUnion } from 'api/odata/generated/enums/UserAccessToBudgets';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import {
  selectAuthenticatedUser,
  selectGlobalSetting,
  selectSiteMapProvider,
} from 'app/slice/selectors';

import React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, Redirect, Route } from 'react-router-dom';
import { AllowedSettings } from 'utils/globalSettings';

export function BudgetsRestrictedRoute(
  routeProps: RouteProps & { globalSettings?: GlobalSettingsType },
): React.ReactElement {
  const User = useSelector(selectAuthenticatedUser);
  const siteMapProvider = useSelector(selectSiteMapProvider);
  const UserAccessToBudgetsUnionUserAccessToBudgetsUnion = useSelector(
    state =>
      selectGlobalSetting(
        state,
        AllowedSettings.UserAccessToBudgets,
      ) as UserAccessToBudgetsUnion,
  );

  const { component: Component, ...rest } = routeProps;

  const render = () => {
    let allowedNode: boolean = siteMapProvider.isBudgetsAccessible(
      User,
      UserAccessToBudgetsUnionUserAccessToBudgetsUnion,
    );
    if (allowedNode === false) {
      return <Redirect to={'/notavailable'} />;
    } else if (allowedNode === true) {
      return <Route component={routeProps.component} {...routeProps} />;
    } else {
      return undefined;
    }
  };
  return <Route {...rest} render={render} />;
}
