import * as React from 'react';
import styled from 'styled-components';
import BasicTypography from 'app/components/Typography/BasicTypography';

export interface FormSectionTitleProps {
  title: React.ReactNode;
  link?: React.ReactNode;
}
const SectionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
`;
export function FormSectionTitle(props: FormSectionTitleProps) {
  return (
    <SectionTitle>
      <BasicTypography variant="boldM" bold={true} component={'h1'}>
        {props.title}
      </BasicTypography>
      {props.link !== undefined && props.link}
    </SectionTitle>
  );
}
