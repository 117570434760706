import {
  EquipmentsChangeStateParameters,
  EquipmentSlotParameters,
  ReservationInsertModel,
  ReservationModel,
  ReservationMultiEvent,
  ReservationQueryStringParameters,
  TransferToReservation,
} from 'app/pages/ReservationDetails/Details/slice/types';
import { IStatusReservations } from 'app/pages/ReservationHistoryPage/IStatusReservations';
import { ITrainingReservations } from 'app/pages/ReservationHistoryPage/ITrainingReservations';
import { GroupedEquipments } from 'types/Equipments/types';
import { httpClient } from './HttpClient';

import { ISharedReservations } from 'app/pages/ReservationHistoryPage/ISharedReservations';
import { EndUsageData } from 'app/components/EndUsageConfirm';
import { IResponseType } from 'types/ResponseType';
import { CancelTokenSource } from 'axios';

export const ReservationsApi = {
  initReservation: (payload: ReservationQueryStringParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Reservations/InitReservation`;
    return httpClient.post(url, data, {
      timeout: undefined,
    });
  },
  insertReservation: (
    payload: ReservationInsertModel,
    cancelTokenSource?: CancelTokenSource,
  ) => {
    const url = `/api/odata/v4/Reservations`;
    return httpClient.post(url, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  },
  updateReservation: (payload: ReservationModel) => {
    const url = `/api/odata/v4/Reservations(${payload.Id})`;
    return httpClient.put(url, payload);
  },
  getReservation: (id: number, params: object) => {
    const url = `/api/odata/v4/reservations/${id}`;
    return httpClient.get<ReservationModel>(url, params);
  },
  getReservationUserTrainingEquipments: (id: number, params?: object) => {
    const url = `/api/odata/v4/reservations/${id}/UserTrainingEquipments`;
    return httpClient.get<ReservationModel>(url, params);
  },
  hasAnyFirstReservations: (
    ids: string,
    user: string,
    reservationId: number,
  ) => {
    const url = `/api/odata/v4/Reservations/HasAnyFirstReservation`;
    return httpClient.post(url, { ids: ids, user: user, id: reservationId });
  },
  getEquipmentsChangeState: (payload: EquipmentsChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = '/api/odata/v4/Reservations/GetEquipmentsChangeState';
    return httpClient.post(url, data);
  },
  getReservationValidationState: (payload: EquipmentsChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Reservations/GetReservationValidationState`;
    return httpClient.post(url, data);
  },
  getReservationSlots: (payload: EquipmentSlotParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Reservations/GetReservationSlots`;
    return httpClient.post(url, data);
  },
  validateDuration: (payload: EquipmentsChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Reservations/ValidateDuration`;
    return httpClient.post(url, data);
  },
  validatePrerequisite: (payload: EquipmentsChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Reservations/ValidatePrerequisite`;
    return httpClient.post(url, data);
  },
  validatePermissions: (payload: EquipmentsChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Reservations/ValidatePermissions`;
    return httpClient.post(url, data);
  },
  changeStatus: (payload: EquipmentsChangeStateParameters) => {
    const data = {
      params: payload,
    };
    const url = `/api/odata/v4/Reservations/ChangeStatus`;
    return httpClient.post(url, data);
  },
  getEquipmentsData: (payload: {
    q?: string;
    value?: string;
    ids?: string;
    sgid?: string;
    avaEqu: boolean;
    resStart?: string;
    resEnd?: string;
    budgetId?: number;
    budgetExperimentId?: number;
    mandatoryIds?: string;
    init?: boolean;
  }) => {
    const url = '/api/scheduler/reservationEquipments';
    return httpClient.get<GroupedEquipments[]>(url, payload);
  },
  createTraining: (payload: ITrainingReservations[]) => {
    const data = {
      reservations: payload,
    };
    const url = '/api/odata/v4/ReservationHistory/CreateTraining';
    return httpClient.post(url, data);
  },
  updateStatus: (payload: IStatusReservations[]) => {
    const data = {
      reservations: payload,
    };
    const url = '/api/odata/v4/ReservationHistory/UpdateStatus';
    return httpClient.post(url, data);
  },
  HasFutureReservationsHttp: (equipmentNames: ISharedReservations[]) => {
    const data = {
      reservations: equipmentNames,
    };
    // '/api/odata/v4/ReservationHistory/HasEquipmentFutureReservations'
    // /api/ICalController/HasFutureReservations
    const url = '/api/odata/v4/ReservationHistory/HasFutureReservations';
    return httpClient.get<boolean>(url, data);
  },
  transferReservation: (payload: TransferToReservation) => {
    const data = {
      params: {
        User: payload.User.Id,
        ReservationId: payload.ReservtionId,
      },
    };
    const url = `/api/odata/v4/Reservations/TransferReservation`;
    return httpClient.post(url, data);
  },
  sendTransferNotification: (payload: TransferToReservation) => {
    const data = {
      params: {
        User: payload.User.Id,
        ReservationId: payload.ReservtionId,
      },
    };
    const url = `/api/odata/v4/Reservations/SendTranserNotification`;
    return httpClient.post(url, data);
  },
  sendCancelTransferNotification: (payload: TransferToReservation) => {
    const data = {
      params: {
        User: payload.User.Id,
        ReservationId: payload.ReservtionId,
      },
    };
    const url = `/api/odata/v4/Reservations/SendCancelTranserNotification`;
    return httpClient.post(url, data);
  },
  cancelTransferReservation: (payload: number) => {
    const url = `/api/odata/v4/Reservations/CancelTranser`;
    return httpClient.post(url, { id: payload });
  },
  sendTerminateNotification: (payload: number[]) => {
    const data = {
      ReservationIds: payload,
    };
    const url = `/api/odata/v4/Reservations/SendTerminateNotification`;
    return httpClient.post(url, data);
  },
  hasUsage: (payload: { resId: number; eqId: number }) => {
    const url = `/api/odata/v4/Reservations/HasUsage`;
    return httpClient.post(url, { id: payload.resId, eqid: payload.eqId });
  },
  endUsage: (id: number, data: EndUsageData): Promise<IResponseType> => {
    const url = `/api/odata/v4/Reservations/EndReservationUsage`;
    const params = {
      id: id,
      remarks: data.remarks ?? '',
      sids:
        data.services.map(s => s.service?.Id + ':' + s.quantity).join(',') ??
        '',
    };
    return httpClient.post(url, params);
  },
  userCanBookEquipment: (payload: number) => {
    const data = {
      equipmentid: payload,
    };
    const url = `/api/odata/v4/Reservations/UserCanBookEquipment`;
    return httpClient.post(url, data);
  },
  updateMultiReservations: (payload: ReservationMultiEvent) => {
    const url = `/api/odata/v4/Reservations/UpdateMultiReservations`;
    const data = {
      params: payload,
    };
    return httpClient.post(url, data);
  },
  // calculateCredit: (payload: ReservationCreditModel) => {
  //   const url = `/api/odata/v4/Reservations/CalculateReservationCredit`;
  //   // const data = {
  //   //   Id: payload.Id,
  //   //   Services: payload.Services,
  //   //   //AccServices: payload.AccServices,
  //   //   Username: payload.Username,
  //   //   UserGroupId: payload.UserGroupId,
  //   //   BudgetId: payload.BudgetId,
  //   //   Tutoring: payload.Tutoring,
  //   //   TrainingSignUp: payload.TrainingSignUp,
  //   //   BudgetPercent: payload.BudgetPercent,
  //   // };
  //   return httpClient.post(url, payload);
  // },
};
