import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { FundingTypes } from 'app/pages/ReservationDetails/Details/slice/types';
import { Roles } from 'api/odata/generated/enums/Roles';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { AsyncDataState } from 'types/common';
import { IConnectedFiltersDto } from 'types/IConnectedFiltersDto';
import { FieldHandlerProps } from '../../FormRender/FormRenderer';
import { ReportedFrom } from 'app/pages/OtherServiceDetails/Details/slice/types';

/* --- STATE --- */
export interface ConnectedFiltersState
  extends AsyncDataState<IConnectedFiltersDto> {
  connectedValueHandler?: FieldHandlerProps<IConnectedFiltersDto>;
  connectedSettings: ConnectedFiltersSettings;
}
export interface ConnectedFiltersSettings {
  startTimeVisible?: boolean;
  endTimeVisible?: boolean;
  budgetVisible?: boolean;
  userVisible?: boolean;
  userGroupVisible?: boolean;
  fundingTypeVisible?: boolean;
  budgetExpirementVisible?: boolean;
  instProjVisible?: boolean;
  extCustomerVisible?: boolean;
  purchaseOrderVisible?: boolean;
  referenceVisible?: boolean;
  onlyOwnUserGroupsAllowed?: boolean;
  ReservationUserGroupGroupByBudgetUserGroup?: boolean;
  budgetLimitedByUsers?: boolean;
  isBudgetsWithoutUserGroup?: boolean;
  budgetInputDisabled?: boolean;
  isServiceAdmin?: boolean;
  isUserGroupCoordinator?: boolean;
  chargesViewAllowed?: boolean;
  hasConflictProjects?: boolean;
}
export function mapSettings(
  services: IServiceTypeFilterDto[],
  globalSettings: GlobalSettingsType,
  settings: ConnectedFiltersSettings,
  isEditMode: boolean,
  user: AuthenticatedUser | undefined,
  data?: IConnectedFiltersDto,
  reportFrom?: ReportedFrom,
): ConnectedFiltersSettings {
  let budgetEnabled = getOfflineBudgetVisible(globalSettings, services);
  let isForMilestone = reportFrom === 'milestone';
  let resUGEnabled =
    services.length > 0
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
        budgetEnabled
      : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  let isServiceAdmin = user?.IsAllGroupOrLabTechAdmin(
    services.map(f => f.ServiceGroupId ?? 0),
    services,
  );
  let isRoleAdmin =
    user?.Roles.includes(Roles.Administrators) ||
    user?.Roles.includes(Roles.GroupAdministrators) ||
    user?.Roles.includes(Roles.LabTech);
  let isUserGroupCoordinator =
    user &&
    user.Roles.includes(Roles.UserGroupCoord) &&
    (isEditMode ? !!data && data.UserGroup?.Id === user.ActiveUserGroup : true);
  let btExpEnabled =
    globalSettings.budgetExperimentModuleEnabled && budgetEnabled;
  let chargesViewAllowed =
    (user && user.Roles.includes(Roles.FinAdmin)) ||
    isServiceAdmin ||
    (user &&
      user.Roles.includes(Roles.InvoicesReader) &&
      user.ActiveUserGroup?.Id === data?.UserGroup?.Id);
  const result = Object.assign({}, settings, {
    hideProject:
      services.length > 0 ? services.every(f => f.HideProject) : undefined,
    isUserGroupCoordinator: isUserGroupCoordinator,
    hasConflictProjects:
      services.some(f => f.HideProject === true) &&
      services.some(f => f.HideProject === false),
    budgetVisible: budgetEnabled && !isForMilestone,
    fundingTypeVisible:
      globalSettings.fundingTypeBudgetEnabled &&
      budgetEnabled &&
      // funding type is not needed unless the PurchaseOrder is also enabled
      globalSettings.purchaseOrderBudgetEnabled &&
      !isForMilestone,
    ReservationUserGroupGroupByBudgetUserGroup: resUGEnabled,
    userGroupVisible:
      (isServiceAdmin || isRoleAdmin) &&
      !isEditMode &&
      !isUserGroupCoordinator &&
      !resUGEnabled &&
      !isForMilestone,
    userVisible:
      !isForMilestone &&
      (isServiceAdmin ||
        isUserGroupCoordinator ||
        (!isEditMode && isRoleAdmin)),
    budgetExpirementVisible: btExpEnabled && !isForMilestone,
    instProjVisible: globalSettings.instituteProjectsEnabled && !isForMilestone,
    // looks like the external customers are being deprecated
    extCustomerVisible: false,
    purchaseOrderVisible:
      globalSettings.purchaseOrderBudgetEnabled &&
      !isForMilestone &&
      ((data?.Budget as IBudgetFilterDto)?.PurchaseOrderOptionId ??
        PurchaseOrderOptions.Hidden) !== PurchaseOrderOptions.Hidden,
    referenceVisible:
      globalSettings.showReferenceFieldInReservationDetails &&
      !isForMilestone &&
      !!data
        ? data.FundingType?.Id === FundingTypes.External
        : false,
    onlyOwnUserGroupsAllowed: globalSettings.onlyOwnUserGroupsAllowed,
    projectsAreOptional: globalSettings.projectsAreOptional,
    budgetLimitedByUsers: globalSettings.budgetLimitedByUsers,
    isBudgetsWithoutUserGroup: globalSettings.isBudgetsWithoutUserGroup,
    isServiceAdmin: isServiceAdmin,
    endTimeVisible: false,
    startTimeVisible: true,
    chargesViewAllowed:
      !isForMilestone &&
      (globalSettings.allowUsersToViewTheirCharges || chargesViewAllowed),
  });
  return result;
}
export function getOfflineBudgetVisible(
  globalSettings: Pick<
    GlobalSettingsType,
    | 'billingModuleEnabled'
    | 'budgetModuleEnabled'
    | 'priceSheetPricing'
    | 'projectsAreOptional'
  >,
  equipments: Array<
    Pick<IServiceTypeFilterDto, 'HideProject' | 'BudgetsTurnedOn'>
  >,
): boolean {
  if (!globalSettings.budgetModuleEnabled) {
    return false;
  }

  if (globalSettings.billingModuleEnabled) {
    // budgets turned off on service group level
    if (equipments.every(f => f.BudgetsTurnedOn === false)) {
      return false;
    }
  }

  // HideProjects is enabled only when ProjectsAreOptional is turned on in the system settings
  if (globalSettings.projectsAreOptional) {
    if (equipments.every(f => f.HideProject)) {
      return false;
    }
  }
  return true;
}
