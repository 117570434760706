import { Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

export const TableSkeletonRoot = styled('div')`
  &.table-skeleton-root {
    flex-grow: 1;
    padding: 24px;
    & .table-skeleton-header {
      width: calc(100% + 16px);
      margin: -8px;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      & .table-skeleton-header-cell {
        padding: 8px;

        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;

        @media (min-width: 960px) {
          flex-grow: 0;
          max-width: 16.666667%;
          flex-basis: 16.666667%;
        }
        margin: 0;
        box-sizing: border-box;
      }
    }
    & .table-skeleton-toolbar {
      margin-top: 8px;
      & .table-skeleton-toolbar-cell {
        width: calc(100% + 16px);
        margin: -8px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        & .table-skeleton-toolbar-item {
          padding: 8px;

          flex-grow: 0;
          max-width: 50%;
          flex-basis: 50%;

          @media (min-width: 960px) {
            flex-grow: 0;
            max-width: 25%;
            flex-basis: 25%;
          }
          margin: 0;
          box-sizing: border-box;
        }
        & .table-skeleton-toolbar-item-2 {
          padding: 8px;

          flex-grow: 0;
          max-width: 33.333333%;
          flex-basis: 33.333333%;

          @media (min-width: 960px) {
            flex-grow: 0;
            max-width: 16.666667%;
            flex-basis: 16.666667%;
          }
          margin: 0;
          box-sizing: border-box;
        }
      }
    }
    & .table-skeleton-divider {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    & .table-skeleton-search-bar {
      width: calc(100% + 16px);
      margin: -8px;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      margin-bottom: 24px;
      & .table-skeleton-search-bar-item {
        padding: 8px;

        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;

        margin: 0;
        box-sizing: border-box;
      }
      & .table-skeleton-search-bar-item-2 {
        padding: 8px;

        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;

        @media (min-width: 960px) {
          flex-grow: 0;
          max-width: 33.333333%;
          flex-basis: 33.333333%;
        }
        margin: 0;
        box-sizing: border-box;
      }
    }
  }
`;
export const TableRowsSkeletonRoot = styled('div')`
  &.table-rows-skeleton-root {
    width: 100%;
    & .table-skeleton-grid {
      width: calc(100% + 8px);
      margin: -4px;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      & .table-skeleton-header-cell {
        color: white;
        height: 48px;
        position: relative;
        box-shadow: 0 3px 5px 2px #d7d7d7;
        border-right: 1px solid #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        background-color: #f5f5f5;

        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;

        margin: 0;
        box-sizing: border-box;
        & .table-skeleton-header-content {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          & .table-skeleton-header-item {
            flex-grow: 1;
            max-width: 100%;
            flex-basis: 0;
            margin: 0;
            box-sizing: border-box;
          }
        }
      }
      & .table-skeleton-row-cell {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;

        margin: 0;
        box-sizing: border-box;
        & .table-skeleton-row-content {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          & .table-skeleton-row-item {
            flex-grow: 1;
            max-width: 100%;
            flex-basis: 0;
            margin: 0;
            box-sizing: border-box;
            & .item-cell {
              color: white;
              height: 48px;

              width: calc(100% + 8px);
              margin: -4px;

              display: flex;
              flex-wrap: wrap;
              box-sizing: border-box;
              & .item-content {
                padding: 4px;

                position: relative;
                border-bottom: 1px solid #d7d7d7;

                flex-grow: 1;
                max-width: 100%;
                flex-basis: 0;

                margin: 0;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }
  }
`;
export const HeaderSkeleton = styled(Skeleton)`
  top: 37%;
  left: 20%;
  color: white;
  width: 60%;
  height: 15px;
  position: absolute;
`;
export const CellSkeleton = styled(Skeleton)`
  top: 35%;
  left: 20%;
  color: white;
  width: 60%;
  height: 15px;
  position: absolute;
  background: #d7d7d7;
`;
export const CardSkeleton = styled(Card)`
  &.card-content {
    display: flex;
    padding: 0;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    &.card-horizontal {
      height: 110px;
      max-height: 183px;
    }
    &.card-vertical {
      height: 220px;
      max-height: 308px;
    }
    & .card-skelet {
      background: #e0e5ec;
    }
  }
`;

export const ShadowSkeleton = styled(Skeleton)`
  box-shadow: 0 3px 5px 2px #d7d7d7;
`;
export const SkeletonGridSpacing1 = styled('div')`
  width: calc(100% + 8px);
  margin: -4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
`;
export const SkeletonHeaderCell = styled('div')`
  color: white;
  height: 48px;
  position: relative;
  box-shadow: 0 3px 5px 2px #d7d7d7;
  border-right: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  background-color: #f5f5f5;

  flex-grow: 1;
  max-width: 100%;
  flex-basis: 0;

  margin: 0;
  box-sizing: border-box;
`;
export const CalendarSkeletonRoot = styled('div')`
  &.calendar-skeleton-root {
    width: 100%;
    z-index: 1;
    position: absolute;
    & .calendar-conteiner {
      width: 100%;
      height: inherit;
      display: flex;
      overflow-x: unset;
      overflow-y: auto;
      flex-direction: column;
      padding-left: 80px;
      & .table-skeleton-grid {
        width: calc(100% + 8px);
        margin: -4px;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        & .table-skeleton-header-cell {
          color: white;
          height: 48px;
          position: relative;
          box-shadow: 0 3px 5px 2px #d7d7d7;
          border-right: 1px solid #d7d7d7;
          border-bottom: 1px solid #d7d7d7;
          background-color: #f5f5f5;

          flex-grow: 1;
          max-width: 100%;
          flex-basis: 0;

          margin: 0;
          box-sizing: border-box;
          & .table-skeleton-header-content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            & .table-skeleton-header-item {
              flex-grow: 1;
              max-width: 100%;
              flex-basis: 0;
              margin: 0;
              box-sizing: border-box;
            }
          }
        }
        & .table-skeleton-row-cell {
          flex-grow: 1;
          max-width: 100%;
          flex-basis: 0;

          margin: 0;
          box-sizing: border-box;
          & .table-skeleton-row-content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            & .table-skeleton-row-item {
              flex-grow: 1;
              max-width: 100%;
              flex-basis: 0;
              margin: 0;
              box-sizing: border-box;
              & .item-cell {
                color: white;
                height: 48px;

                width: calc(100% + 8px);
                margin: -4px;

                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                & .item-content {
                  padding: 4px;

                  position: relative;
                  border-bottom: 1px solid #d7d7d7;

                  flex-grow: 1;
                  max-width: 100%;
                  flex-basis: 0;

                  margin: 0;
                  box-sizing: border-box;
                }
              }
            }
          }
        }
      }
    }
  }
`;
