import { CheckEntity } from 'app/components/CheckBoxGroup';
import { generateTrainingSessionName } from 'app/components/pickers/AutocompletePickers/TrainingSessionPicker';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import { Entity } from 'types/common';
import { GlobalSettingsType } from '../components/useGlobalSettingsHook';
import { combineOptionalEquipments } from './mapSettingsFromServices';
import { TryDisableRemoveableEquipments } from './TryDisableRemoveableEquipments';
import {
  IReservationDto,
  getSingleReservationStatus,
  ReservationDetailsState,
} from './types';

export function ConvertDtoToModel(
  dto: IReservationDto,
  globalSettings?: GlobalSettingsType,
) {
  let equipments =
    dto.EquipmentsData.length > 0
      ? dto.EquipmentsData
      : dto.BaseEquipment === null
      ? []
      : [dto.BaseEquipment];
  if (dto.Id > 0) {
    if (
      dto.StaffMandatoryEquipment !== null &&
      dto.StaffMandatoryEquipment !== undefined
    ) {
      equipments = TryDisableRemoveableEquipments(
        equipments,
        f => f.Id === dto.StaffMandatoryEquipment?.Id,
        `Mandatory instrument for ${dto.StaffUser?.Name}`,
      );
    }
    if (!globalSettings?.allowToRemoveFromMultipleReservation) {
      equipments = TryDisableRemoveableEquipments(
        equipments,
        f => f.Id > 0,
        `Not removeable service`,
      );
    }
  }
  let model: ReservationDetailsState = {
    Id: dto.Id,
    FatherId: dto.FatherId,
    BookedBy: (dto.BookedBy as Entity<string>) || null,
    AccServices: dto.AccServices || [],
    Budget: (dto.Budget as Entity<number>) || null,
    CollaboratedUsers: (dto.CollaboratedUsers as Entity<string>[]) || [],
    EndTime: dto.EndTime,
    EquipmentsData: equipments as Entity<number>[],
    FormValues: dto.FormValues,
    InstituteProject: (dto.InstituteProject as Entity<number>) || null,
    PartialAttendance: dto.PartialAttendance,
    SampleIds: dto.SampleIds,
    StaffUser: (dto.StaffUser as Entity<string>) || null,
    StartTime: dto.StartTime,
    TrainingSession:
      dto.TrainingSession === null || dto.TrainingSession === undefined
        ? null
        : generateTrainingSessionName(dto.TrainingSession),
    TrainingSignUp: dto.TrainingSignUp,
    TransferredTo: (dto.TransferredTo as Entity<string>) || null,
    WaitingTransferApproval: dto.WaitingTransferApproval,
    TransferFromId: dto.TransferFromId,
    Tutoring: dto.Tutoring,
    FullDayReservation: dto.FullDayReservation,
    ADGroup: (dto.ADGroup as Entity<string>) || null,
    BudgetExperiment: (dto.BudgetExperiment as Entity<number>) || null,
    DisplayName: dto.DisplayName,
    ExternalCustomer: (dto.ExternalCustomer as Entity<number>) || null,
    FundingType:
      dto.FundingTypeId !== null
        ? getSingleInvoiceFundingType(dto.FundingTypeId)
        : null,
    PartialAttendanceEnd: dto.PartialAttendanceEnd,
    PartialAttendanceStart: dto.PartialAttendanceStart,
    PurchaseOrder: dto.PurchaseOrder,
    Reference: dto.Reference,
    Remarks: dto.Remarks,
    SampleRunId: dto.SampleRunId,
    Status: getSingleReservationStatus(dto.StatusId),
    WorkflowBooking: (dto.WorkflowBooking as Entity<number>) || null,
    OptionalsEquipments: (
      combineOptionalEquipments(dto.OptionalsEquipments) ?? []
    ).map(op => {
      if (dto.EquipmentsData.some(f => f.Id === op.Id)) {
        return Object.assign({}, op, { disabled: true, checked: false });
      } else {
        return Object.assign({}, op, { disabled: false, checked: false });
      }
    }) as CheckEntity[],
    AssemblyParts: dto.AssemblyParts,
  };
  return model;
}
