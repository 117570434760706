import * as React from 'react';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { FieldTypesUnion } from 'api/odata/generated/enums/FieldTypes';
import { Entity } from 'types/common';
import { assertExhaustive } from 'utils/assertExhaustive';
import { CustomFormAnswerQuestions } from './CustomFormFields/CustomFormAnswerQuestions';
import { CustomFormCheckBox } from './CustomFormFields/CustomFormCheckBox';
import { CustomFormDate } from './CustomFormFields/CustomFormDate';
import { CustomFormDisclimer as CustomFormDisclaimer } from './CustomFormFields/CustomFormDisclimer';
import { CustomFormDropDown } from './CustomFormFields/CustomFormDropDown';
import { CustomFormEmail } from './CustomFormFields/CustomFormEmail';
import { CustomFormFileUpload } from './CustomFormFields/CustomFormFileUpload';
import { CustomFormNumberText } from './CustomFormFields/CustomFormNumberText';
import { CustomFormParagraph } from './CustomFormFields/CustomFormParagraph';
import { CustomFormPositiveNumberText } from './CustomFormFields/CustomFormPositiveNumber';
import { CustomFormRadioGroup } from './CustomFormFields/CustomFormRadioGroup';
import { CustomFormRichText } from './CustomFormFields/CustomFormRichText';
import { CustomFormShortText } from './CustomFormFields/CustomFormShortText';
import { CustomFormUserSelection } from './CustomFormFields/CustomFormUserSelection';

export interface CustomFormFieldProps {
  name: string;
  id: string;
  formField: IFormFieldDto;
  readonly?: boolean;
  parentValue?: Entity<string>[];
  disabled?: boolean;
  onChange?: (value: any) => void;
  autoFocus?: boolean;
  required?: boolean;
  fullWidth?: boolean;
}
export function CustomFormField({
  formField,
  readonly,
  disabled,
  onChange,
  ...props
}: CustomFormFieldProps) {
  const requireComponent = (
    type: FieldTypesUnion,
  ): React.FC<CustomFormFieldProps> => {
    switch (type) {
      case 'MultipleAnswersQuestion':
        return CustomFormAnswerQuestions;
      case 'RadioButtons':
        return CustomFormRadioGroup;
      case 'CheckBox':
        return CustomFormCheckBox;
      case 'Disclaimer':
        return CustomFormDisclaimer;
      case 'Date':
        return CustomFormDate;
      case 'DropDownList':
        return CustomFormDropDown;
      case 'Email':
        return CustomFormEmail;
      case 'Number':
        return CustomFormNumberText;
      case 'PositiveNumber':
        return CustomFormPositiveNumberText;
      case 'Paragraph':
        return CustomFormParagraph;
      case 'RichText':
        return CustomFormRichText;
      case 'ShortText':
        return CustomFormShortText;
      case 'UserSelection':
        return CustomFormUserSelection;
      case 'File':
        return CustomFormFileUpload;
      case 'AlphanumericWithDashes':
        return CustomFormShortText;
      case 'FormSection':
        return CustomFormParagraph;
      default:
        assertExhaustive(type);
    }
  };
  const Component = requireComponent(formField.Type);
  return (
    <Component
      {...props}
      formField={formField}
      readonly={readonly}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
