/**
 *
 * CustomFormField
 *
 */
import React from 'react';
import { CustomFormFieldProps } from 'app/components/CustomForm/CustomFormFields';
import { Entity } from 'types/common';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { IFormFieldOptionDto } from 'api/odata/generated/entities/IFormFieldOptionDto';
import { FormAutocompleteOptions } from 'app/components/Forms/FormAutocompleteOptions';
import { FormAutocompleteMultiOptions } from 'app/components/Forms/FormAutocompleteMultiOptions';
import { ExternalApi } from 'api/ExternalApi';
import { FormAutocompleteMulti } from 'app/components/Forms/FormAutocompleteMulti';
import { FormAutocomplete } from 'app/components/Forms/FormAutocomplete';

export const initCustomDropDownData = (
  initialValue: string | undefined,
  formField: IFormFieldDto | undefined,
) => {
  if (initialValue === undefined || formField === undefined) {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      resolve([] as Entity<string>[]);
    });
  } else {
    return new Promise<Entity<string>[]>((resolve, reject) => {
      const options = createOptions(formField);
      resolve(
        getDropDownInitValue(initialValue, formField.IsMultiple, options),
      );
    });
  }
};
export const getDropDownInitValue = (
  initialValue: string | undefined,
  isMultiple: boolean,
  options: IFormFieldOptionDto[],
) => {
  if (initialValue === undefined) {
    return [] as Entity<string>[];
  } else {
    if (isMultiple) {
      let values = initialValue.split(',');
      return options.filter(item => values.includes(item.Id));
    } else {
      return options.filter(item => item.Id === initialValue);
    }
  }
};
export const createOptions = (
  field: Pick<IFormFieldDto, 'Options' | 'OptionsSourceType'>,
): IFormFieldOptionDto[] => {
  if (field.Options && field.Options !== null) {
    switch (field.OptionsSourceType) {
      case 'Internal':
        return deserializeOptions(field.Options);

      case 'SimpleList':
        return deserializeOptions(field.Options.split(','));
      case 'External':
        return [] as IFormFieldOptionDto[];
    }
  } else {
    return [] as IFormFieldOptionDto[];
  }
};
const deserializeOptions = (options: any): IFormFieldOptionDto[] => {
  let optsArray = [] as IFormFieldOptionDto[];
  if (Array.isArray(options)) {
    optsArray = options.map(opt => {
      return { Id: opt, Name: opt, Parent: null } as IFormFieldOptionDto;
    });
  } else {
    try {
      let items = JSON.parse(options);
      if (Array.isArray(items)) {
        let arr = items.map(o => {
          if (typeof o == 'string') {
            return { Id: o, Name: o, Parent: null } as IFormFieldOptionDto;
          } else if (o.hasOwnProperty('id') && o.hasOwnProperty('text')) {
            return {
              Id: o.id,
              Name: o.text,
              Parent: o.parent,
            } as IFormFieldOptionDto;
          } else {
            return undefined;
          }
        });
        optsArray =
          (arr.filter(o => o !== undefined) as IFormFieldOptionDto[]) ||
          ([] as IFormFieldOptionDto[]);
      }
      return optsArray;
    } catch {
      return optsArray;
    }
  }

  return optsArray;
};

export function CustomFormDropDown({
  formField,
  readonly,
  parentValue,
  disabled,
  ...props
}: CustomFormFieldProps) {
  const getOptions = (searchTerm: string | null): Entity<string>[] => {
    if (formField) {
      let options = createOptions(formField);
      if (formField.ParentId !== null && parentValue !== undefined) {
        return options.filter(
          item =>
            parentValue === undefined ||
            parentValue.length < 1 ||
            parentValue.filter(p => p.Id === item.Parent).length > 0,
        ) as Entity<string>[];
      } else {
        return options as Entity<string>[];
      }
    } else {
      return [] as Entity<string>[];
    }
  };
  const externalLoadData = (searchTerm: string | null) => {
    const params: {
      filter: string | null;
      pageSize: number;
      pageIndex: number;
    } = {
      filter: searchTerm,
      pageSize: 50,
      pageIndex: 0,
    };

    return ExternalApi.getExternalItems(formField.Id, params).then(response => {
      if (!!response.data.data) {
        let options = response.data.data.map(option => {
          return { Id: option.name, Name: option.value };
        });
        return options as Entity<string>[];
      } else if (!!response.data && response.data.length > 0) {
        let options = response.data.map(option => {
          return { Id: option.name, Name: option.value };
        });
        return options as Entity<string>[];
      } else {
        return [] as Entity<string>[];
      }
    });
  };
  return (
    <React.Fragment>
      {formField.OptionsSourceType === 'External' ? (
        formField.IsMultiple ? (
          <FormAutocompleteMulti
            key={formField.Id}
            disabled={disabled}
            loadData={externalLoadData}
            label={formField.Label}
            info={formField.Description ?? undefined}
            {...props}
            fullWidth={true}
          />
        ) : (
          <FormAutocomplete
            key={formField.Id}
            disabled={disabled}
            loadData={externalLoadData}
            label={formField.Label}
            info={formField.Description ?? undefined}
            {...props}
            fullWidth={true}
          />
        )
      ) : formField.IsMultiple ? (
        <FormAutocompleteMultiOptions
          key={formField.Id}
          disabled={disabled}
          getOptions={getOptions}
          label={formField.Label}
          info={formField.Description ?? undefined}
          {...props}
          fullWidth={true}
        />
      ) : (
        <FormAutocompleteOptions
          key={formField.Id}
          disabled={disabled}
          getOptions={getOptions}
          label={formField.Label}
          info={formField.Description ?? undefined}
          {...props}
          fullWidth={true}
        />
      )}
    </React.Fragment>
  );
}
