import * as React from 'react';
import { IRow } from 'app/components/BasicTable/IRow';
import { RenderRow, RenderRowProps } from './RenderRow';

export interface RenderGroupedRowProps<TRow extends IRow>
  extends RenderRowProps<TRow> {}

export function RenderGroupedRow<TRow extends IRow>({
  row,
  onRefresh,
  onRowClicked,
  rowIsClickable,
  rowTitle,
  cursorBehavior,
  children = cell =>
    cell.isAggregated
      ? // If the cell is aggregated, use the Aggregated
        // renderer for cell
        cell.render('Aggregated')
      : cell.isPlaceholder
      ? null // For cells with repeated values, render null
      : // Otherwise, just render the regular cell
        cell.render('Cell', {
          onRefresh: onRefresh,
        }),
}: RenderGroupedRowProps<TRow>) {
  return (
    <RenderRow
      row={row}
      onRefresh={onRefresh}
      onRowClicked={onRowClicked}
      cursorBehavior={cursorBehavior}
      rowIsClickable={rowIsClickable}
      rowTitle={rowTitle}
    >
      {children}
    </RenderRow>
  );
}
