import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { Button } from 'app/components/BasicButtons/Button';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { CustomFormControl } from 'app/components/CustomForm/CusomFormControl';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { selectPublicUrl } from 'app/slice/selectors';
import { CustomFormTypeEnum } from 'enums/CustomFormType';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { enumToEntityArray } from 'utils/enumKeys';
import { buildURL } from 'utils/url-utils';

export interface CustomFormPreviewProps
  extends SidePanelContentProps,
    CoverProps {
  CustomFormTypeId: number;
  CustomFormId: number;
}
export function CustomFormPreview(props: CustomFormPreviewProps) {
  const { CustomFormId, CustomFormTypeId, useSidePanel, closeCover, isCover } =
    props;

  const { t } = useTranslation();
  const formTypes = enumToEntityArray(CustomFormTypeEnum, t);
  const publicUrl = useSelector(selectPublicUrl);

  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(undefined, undefined, useSidePanel, isCover);

  const [values, setValues] = React.useState<IFormValueDto[]>([]);
  const customFormRef = React.useRef<any>(null);

  const handleCloselClick = React.useCallback(() => {
    if (useSidePanel) {
      closePanel({
        isCover: isCover || !!cover,
        useSidePanel: useSidePanel,
        showConfirm: false,
      } as SidePanelCloseState);
    }
  }, [closePanel, cover, isCover, useSidePanel]);
  const handleShowInSidePanelClick = React.useCallback(() => {
    let previewProps = {
      CustomFormId: CustomFormId,
      CustomFormTypeId: CustomFormTypeId,
    };
    openPanel({
      renderPageType: RenderPageType.CustomFormPreview,
      renderPageProps: previewProps,
      useSidePanel: useSidePanel,
      isCover: useSidePanel,
      expanded: CustomFormTypeId === CustomFormTypeEnum.Request,
    } as SidePanelOpenState);
  }, [CustomFormId, CustomFormTypeId, openPanel, useSidePanel]);
  const leftActions = React.useMemo(() => {
    let mactions = [] as ActionRenderer[];
    if (!useSidePanel) {
      mactions.push(() => (
        <Button
          id={'viewInSidePanel'}
          variant="main"
          size="small"
          //startIcon={<Icon icon="money-bill" />}
          onClick={e => handleShowInSidePanelClick()}
        >
          {t(translations.ViewInSidePanel)}
        </Button>
      ));
    }
    return mactions;
  }, [handleShowInSidePanelClick, t, useSidePanel]);

  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <IconButton
            variant="ghost"
            size="small"
            title={t(translations.Close)}
            onClick={handleCloselClick}
          >
            <Icon icon="times" />
          </IconButton>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);

  const pageTitle = React.useMemo(() => {
    return `${t(translations.Preview)} ${
      formTypes.find(f => f.Id === CustomFormTypeId)?.Name
    }`;
  }, [CustomFormTypeId, formTypes, t]);
  const pageName = React.useMemo(() => {
    return t(translations.CustomFormPreview);
  }, [t]);

  if (CustomFormId === 0) {
    return <NotFoundPage />;
  }

  if (!formTypes.some(f => f.Id === CustomFormTypeId)) {
    return <NotFoundPage />;
  }

  return (
    <PageWrapper
      pageName={pageName}
      titlePage={pageTitle}
      useSidePanel={useSidePanel}
      closable={true}
      pageLink={buildURL(
        publicUrl + `CustomFormPreview/${CustomFormTypeId}/${CustomFormId}`,
        {},
      )}
      toPageLink={`CustomFormPreview/${CustomFormTypeId}/${CustomFormId}`}
      leftActions={leftActions}
      rightActions={useSidePanel ? rightActions : undefined}
      isCover={isCover}
      cover={cover}
      closeCover={!isCover ? onCloseCover : closeCover}
      coverClosed={coverClosed}
    >
      <CustomFormControl
        CustomFormId={props.CustomFormId}
        isAdmin={true}
        values={values}
        FormType={CustomFormTypeId as CustomFormTypeEnum}
        submitFormRef={customFormRef}
        formIsValid={(isValid: boolean) => {}}
        saveFormValues={(
          values: IFormValueDto[],
          files: IFormFileValue[],
          originalFiles: IFormFileValue[],
        ) => {
          setValues(values);
        }}
        //disabled={false}
        //onLoadCompleted={onLoadCompleted}
        //onLoadStarted={onLoadStarted}
        //validationEnabled={validationEnabled}
      />
    </PageWrapper>
  );
}
