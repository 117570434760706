import React from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectPrintPDFSections } from './slice/selectors';
import clsx from 'clsx';

export interface PrintPDFSectionProps {
  print: boolean;
  id: string;
  forceChecked?: boolean;
  fullWidth?: boolean;
  sectionClass?: string;
}
export function PrintPDFSection(
  props: React.PropsWithChildren<PrintPDFSectionProps>,
) {
  const printPDFSections = useSelector(selectPrintPDFSections);
  const componentRef = useRef<HTMLDivElement>(null);
  const printClasses = React.useMemo(() => {
    const classes = ['print-section'];
    if (props.fullWidth) {
      classes.push('print-section-full-width');
    }
    let checked = printPDFSections?.some(
      f => f.id === props.id && (f.checked === true || props.forceChecked),
    );
    if (props.print && !checked) {
      classes.push('print-section-hidden');
    } else {
      classes.push('print-section-block');
    }
    return classes.join(' ');
  }, [
    printPDFSections,
    props.forceChecked,
    props.fullWidth,
    props.id,
    props.print,
  ]);
  // useEffect(() => {
  //   if (componentRef.current !== null) {
  //     if (props.print) {
  //       const checked = printPDFSections?.some(
  //         f => f.id === props.id && (f.checked === true || props.forceChecked),
  //       );
  //       if (checked) {
  //         componentRef.current.style.display = 'block';
  //       } else {
  //         componentRef.current.style.display = 'none';
  //       }
  //     } else {
  //       componentRef.current.style.display = 'initial';
  //     }
  //   }
  // }, [printPDFSections, props.forceChecked, props.id, props.print]);
  return (
    <>
      {props.print ? (
        <PrintSection
          className={clsx(printClasses, props.sectionClass)}
          ref={componentRef}
        >
          {props.children}
        </PrintSection>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
}
const PrintSection = styled.div`
  &.print-section {
    display: initial;
    & > * {
      border: none;
      padding: 0px;
    }
    & .rich-editor-header {
      display: none;
    }
    & .rich-text-input-label {
      transform: translate(9px, 4px) scale(1);
    }
    & > .formsection {
      & * {
        border: none;
      }
      & svg {
        display: none;
      }
      & .request-section {
        border: none;
        padding: 0px;
        gap: 8px;
        & .fields {
          gap: 0px;
        }
      }
      & .section-table {
        & > * {
          padding: 0px;
        }
        & .table-footer {
          display: none;
        }
      }
      & table {
        width: 100%; /* Ensure the table fits within the page */
        border-collapse: collapse;
        thead {
          display: table-header-group; /* Ensure the header repeats on every page */
        }
        tbody {
          display: table-row-group;
        }
        tr {
          page-break-inside: avoid; /* Prevent row splitting */
        }
        tr > th,
        tr > td {
          padding: 4px 8px;
          height: auto;
        }
      }
      & tr,
      td,
      th {
        page-break-inside: avoid;
      }
      & [class*='MuiToolbar-root'] {
        min-height: 32px;
        padding: 4px 0px;
      }
      & .services-cell .table-root {
        padding-bottom: 12px;
        & .table-content {
          padding: 0px;
        }
      }
      & .table-root.section-table {
        padding-bottom: 0px;
      }
    }
    &.print-section-hidden {
      display: none;
    }
    &.print-section-block {
      display: block;
      height: 100%;
    }
    &.print-section-full-width {
      width: 100%;
    }
    &.print-reservation {
      & .formsection {
        flex-direction: column;
        gap: 32px;
        & .rightsection {
          width: 100%;
        }
      }
    }
  }
`;
