/**
 *
 * RequestSamplesPage
 *
 */
import * as React from 'react';
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRequestSamplesSlice } from './slice';
import {
  selectIsServiceRequestAdmin,
  selectRequestDetails,
  selectRequestDetailsData,
  selectServiceRequestCustomFormColumns,
  selectServiceRequestCustomFormFields,
  selectServiceRequestFormData,
  selectServiceRequestSidePanel,
  selectSubmitting,
} from './slice/selectors';
import { useTranslation } from 'react-i18next';
import { SamplesTable } from '../components/SamplesTable';
import { translations } from 'locales/translations';
import { ServiceRequestCustomForm } from './components/ServiceRequestCustomForm';
import { PrintToPDF } from 'app/components/PrintToPDF';
import { PrintPDFSection } from 'app/components/PrintToPDF/PrintPDFSection';
import { usePrintPDFSlice } from 'app/components/PrintToPDF/slice';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { NotFoundPage } from 'app/pages/NotFoundPage';
import { PageWrapper } from 'app/Layout/FrontendLayout/components/PageWrapper';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { GetServiceRequestDetailsPath } from './slice/path';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { push } from 'connected-react-router';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { ServiceRequestSubmissionDetails } from '../components/ServiceRequestSubmissionDetails';
import { SubmitServiceRequestButton } from './components/SubmitServiceRequestButton';
import { CancelServiceRequestButton } from './components/CancelServiceRequestButton';
import { getServiceRequestTitle } from './getServiceRequestTitle';
import { getPrintPDFSections } from 'app/components/PrintToPDF/getPrintPDFSections';
import { ServiceRequestMilestonesSection } from '../components/ServiceRequestMilestonesSection';
import { AddonTypesUnion } from 'api/odata/generated/enums/AddonTypes';
import { assertExhaustive } from 'utils/assertExhaustive';
import { ServiceRequestMilestoneDetailsProps } from '../components/ServiceRequestMilestoneDetails';
import { ServiceRequestMilestoneDetails } from '../components/ServiceRequestMilestoneDetails';
import {
  ServiceRequestMilestoneChargeDetails,
  ServiceRequestMilestoneChargeDetailsProps,
} from '../components/ServiceRequestMilestoneChargeDetails';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { buildURL } from 'utils/url-utils';
import { CloneServiceRequestButton } from './components/CloneServiceRequestButton';
import {
  selectAppSettings,
  selectForbiddenFileExtensions,
  selectglobalSettings,
  selectKnownModule,
  selectUploadFileSizeLimit,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import { getNotificationsLogPageLocation } from 'app/pages/EmailNotificationsPage/getNotificationsLogPageLocation';
import { ServiceRequestRowsTable } from '../components/ServiceRequestRowsTable';
import { QuoteSentToUserAlert } from './components/QuoteSentToUserAlert';
import { SendQuoteButtonButton } from './components/SendQuoteButton';
import {
  FormFieldsSection,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { KnownModules } from 'types/KnownModules';
import { ServiceRequestRowComments as ServiceRequestComments } from '../components/ServiceRequestRowsTable/components/ServiceRequestComments';
import { getRequestDetailsSchema } from './utils/validationSchema';
import { Formik, FormikProps } from 'formik';
import {
  IServiceRequestDetailsFormModel,
  IServiceRequestTableRowModel,
} from './slice/types';
import { InternalServiceRequestStatus } from 'api/odata/generated/enums/InternalServiceRequestStatus';
import { DownloadAllFiles } from './components/DownloadAllFiles';
import { H4 } from 'app/components/Typography';
import {
  FormValuesErrorsSummury,
  RowsErrorsSummury,
} from './utils/RequestErrorSummury';
import {
  ReservationDetails,
  ReservationDetailsProps,
} from 'app/pages/ReservationDetails/Details';
import { AllowedSettings } from 'utils/globalSettings';
import { ServiceRequestReservations } from '../components/ServiceRequestReservations';
import { ReferenceType } from 'enums/ReferenceType';
import { DetectIsMobile } from 'utils/mobileDetect';
import { CreateRequestReservation } from '../components/CreateRequestReservation';
import {
  ReportConsumables,
  ReportConsumablesProps,
} from 'app/pages/OtherServiceDetails/ReportConsumables';
import { ShortRequestDetails } from '../components/ShortRequestDetails';
import clsx from 'clsx';
import { RequestContentWrapper } from '../styled';
import { useTheme } from '@material-ui/core';
import {
  OfflineTypeReferencesPopUp,
  OfflineTypeReferencesPopUpProps,
} from 'app/pages/OfflineTypeReferencesPage/OfflineTypeReferencesPopUp';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { OfflineReferenceTypeEnum } from 'enums/OfflineReferenceTypeEnum';
import { ServiceRequestBlockedByInvoice } from './components/ServiceRequestBlockedByInvoice';

export interface RequestSamplesPageProps
  extends RouteComponentProps<{ id: string }> {}
export interface ServiceRequestDetailsProps extends SidePanelContentProps {
  id: number;
}

export function RequestSamplesPage(props: RequestSamplesPageProps) {
  const serviceRequestId = parseInt(props.match.params.id);
  if (isNaN(serviceRequestId)) {
    return null;
  }
  return <ServiceRequestDetails id={serviceRequestId} useSidePanel={false} />;
}
export function RequestDetailsPage() {
  const location = useLocation();
  const id = React.useMemo(
    () => new URLSearchParams(location.search).get('id') ?? '',
    [location.search],
  );
  const serviceRequestId = parseInt(id);
  if (isNaN(serviceRequestId)) {
    return <NotFoundPage />;
  }
  return <Redirect to={'/requests/' + serviceRequestId} />;
}
export function ServiceRequestDetails({
  id,
  useSidePanel,
}: ServiceRequestDetailsProps) {
  const requestDetails = useSelector(selectRequestDetails);
  const requestDetailsData = useSelector(selectRequestDetailsData);
  const serviceRequestFormData = useSelector(selectServiceRequestFormData);
  const userProfileSettings = useSelector(selectUserProfileSettings);
  const appSettings = useSelector(selectAppSettings);
  const settings = useSelector(selectglobalSettings);
  const samplesAutomationEnabled = appSettings?.Modules.includes(
    KnownModules.SamplesAutomation,
  );
  const submittingRequest = useSelector(selectSubmitting);
  /**
   * custom form table section enabled/disabled
   */
  const SampleManagementTablesEnabled = useSelector(state =>
    selectKnownModule(state, KnownModules.SampleManagementTables),
  );
  const theme = useTheme();
  const requestReservationEnabled = React.useMemo(() => {
    return (
      settings.GetBoolean(
        AllowedSettings[AllowedSettings.ServiceRequestReservations],
      ) && (requestDetailsData?.ServiceRequestRelatedEquipments.length ?? 0) > 0
    );
  }, [requestDetailsData?.ServiceRequestRelatedEquipments.length, settings]);
  const { actions } = useRequestSamplesSlice();
  const { actions: printActions } = usePrintPDFSlice();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = DetectIsMobile();
  //todo
  const { isAdmin, isOwner, isEditable } = useSelector(
    selectIsServiceRequestAdmin,
  );
  const sidePanel = useSelector(selectServiceRequestSidePanel);

  React.useEffect(() => {
    dispatch(actions.loadRequestDetails(id));
  }, [actions, dispatch, id]);

  const [print, setPrint] = React.useState(false);
  const [reservationOpen, setReservationOpen] = React.useState<boolean>(false);
  const [selectedRows, setSelectedRows] = React.useState<
    IServiceRequestTableRowModel[]
  >([]);
  const [fullRequestDetails, setFullRequestDetails] =
    React.useState<boolean>(false);
  const handleFullDetailsChange = React.useCallback(
    (changedState?: boolean) => {
      if (changedState !== undefined) {
        setFullRequestDetails(changedState);
      } else {
        setFullRequestDetails(!fullRequestDetails);
      }
    },
    [fullRequestDetails],
  );
  const showCancelRequest = React.useMemo(() => {
    return (
      !!requestDetailsData &&
      requestDetailsData.Status.InternalStatusId !== 'Cancelled' &&
      (isAdmin ||
        (isOwner &&
          ['Draft', 'ReturnedToUser', 'Pending', 'QuoteSendToUser'].includes(
            requestDetailsData.Status.InternalStatusId,
          )))
    );
  }, [isAdmin, isOwner, requestDetailsData]);
  const innerFormRef =
    React.useRef<FormikProps<IServiceRequestDetailsFormModel> | null>(null);
  const handleCreateResClose = React.useCallback(() => {
    setReservationOpen(false);
    setSelectedRows([]);
  }, []);
  const handleCreateRowReservation = React.useCallback(
    (rows: IServiceRequestTableRowModel[]) => {
      setReservationOpen(true);
      setSelectedRows(rows);
    },
    [],
  );

  const customFormFields = useSelector(selectServiceRequestCustomFormFields);
  const customFormColumns = useSelector(selectServiceRequestCustomFormColumns);
  const handlePrintToPDF = React.useCallback(() => {
    setPrint(true);
  }, []);

  const ForbiddenFileExtensions = useSelector(selectForbiddenFileExtensions);
  const UploadFileSizeLimit = useSelector(selectUploadFileSizeLimit);
  const rowsVisible = React.useMemo(() => {
    return (
      !!requestDetailsData &&
      requestDetailsData.Id !== undefined &&
      customFormColumns?.getArray()?.length > 0 &&
      (requestDetailsData.Service?.CustomForm?.LanesEnabled ||
        SampleManagementTablesEnabled) &&
      (requestDetailsData.Service?.CustomForm?.VisibleToAdminOnly !== true ||
        isAdmin === true)
    );
  }, [
    SampleManagementTablesEnabled,
    customFormColumns,
    isAdmin,
    requestDetailsData,
  ]);
  const schema = React.useMemo(
    () =>
      (rowsVisible
        ? !!customFormColumns &&
          Object.keys(customFormColumns.columnsObj).length > 0
        : true) && customFormFields.length > 0
        ? getRequestDetailsSchema({
            columns: customFormColumns,
            fields: customFormFields,
            isAdmin,
            ForbiddenFileExtensions,
            UploadFileSizeLimit,
            values: serviceRequestFormData.FormValues,
          })
        : undefined,
    [
      ForbiddenFileExtensions,
      UploadFileSizeLimit,
      customFormColumns,
      customFormFields,
      isAdmin,
      rowsVisible,
      serviceRequestFormData.FormValues,
    ],
  );
  const [hasRowsErrors, setHasRowsErrors] = React.useState<boolean | undefined>(
    undefined,
  );
  React.useEffect(() => {
    if (!print) {
      const printSections = getPrintPDFSections(
        t,
        userProfileSettings.GetSettingByKey('/RequestSamples.pdf')?.Value ?? '',
        rowsVisible,
        requestReservationEnabled,
        samplesAutomationEnabled ?? false,
        (requestDetailsData?.Service?.MilestonesAddonsType ?? 'NA') !== 'NA',
      );
      dispatch(printActions.setUseSections(true));
      dispatch(printActions.updateSections(printSections));
    }
  }, [
    dispatch,
    print,
    printActions,
    requestDetailsData?.Service?.MilestonesAddonsType,
    requestReservationEnabled,
    rowsVisible,
    samplesAutomationEnabled,
    t,
    userProfileSettings,
  ]);
  // const errorsTimeout = React.useRef<number | undefined>();
  // React.useEffect(() => {
  //   if (errorsTimeout.current !== undefined) {
  //     clearTimeout(errorsTimeout.current);
  //   }
  //   if (
  //     (rowsVisible
  //       ? !!customFormColumns &&
  //         Object.keys(customFormColumns.columnsObj).length > 0
  //       : true) &&
  //     customFormFields.length > 0 &&
  //     innerFormRef.current !== null
  //   ) {
  //     errorsTimeout.current = setTimeout(() => {
  //       innerFormRef.current?.validateForm();
  //       console.log('validating form timeout');
  //     }, 6000);
  //   }

  //   return () => {
  //     clearTimeout(errorsTimeout.current);
  //     console.log('clearing timeout');
  //   };
  // }, [customFormColumns, customFormFields.length, rowsVisible]);
  // const submitAction = React.useMemo(() => {
  //   const x = () => (
  //     <SubmitServiceRequestButton
  //       serviceRequest={requestDetails?.value}
  //       editable={isEditable}
  //       getErrors={(errors: FormikErrors<IRequestDetailsModel>) =>
  //         setErrors(errors)
  //       }
  //     />
  //   );
  //   return x;
  // }, [isEditable, requestDetails?.value]);
  // const cancelAction = React.useMemo(
  //   () => () => (
  //     <CancelServiceRequestButton
  //       serviceRequestId={requestDetailsData?.Id}
  //       isAdmin={isAdmin}
  //     />
  //   ),
  //   [isAdmin, requestDetailsData?.Id],
  // );
  // const sendQuoteAction = React.useMemo(() => {
  //   const serviceRequest = requestDetailsData;
  //   if (serviceRequest === undefined) {
  //     return undefined;
  //   } else if (
  //     !canSendQuote({ isAdmin: isAdmin, serviceRequest: serviceRequest })
  //   ) {
  //     return undefined;
  //   } else {
  //     return () => (
  //       <SendQuoteButtonButton
  //         isAdmin={isAdmin}
  //         serviceRequest={serviceRequest}
  //       />
  //     );
  //   }
  // }, [isAdmin, requestDetailsData]);
  const handleManageOfflineRefsClick = React.useCallback(() => {
    let sidePanelProps: OfflineTypeReferencesPopUpProps = {
      useSidePanel: true,
      refId: requestDetailsData?.Service?.Id,
      refTypeId: OfflineReferenceTypeEnum.Offline_ServiceRequest,
    };

    dispatch(
      actions.openSidePanel({
        type: RenderPageType.OfflineTypeRefs,
        props: sidePanelProps,
        expanded: true,
      }),
    );
  }, [actions, dispatch, requestDetailsData?.Service?.Id]);
  const rightTopActions: ActionRenderer[] = React.useMemo(() => {
    let rtactions = [] as ActionRenderer[];
    rtactions.push(() => (
      <Button
        variant="white"
        size="small"
        startIcon={<Icon icon="print" />}
        aria-label="print"
        onClick={handlePrintToPDF}
        disabled={requestDetailsData?.Status.InternalStatusId === 'Draft'}
        title={
          requestDetailsData?.Status.InternalStatusId === 'Draft'
            ? t(translations.PleaseSubmitRequestFirstInOrderToPrint)
            : t(translations.PrintPdf)
        }
      >
        {t(translations.PrintPdf)}
      </Button>
    ));
    rtactions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="history" />}
        aria-label="history"
        href={buildURL(`/ServiceRequestAuditTrailHistory.aspx`, {
          requestId: requestDetailsData?.Id,
        })}
        target="_blank"
        title={t(translations.ModificationsHistory)}
      >
        {t(translations.ModificationsHistory)}
      </Button>
    ));
    rtactions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="history" />}
        aria-label="history"
        href={getNotificationsLogPageLocation({
          NotificationTypeId: 'Request',
          ReferenceId: requestDetailsData?.Id,
        })}
        target="_blank"
        title={t(translations.menu_EmailNotifications)}
      >
        {t(translations.menu_EmailNotifications)}
      </Button>
    ));
    rtactions.push(() => (
      <React.Fragment>
        <CloneServiceRequestButton item={requestDetailsData} />
      </React.Fragment>
    ));
    rtactions.push(() => (
      <Button
        variant="ghost"
        size="small"
        startIcon={<Icon icon="money-bill" />}
        aria-label="money-bill"
        href={buildURL(`Charges`, {
          refType: ReferenceType.ServiceRequest as number,
          refId: requestDetailsData?.Id,
        })}
        target="_blank"
        title={t(translations.Charges)}
      >
        {t(translations.Charges)}
      </Button>
    ));
    if (isAdmin) {
      rtactions.push(() => (
        <TopActionButton
          variant="ghost"
          size="small"
          startIcon="cog"
          icon="cog"
          text={t(translations.ManageRelatedService)}
          onClick={handleManageOfflineRefsClick}
        />
      ));
      rtactions.push(() => (
        <TopActionButton
          variant="ghost"
          size="small"
          href={{
            path: '/SampleType.aspx',
            search: {
              id: requestDetailsData?.Service?.Id,
              back: 'servicerequests',
            },
          }}
          target={'_blank'}
          startIcon="pencil-alt"
          icon="pencil-alt"
          text={t(translations.EditSampleType)}
        />
      ));
    }
    return rtactions;
  }, [
    handleManageOfflineRefsClick,
    handlePrintToPDF,
    isAdmin,
    requestDetailsData,
    t,
  ]);

  const leftActions = React.useMemo(() => {
    let actions = [] as ActionRenderer[];
    if (!!requestDetailsData) {
      if (isEditable) {
        actions.push(() => (
          <React.Fragment>
            <SubmitServiceRequestButton
              serviceRequest={requestDetailsData}
              getRowsErrors={(hasErrors: boolean) =>
                setHasRowsErrors(hasErrors)
              }
              formRef={innerFormRef}
              disabled={
                requestDetails.status === 'pending' || submittingRequest
              }
            />
          </React.Fragment>
        ));
      }
      if (showCancelRequest) {
        actions.push(() => (
          <React.Fragment>
            <CancelServiceRequestButton
              serviceRequestId={requestDetailsData?.Id}
            />
          </React.Fragment>
        ));
      }
      if (
        requestDetailsData.Service.SendQuoteToUser === true &&
        isAdmin === true &&
        ['InProcess', 'Draft'].includes(
          requestDetailsData.Status.InternalStatusId,
        )
      ) {
        actions.push(() => (
          <React.Fragment>
            <SendQuoteButtonButton serviceRequest={requestDetailsData} />
          </React.Fragment>
        ));
      }
      if (
        isAdmin &&
        requestDetailsData !== undefined &&
        requestDetailsData?.HasFiles === true
      ) {
        actions.push(() => (
          <DownloadAllFiles serviceRequestId={requestDetailsData.Id} />
        ));
      }
    }
    return [...actions, ...rightTopActions];
  }, [
    isAdmin,
    isEditable,
    requestDetails.status,
    requestDetailsData,
    rightTopActions,
    showCancelRequest,
    submittingRequest,
  ]);
  const handleClosePrintToPDF = React.useCallback(() => {
    setPrint(false);
  }, []);

  const handleCloseClick = React.useCallback(() => {
    dispatch(actions.resetRequestDetails());
    if (useSidePanel) {
      dispatch(layoutActions.resetSidePanel());
      dispatch(layoutActions.setRefreshTable(true));
    } else {
      dispatch(push('/ServiceRequests'));
    }
  }, [actions, dispatch, useSidePanel]);

  const handleSubmit = React.useCallback(
    async (values: IServiceRequestDetailsFormModel) => {
      if (requestDetailsData === undefined) {
        return;
      }
      dispatch(actions.submitStep({ ...requestDetailsData, ...values }));
    },
    [actions, dispatch, requestDetailsData],
  );

  const closeCover = () => dispatch(actions.setCover(undefined));
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="ghost"
            size="small"
            startIcon={<Icon icon="times" />}
            onClick={handleCloseClick}
          >
            {t(translations.Close)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloseClick, t]);

  const openRowsSidePanel = React.useCallback(
    state => {
      dispatch(
        actions.openSidePanel({
          type: state.renderPageType,
          props: state.renderPageProps,
        }),
      );
    },
    [actions, dispatch],
  );

  if (requestDetails.status !== 'pending') {
    if (requestDetails.error !== undefined) {
      const statusCode = (requestDetails.error as AxiosError)?.response?.status;
      switch (statusCode) {
        case StatusCodes.NOT_FOUND:
          return <NotFoundPage />;
      }
    }
  }
  const getTitle = (addonType?: AddonTypesUnion) => {
    if (addonType === undefined) {
      return undefined;
    }
    switch (addonType) {
      case 'Milestones':
        return t(translations.Milestones);
      case 'Addons':
      case 'UserAddons':
        return t(translations.AddonCharges);
      case 'NA':
        return undefined;

      default:
        assertExhaustive(addonType);
    }
  };

  if (requestDetailsData === undefined) {
    // nothing to show if the request details are empty but it's neither processing nor errored but the data for whatever reason is still not there
    return null;
  }
  const title = getServiceRequestTitle(requestDetailsData);
  const loading = requestDetails.status === 'pending';
  const cover =
    useSidePanel === false ? undefined : sidePanel?.type ===
      RenderPageType.ServiceRequestMilestone ? (
      <ServiceRequestMilestoneDetails
        {...(sidePanel.props as ServiceRequestMilestoneDetailsProps)}
      />
    ) : sidePanel?.type === RenderPageType.ServiceRequestMilestoneCharge ? (
      <ServiceRequestMilestoneChargeDetails
        {...(sidePanel.props as ServiceRequestMilestoneChargeDetailsProps)}
      />
    ) : sidePanel?.type === RenderPageType.ReservationDetails ? (
      <ReservationDetails
        {...({
          closeCover: () => {
            closeCover();
            dispatch(
              actions.loadServiceRequestRows({
                serviceRequestId: id,
                state: {
                  pageIndex: 0,
                  pageSize: 10,
                },
              }),
            );
          },
          onCloseReservation: () => {
            closeCover();
            dispatch(
              actions.loadServiceRequestRows({
                serviceRequestId: id,
                state: {
                  pageIndex: 0,
                  pageSize: 10,
                },
              }),
            );
          },
          ...sidePanel.props,
        } as ReservationDetailsProps)}
      />
    ) : sidePanel?.type === RenderPageType.ReportConsumables ? (
      <ReportConsumables
        {...({
          onClose: () => closeCover(),
          closeCover: () => closeCover(),
          ...sidePanel.props,
        } as ReportConsumablesProps)}
      />
    ) : sidePanel?.type === RenderPageType.OfflineTypeRefs ? (
      <OfflineTypeReferencesPopUp
        {...({
          onCloseTable: () => {
            closeCover();
            dispatch(layoutActions.setIsCoverExpanded(false));
            dispatch(actions.refreshRequestDetails(id));
          },
          closeCover: () => {
            closeCover();
            dispatch(layoutActions.setIsCoverExpanded(false));
            dispatch(actions.refreshRequestDetails(id));
          },
          ...sidePanel.props,
        } as OfflineTypeReferencesPopUpProps)}
      />
    ) : undefined;

  return (
    <>
      <PageWrapper
        topProcessing={requestDetails.status === 'pending' || submittingRequest}
        loading={loading}
        pageName={t(translations.ServiceRequest)}
        titlePage={title}
        pageLink={GetServiceRequestDetailsPath(requestDetailsData?.Id)}
        toPageLink={GetServiceRequestDetailsPath(requestDetailsData?.Id)}
        closable
        disableExpandToggle
        hideTitleBar={false}
        useSidePanel={useSidePanel}
        rightTopActions={rightTopActions}
        leftActions={leftActions}
        leftActionsMaxLength={2}
        rightActions={rightActions}
        closeSidePanel={handleCloseClick}
        cover={cover}
        closeCover={closeCover}
      >
        {requestDetails.value !== undefined && (
          <ServiceRequestBlockedByInvoice
            requestDetails={requestDetails.value}
          />
        )}
        <PrintToPDF
          title={'Print Request'}
          print={print}
          printTitle={title}
          useSections={true}
          close={handleClosePrintToPDF}
          saveMarkedSections={true}
          noTitlePrinted={true}
          hideDisabled={true}
        >
          <Formik
            //validateOnChange={true}
            // validateOnMount={
            //   serviceRequestFormData.FormValues !== undefined ||
            //   serviceRequestFormData.Rows !== undefined
            // }
            //validateOnBlur={true}
            validationSchema={schema}
            enableReinitialize={true}
            initialValues={serviceRequestFormData}
            onSubmit={handleSubmit}
            innerRef={innerFormRef}
          >
            {formik => {
              if (formik.errors) {
                console.log('Request errors', formik.errors);
              }
              return (
                <RequestContentWrapper
                  theme={theme}
                  className={clsx('request-wrapper', {
                    'request-print': print,
                  })}
                >
                  {print && <H4>{title}</H4>}
                  {isEditable && (
                    <QuoteSentToUserAlert
                      ServiceRequestId={requestDetailsData.Id}
                      ServiceRequestInternalStatus={
                        requestDetailsData.Status.InternalStatusId
                      }
                    />
                  )}
                  {requestDetailsData !== undefined && print && (
                    <ShortRequestDetails
                      requestDetails={requestDetailsData}
                      print={print}
                      fullDetails={fullRequestDetails}
                      onFullDetailsChange={handleFullDetailsChange}
                    />
                  )}
                  {requestDetailsData !== undefined && (
                    <PrintPDFSection print={print} id="SubmissionsDetails">
                      <React.Fragment>
                        {!print && (
                          <ShortRequestDetails
                            requestDetails={requestDetailsData}
                            print={print}
                            fullDetails={fullRequestDetails}
                            onFullDetailsChange={handleFullDetailsChange}
                          />
                        )}
                        <ServiceRequestSubmissionDetails
                          requestDetails={requestDetailsData}
                          isEditable={isEditable}
                          isOwner={isOwner}
                          isAdmin={isAdmin}
                          print={print}
                          fullDetails={fullRequestDetails}
                        />
                      </React.Fragment>
                    </PrintPDFSection>
                  )}

                  {customFormFields.length > 0 && (
                    <React.Fragment>
                      <PrintPDFSection print={print} id="RequestForm_Table">
                        <ServiceRequestCustomForm
                          serviceRequestId={requestDetailsData?.Id}
                          customForm={requestDetailsData?.Service.CustomForm}
                          isAdmin={isAdmin}
                          disabled={!isEditable}
                          print={print}
                          allowUserUpload={
                            isAdmin || requestDetailsData?.AllowUserUpload
                          }
                          visibleOnCompletion={() =>
                            requestDetailsData.Status.InternalStatusId ===
                            'Completed'
                          }
                          {...(innerFormRef.current ?? formik)}
                        />
                      </PrintPDFSection>
                    </React.Fragment>
                  )}

                  {rowsVisible && (
                    <React.Fragment>
                      {!!formik.errors &&
                        !print &&
                        Object.entries(formik.errors).some(
                          f => f[0] === 'Rows',
                        ) && (
                          <StyledFormFieldsContainer id="row_errors">
                            <RowsErrorsSummury errors={formik.errors} />
                          </StyledFormFieldsContainer>
                        )}

                      <PrintPDFSection print={print} id="RequestRows_Table">
                        <StyledFormFieldsContainer>
                          <ServiceRequestRowsTable
                            {...(innerFormRef.current ?? formik)}
                            serviceRequestId={requestDetailsData.Id}
                            serviceGroups={
                              !!requestDetailsData.Service.ServiceGroup
                                ? [requestDetailsData.Service.ServiceGroup]
                                : undefined
                            }
                            openSidePanel={openRowsSidePanel}
                            onCreateReservation={handleCreateRowReservation}
                            reservationEnabled={requestReservationEnabled}
                            useSidePanel={useSidePanel}
                            serviceRequestTypeId={requestDetailsData.Service.Id}
                            formFields={customFormColumns}
                            isEditable={isEditable}
                            requestStatusId={
                              requestDetailsData.Status.InternalStatusId
                            }
                            processing={false}
                            isAdmin={isAdmin}
                            hasErrors={hasRowsErrors}
                            rowStatusColumnEditable={
                              requestDetailsData.Service.CustomForm
                                .RowStatusColumnEditabilityId
                            }
                            rowStatusVisibility={
                              requestDetailsData.Service.CustomForm
                                .RowStatusColumnVisibilityId
                            }
                            hideRowIdColumn={
                              requestDetailsData.Service.CustomForm
                                .HideRowIdColumn
                            }
                            printing={{
                              id: '3',
                              onLoading: (id: string, loading: boolean) => {
                                dispatch(
                                  printActions.setLoading({
                                    id: id,
                                    loading: loading,
                                  }),
                                );
                              },
                              printing: print,
                            }}
                          />
                        </StyledFormFieldsContainer>
                      </PrintPDFSection>
                    </React.Fragment>
                  )}

                  {requestReservationEnabled && (
                    <PrintPDFSection print={print} id="Request_Reservations">
                      <StyledFormFieldsContainer>
                        <ServiceRequestReservations
                          serviceRequestId={requestDetailsData.Id}
                          openPanel={openRowsSidePanel}
                          isMobile={isMobile}
                          useSidePanel={useSidePanel}
                          onCreateReservation={() => setReservationOpen(true)}
                          isAdmin={isAdmin}
                          tableContainerAsSection={true}
                          print={print}
                        />
                      </StyledFormFieldsContainer>
                    </PrintPDFSection>
                  )}
                  {samplesAutomationEnabled && (
                    <PrintPDFSection print={print} id="Request_Samples">
                      <StyledFormFieldsContainer>
                        <SamplesTable
                          isAdmin={isAdmin}
                          isEditable={isEditable}
                          serviceRequestId={requestDetailsData?.Id}
                          serviceGroupId={
                            requestDetailsData?.ServiceGroupId ?? undefined
                          }
                          // processing skeleton is shown independently above
                          processing={false}
                          printing={{
                            id: '1',
                            onLoading: (id: string, loading: boolean) => {
                              dispatch(
                                printActions.setLoading({
                                  id: id,
                                  loading: loading,
                                }),
                              );
                            },
                            printing: print,
                          }}
                          tableContainerAsSection={true}
                        />
                      </StyledFormFieldsContainer>
                    </PrintPDFSection>
                  )}

                  {requestDetailsData !== undefined && (
                    <PrintPDFSection print={print} id="Milestones_Services">
                      <StyledFormFieldsContainer>
                        <ServiceRequestMilestonesSection
                          ServiceRequestId={requestDetailsData?.Id}
                          AddonType={
                            requestDetailsData.Service.MilestonesAddonsType
                          }
                          isAdmin={isAdmin}
                          InternalStatus={
                            requestDetailsData.Status.InternalStatusId
                          }
                          pageName={getTitle(
                            requestDetailsData.Service.MilestonesAddonsType,
                          )}
                          ServiceTypeId={requestDetailsData.Service.Id}
                          tableContainerAsSection={true}
                          printing={{
                            id: '2',
                            onLoading: (id: string, loading: boolean) => {
                              dispatch(
                                printActions.setLoading({
                                  id: id,
                                  loading: loading,
                                }),
                              );
                            },
                            printing: print,
                          }}
                          useSidePanel={useSidePanel}
                          UnitType={requestDetailsData.UnitType}
                        />
                      </StyledFormFieldsContainer>
                    </PrintPDFSection>
                  )}
                  {requestDetailsData?.Id !== undefined && (
                    <PrintPDFSection print={print} id="Comments">
                      <StyledFormFieldsContainer>
                        <FormFieldsSection useRequest={true}>
                          <ServiceRequestComments
                            print={print}
                            commentType={CommentReferenceTypes.ServiceRequest}
                            referenceId={requestDetailsData?.Id}
                            serviceRequestId={requestDetailsData?.Id}
                            serviceGroups={
                              !!requestDetailsData.Service.ServiceGroup
                                ? [requestDetailsData.Service.ServiceGroup]
                                : undefined
                            }
                            canUserEdit={
                              !requestDetailsData.CommentOnEditOnly ||
                              [
                                InternalServiceRequestStatus[
                                  InternalServiceRequestStatus.Draft
                                ],
                                InternalServiceRequestStatus[
                                  InternalServiceRequestStatus.ReturnedToUser
                                ],
                              ].includes(
                                requestDetailsData.Status.InternalStatusId,
                              )
                            }
                            disableFileUpload={
                              !isAdmin && !requestDetailsData.AllowUserUpload
                            }
                          />
                        </FormFieldsSection>
                      </StyledFormFieldsContainer>
                    </PrintPDFSection>
                  )}
                  {requestReservationEnabled &&
                    !!requestDetailsData &&
                    isAdmin && (
                      <CreateRequestReservation
                        createOpen={reservationOpen}
                        onClose={handleCreateResClose}
                        serviceRequestId={requestDetailsData.Id}
                        gradient={requestDetailsData.Gradient}
                        serviceGroupId={
                          requestDetailsData.Service.ServiceGroup.Id
                        }
                        serviceRequestRelatedEquipments={
                          requestDetailsData.ServiceRequestRelatedEquipments
                        }
                        userName={
                          (
                            requestDetailsData.CreatedFor ??
                            requestDetailsData.CreatedBy
                          ).Id
                        }
                        budgetId={
                          requestDetailsData.Budget.Id === 0
                            ? undefined
                            : requestDetailsData.Budget.Id
                        }
                        requestOverheadMinutes={
                          requestDetailsData.OverheadMinutes
                        }
                        openPanel={state => {
                          dispatch(
                            actions.openSidePanel({
                              type: state.renderPageType,
                              props: state.renderPageProps,
                            }),
                          );
                        }}
                        useSidePanel={useSidePanel}
                        rows={selectedRows}
                      />
                    )}
                  <StyledFormFieldsContainer>
                    <FormValuesErrorsSummury
                      errors={formik.errors ?? innerFormRef.current?.errors}
                    />
                    {/* <RequestErrorsSummury errors={formik.errors} /> */}
                  </StyledFormFieldsContainer>
                </RequestContentWrapper>
              );
            }}
          </Formik>
        </PrintToPDF>
      </PageWrapper>
    </>
  );
}
