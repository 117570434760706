import { useTheme } from '@material-ui/core';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import * as React from 'react';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { AddService } from './AddService';
import { QuickServiceItem } from './QuickServiceItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { selectRefreshOfflineServices } from 'app/pages/ReservationDetails/Details/slice/selectors';
import { useReservationSlice } from 'app/pages/ReservationDetails/Details/slice';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { AddServiceFromSelection } from './AddServiceFromSelection';
import { AssetsSelectionProps } from 'app/pages/MyAssetsPage/AssetsSelection/AssetsSelection';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { ScannerView } from 'app/components/ScannerView';
import { selectRefreshConsumableServices } from '../slice/selectors';
import { useOfflineServiceStateSlice } from '../slice';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { ErrorServices, ReportedFrom } from '../slice/types';
import clsx from 'clsx';
import styled from 'styled-components';
import { Condition } from 'api/odata/ODataFilter';
import {
  selectExpandedSidePanel,
  selectIsCoverExpanded,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';

export interface BookQuickServicesProps {
  initServicesFunc: () => Promise<IOtherServices[]>;
  loadSeviceTypes?: (
    searchTerm: string | null,
    predicates: (string | Condition<IOfflineServiceFilterDto>)[] | undefined,
  ) => Promise<IOfflineServiceFilterDto[]>;
  onServiceUpdate?: (service: IOtherServices) => Promise<any>;
  onServiceRemove?: (service: IOtherServices) => Promise<any>;
  onServicesRemove?: (service: IOtherServices[]) => Promise<any>;
  onServiceClick?: (item: IOtherServices) => void;
  onRenewStockClick?: (service: IOtherServices) => void;
  onAddBatchClick?: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
  createServiceFromServiceType?: (
    serviceTypes: IOfflineServiceFilterDto,
  ) => Promise<IOtherServices | undefined>;
  createServicesFromServiceType?: (
    serviceTypes: IOfflineServiceFilterDto[],
  ) => Promise<IOtherServices[]>;
  userName?: string;
  isEditMode?: boolean;
  disabled?: boolean;
  useAssetSelection?: boolean;
  useDropDownSelectionFirst?: boolean;
  onAddClick?: (services: number[]) => void;
  onAddAndRemoveServices?: (
    serviceType: IOfflineServiceFilterDto[],
    services: IOtherServices[],
  ) => Promise<IOtherServices[]>;
  createdEditable?: boolean;
  renewStock?: boolean;
  setError?: (errors: ErrorServices) => void;
  showScannerView?: boolean;
  isCover?: boolean;
  reportFrom?: ReportedFrom;
  offlineTypeFilter?:
    | (string | Condition<IOfflineServiceFilterDto>)[]
    | undefined;
}
const QuickServiceItems = styled('div')(({ theme }) => ({
  width: '100%',
  '& .addNewSection': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: '16px',
    '&.hasServices': {
      padding: '16px 0px 0px',
    },
    '& .sectionRow': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0',
      gap: '12px',
      '& .sectionItems': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0',
        gap: '8px',
      },
    },
  },
}));

export const BookQuickServices = React.memo(function BookQuickServices(
  props: BookQuickServicesProps,
) {
  const user = useSelector(selectAuthenticatedUser);
  const refreshServices = useSelector(selectRefreshOfflineServices);
  const refreshCosumables = useSelector(selectRefreshConsumableServices);
  const dispatch = useDispatch();
  const { actions } = useReservationSlice();
  const { actions: offlineActions } = useOfflineServiceStateSlice();
  const { actions: layoutActions } = useLayoutSlice();
  const {
    initServicesFunc,
    onServiceRemove,
    onServicesRemove,
    onServiceUpdate,
    createServiceFromServiceType,
    createServicesFromServiceType,
    loadSeviceTypes,
    onServiceClick,
    userName,
    isEditMode,
    disabled,
    useAssetSelection,
    onAddClick,
    onAddAndRemoveServices,
    createdEditable,
    renewStock,
    onAddBatchClick,
    onRenewStockClick,
    setError,
    showScannerView,
    isCover,
    reportFrom,
    useDropDownSelectionFirst,
    offlineTypeFilter,
  } = props;
  const theme = useTheme();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const coverExpanded = useSelector(selectIsCoverExpanded);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const isMobile = DetectIsMobile();
  const showShortView = React.useMemo(() => {
    return (
      (sidePanelOpen && !sidePanelExpanded) ||
      isMobile ||
      (isCover && !coverExpanded)
    );
  }, [coverExpanded, isCover, isMobile, sidePanelExpanded, sidePanelOpen]);
  const fetchServices = async (): Promise<IOtherServices[]> => {
    return new Promise(async resolve => {
      try {
        let data = await initServicesFunc();
        resolve(data);
      } catch {
        resolve([]);
      }
    });
  };
  const { newDate } = useSystemDate();
  const currentUser = React.useMemo(() => {
    return !!userName ? userName : user?.Id;
  }, [user?.Id, userName]);
  const [serviceCreated, setServiceCreated] = React.useState<
    boolean | undefined
  >(undefined);
  const [services, setServices] = useAsyncExtendedState<IOtherServices[]>([]);
  const [fetchServicesState, fetchServicesPromise] = usePromise(fetchServices);
  React.useEffect(() => {
    if (
      !fetchServicesState.status ||
      (fetchServicesState.status !== 'pending' &&
        fetchServicesState.status !== 'resolved')
    ) {
      setServices(fetchServicesPromise());
    }
    return () => {};
  }, [
    fetchServicesPromise,
    fetchServicesState.status,
    initServicesFunc,
    setServices,
  ]);
  React.useEffect(() => {
    let active = refreshServices || refreshCosumables;
    if (active === true && fetchServicesState.status !== 'pending') {
      dispatch(actions.setRefreshOfflineServices(undefined));
      dispatch(offlineActions.setRefreshConsumableOfflineServices(undefined));
      setServices(fetchServicesPromise());
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    fetchServicesPromise,
    fetchServicesState.status,
    initServicesFunc,
    offlineActions,
    refreshCosumables,
    refreshServices,
    setServices,
  ]);
  const handleServiceChange = async (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      if (!!onServiceUpdate) {
        try {
          let data = await onServiceUpdate(service);
          if (!!data) {
            setServices(
              services.map(f => {
                if (f.ServiceTypeID === service.ServiceTypeID) {
                  return Object.assign({}, f, service);
                } else {
                  return f;
                }
              }),
            );
            resolve(data);
          } else {
            reject('error occured');
          }
        } catch (error) {
          reject(error);
        }
      } else {
        setServices(
          services.map(f => {
            if (f.ServiceTypeID === service.ServiceTypeID) {
              return Object.assign({}, f, service);
            } else {
              return f;
            }
          }),
        );
        resolve(service);
      }
    });
  };
  const handleRemoveService = async (service: IOtherServices) => {
    return new Promise(async (resolve, reject) => {
      if (!!onServiceRemove) {
        try {
          let res = await onServiceRemove(service);
          setServices(
            services.filter(f => {
              if (isEditMode) {
                return f.Id !== service.Id;
              } else {
                return f.ServiceTypeID !== service.ServiceTypeID;
              }
            }),
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      } else {
        setServices(
          services.filter(f => {
            return f.ServiceTypeID !== service.ServiceTypeID;
          }),
        );
        resolve(service);
      }
    });
  };
  const handleAddService = React.useCallback(
    (serviceType: IOfflineServiceFilterDto) => {
      setServiceCreated(true);
      setServices(
        new Promise(async resolve => {
          try {
            if (!!createServiceFromServiceType) {
              let data = await createServiceFromServiceType(serviceType);
              if (!!data) {
                resolve([...services, data]);
              } else {
                resolve([...services]);
              }
            } else {
              let batch = serviceType.InventoryBatchesEnabled
                ? !!serviceType.InventoryBatches &&
                  serviceType.InventoryBatches.length > 0
                  ? serviceType.InventoryBatches.filter(
                      f =>
                        (f.ExpirationDate === null ||
                          f.ExpirationDate > newDate()) &&
                        (serviceType.NotLessThanZero
                          ? f.Inventory > 0
                          : true) &&
                        (renewStock === true || f.StokRenewed === true),
                    ).sort((a, b) => b.Id - a.Id)[0]
                  : null
                : null;
              resolve([
                ...services,
                {
                  Id: 0,
                  ServiceTypeID: serviceType.Id,
                  BookedBy: currentUser,
                  DefaultQuantity: serviceType.DefaultQuantity,
                  IntQuantityOnly: serviceType.IntQuantityOnly,
                  Mandatory: false,
                  Quantity: serviceType.DefaultQuantity,
                  ServiceDate: newDate(),
                  ServiceType: serviceType.Name,
                  Units: serviceType.UnitTypeName,
                  ServiceGroupId: serviceType.ServiceGroupId,
                  NotLessThanZero: serviceType.NotLessThanZero,
                  TrackInventory: serviceType.TrackInventory,
                  InventoryBatchesEnabled: serviceType.InventoryBatchesEnabled,
                  InventoryBatchId: batch === null ? null : batch.Id,
                  InventoryBatchName: batch === null ? null : batch.Name,
                  InventoryBatchAmount: batch === null ? null : batch.Inventory,
                } as IOtherServices,
              ]);
            }
          } catch {
            resolve([...services]);
          } finally {
            setServiceCreated(undefined);
          }
        }),
      );
    },
    [
      createServiceFromServiceType,
      currentUser,
      newDate,
      renewStock,
      services,
      setServices,
    ],
  );
  const handleAddServices = React.useCallback(
    (serviceTypes: IOfflineServiceFilterDto[]) => {
      setServiceCreated(true);
      setServices(
        new Promise(async resolve => {
          try {
            if (!!createServicesFromServiceType) {
              let data = await createServicesFromServiceType(serviceTypes);
              if (!!data && data.length > 0) {
                resolve([...services, ...data]);
              } else {
                resolve([...services]);
              }
            } else {
              resolve([
                ...services,
                ...serviceTypes.map(s => {
                  let batch = s.InventoryBatchesEnabled
                    ? !!s.InventoryBatches && s.InventoryBatches.length > 0
                      ? s.InventoryBatches.filter(
                          f =>
                            (f.ExpirationDate === null ||
                              f.ExpirationDate > newDate()) &&
                            (s.NotLessThanZero ? f.Inventory > 0 : true) &&
                            (renewStock === true || f.StokRenewed === true),
                        ).sort((a, b) => b.Id - a.Id)[0]
                      : null
                    : null;
                  return {
                    Id: 0,
                    ServiceTypeID: s.Id,
                    BookedBy: currentUser,
                    DefaultQuantity: s.DefaultQuantity,
                    IntQuantityOnly: s.IntQuantityOnly,
                    Mandatory: false,
                    Quantity: s.DefaultQuantity,
                    ServiceDate: newDate(),
                    ServiceType: s.Name,
                    Units: s.UnitTypeName,
                    UnitTypeId: s.UnitTypeID,
                    ServiceGroupId: s.ServiceGroupId,
                    NotLessThanZero: s.NotLessThanZero,
                    TrackInventory: s.TrackInventory,
                    InventoryBatchesEnabled: s.InventoryBatchesEnabled,
                    InventoryBatchId: batch === null ? null : batch.Id,
                    InventoryBatchName: batch === null ? null : batch.Name,
                    InventoryBatchAmount:
                      batch === null ? null : batch.Inventory,
                  } as IOtherServices;
                }),
              ]);
            }
          } catch {
            resolve([...services]);
          } finally {
            setServiceCreated(undefined);
          }
        }),
      );
    },
    [
      createServicesFromServiceType,
      currentUser,
      newDate,
      renewStock,
      services,
      setServices,
    ],
  );
  const handleRemoveServices = async (items: IOtherServices[]) => {
    return new Promise(async (resolve, reject) => {
      if (!!onServicesRemove) {
        try {
          let res = await onServicesRemove(items);
          setServices(
            services.filter(f => {
              if (isEditMode) {
                return !items.some(a => a.Id === f.Id);
              } else {
                return !items.some(a => a.ServiceTypeID === f.ServiceTypeID);
              }
            }),
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      } else {
        let newServices = services.filter(f => {
          return !items.some(a => a.ServiceTypeID === f.ServiceTypeID);
        });
        setServices(newServices);
        resolve(newServices);
      }
    });
  };
  const handleAddAndRemoveServices = async (
    serviceTypes: IOfflineServiceFilterDto[],
    removed: IOtherServices[],
  ) => {
    return new Promise(async (resolve, reject) => {
      if (!!onAddAndRemoveServices) {
        try {
          let res = await onAddAndRemoveServices(serviceTypes, removed);
          setServices(res);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      } else {
        let staticServices = serviceTypes.map(s => {
          return {
            Id: 0,
            ServiceTypeID: s.Id,
            BookedBy: currentUser,
            DefaultQuantity: s.DefaultQuantity,
            IntQuantityOnly: s.IntQuantityOnly,
            Mandatory: false,
            Quantity: s.DefaultQuantity,
            ServiceDate: newDate(),
            ServiceType: s.Name,
            Units: s.UnitTypeName,
            ServiceGroupId: s.ServiceGroupId,
          } as IOtherServices;
        });
        let addedServ = staticServices.filter(
          f => !services.some(s => s.ServiceTypeID === f.ServiceTypeID),
        );
        let otherServices = services.filter(f => {
          return !removed.some(a => a.ServiceTypeID === f.ServiceTypeID);
        });
        setServices([...otherServices, ...addedServ]);
        resolve([...otherServices, ...addedServ]);
      }
    });
  };
  const focusedService = React.useMemo(() => {
    return services.some(f => f.Quantity === null && f.DefaultQuantity === null)
      ? services.filter(
          f => f.Quantity === null && f.DefaultQuantity === null,
        )[0]
      : services[0];
  }, [services]);
  return (
    <QuickServiceItems theme={theme}>
      {services.map((item, indx) => (
        <QuickServiceItem
          key={item.Id + `_${indx}_` + item.ServiceTypeID}
          handleChange={handleServiceChange}
          handleRemove={handleRemoveService}
          onServiceClick={onServiceClick}
          onRenewStockClick={onRenewStockClick}
          onAddBatchClick={onAddBatchClick}
          mandatory={item.Mandatory}
          service={item}
          isEditMode={isEditMode}
          createdEditable={createdEditable}
          count={item.Quantity ?? item.DefaultQuantity}
          minStep={item.IntQuantityOnly === true ? 1 : 0.01}
          step={1}
          maxStep={9999}
          user={user}
          renewStock={renewStock}
          setError={setError}
          shortView={showShortView}
          focused={
            item.ServiceTypeID === focusedService.ServiceTypeID
              ? true
              : undefined
          }
          disabled={
            item.Mandatory
              ? (item.Quantity ?? (item.IntQuantityOnly === true ? 1 : 0.01)) <
                (item.IntQuantityOnly === true ? 1 : 0.01) * 2
              : (item.Quantity ?? 0) <= -1
          }
        />
      ))}
      <div
        className={clsx('addNewSection', { hasServices: services.length > 0 })}
      >
        {(useAssetSelection || showScannerView) && services.length < 1 && (
          <ScannerView />
        )}

        <div className={'sectionRow'}>
          <div className={'sectionItems'}>
            {useAssetSelection && !useDropDownSelectionFirst ? (
              <React.Fragment>
                <AddServiceFromSelection
                  addServices={handleAddServices}
                  getServiceTypes={loadSeviceTypes}
                  removeServices={handleRemoveServices}
                  addAndRemoveServices={handleAddAndRemoveServices}
                  selected={services}
                  disabled={serviceCreated || disabled}
                  reportFrom={reportFrom}
                  onAddClick={
                    !!onAddClick
                      ? onAddClick
                      : (services: number[]) => {
                          let selParams = {
                            useSidePanel: true,
                            actionType: 'OfflineService',
                            offTypeIds: services,
                          } as AssetsSelectionProps;
                          dispatch(
                            layoutActions.openSidePanel({
                              type: RenderPageType.AssetsSelection,
                              props: selParams,
                              expanded: false,
                            }),
                          );
                        }
                  }
                />
              </React.Fragment>
            ) : (
              <AddService
                addService={handleAddService}
                addServices={handleAddServices}
                getServiceTypes={loadSeviceTypes}
                selected={services}
                disabled={serviceCreated || disabled}
                reportFrom={reportFrom}
                useAssetSelection={useDropDownSelectionFirst}
                offlineTypeFilter={offlineTypeFilter}
                removeServices={handleRemoveServices}
                addAndRemoveServices={handleAddAndRemoveServices}
                onAddClick={
                  !!onAddClick
                    ? onAddClick
                    : (services: number[]) => {
                        let selParams = {
                          useSidePanel: true,
                          actionType: 'OfflineService',
                          offTypeIds: services,
                        } as AssetsSelectionProps;
                        dispatch(
                          layoutActions.openSidePanel({
                            type: RenderPageType.AssetsSelection,
                            props: selParams,
                            expanded: false,
                          }),
                        );
                      }
                }
              />
            )}
          </div>
        </div>
      </div>
    </QuickServiceItems>
  );
});
