import * as React from 'react';
import { FieldHookConfig, useField } from 'formik';
import { IFieldProps } from './FormRender/IFormFieldSetting';
import { BasicCheckBox, BasicCheckBoxProps } from '../BasicCheckBox';

export interface FormCheckboxProps extends BasicCheckBoxProps {
  label?: string;
  helperText?: string;
}
export const FormCheckbox = ({
  label,
  onChange,
  onBlur,
  value,
  name,
  color,
  helperText,
  required,
  fullWidth,
  ...props
}: FormCheckboxProps & FieldHookConfig<boolean> & IFieldProps) => {
  const [field, meta] = useField<boolean>({ name, ...props });
  const handleChange = (value, event) => {
    if (onChange !== undefined) {
      onChange(value, event);
    }
    field.onChange(event);
  };
  return (
    <>
      <BasicCheckBox
        error={meta?.error}
        label={label}
        info={props.info}
        color={color || 'primary'}
        onChange={handleChange}
        onBlur={field?.onBlur}
        value={field.value}
        checked={field.value}
        name={name}
        fullWidth={fullWidth}
        required={required && field.value !== true}
        {...props}
      />
    </>
  );
};
