import { IAssetRangeDto } from 'api/odata/generated/complexTypes/IAssetRangeDto';
import { Entity } from 'types/common';
import { AlertInfo, IAsset } from '../IAsset';
import { IAssetAssemblyStructure } from '../IAssetAssemblyStructure';
import { IAssetRow } from '../IAssetRow';

export interface RawSection {
  Id: string;
  Index: number;
  SectionName: boolean;
  remove: boolean;
}
export interface Section {
  Id: string;
  row: IAssetRow;
  rows: IAssetRow[];
}
export interface Sections {
  leftSections: Section[];
  rightSections: Section[];
}
export interface SectionsProps {
  sections: Section[];
  data?: IAsset;
}
export interface AssetDetailsAdditionalData {
  Id: number;
  ApprovalRequired: boolean;
  Status: string;
  StatusId: number;
  AssemblyStructure?: IAssetAssemblyStructure;
  HideTrainingSession: boolean;
  Range: IAssetRangeDto[];
  ForceTutoring: boolean;
  AutoMode?: number;
  Allowed_ByTraining?: boolean;
  CanStartUsage: boolean;
  CanEndUsage: boolean;
  UsageId?: number;
  EndUsageIds?: number[];
  Alerts?: Entity<number>[];
  PlanedEventsEnabled: boolean;
  AlertInfo?: AlertInfo;
  NotifyExists?: boolean;
  CanBeCreatedFromLinkOnly?: boolean;
  NotAllowReservations: boolean | null;
  ShowFeed?: boolean;
  AssemblyId: number | null;
  CanRequestAccess: boolean;
  ApprovalStatusId: number | null;
  NotActiveSince?: Date;
  AssetImageNames?: string[];
  InternalId: string;
  IncidentSettings: boolean;
  PublicationSettings: boolean;
}

export class CountersForTabs {
  Reservations: { Enabled: boolean; Count: number } = {
    Enabled: true,
    Count: 0,
  };
  Schedule: { Enabled: boolean; Count: number } = {
    Enabled: true,
    Count: 0,
  };
  WorkOrders: { Enabled: boolean; Count: number } = {
    Enabled: true,
    Count: 0,
  };
  Calibrations: { enabled: boolean; Count: number } = {
    enabled: true,
    Count: 0,
  };
  Publications: { Enabled: boolean; Count: number } = {
    Enabled: true,
    Count: 0,
  };
  TrainingSessions: { Enabled: boolean; Count: number } = {
    Enabled: true,
    Count: 0,
  };
  WaitingList: { Enabled: boolean; Count: number } = {
    Enabled: true,
    Count: 0,
  };
}
export const ExpandableRows: Array<string> = [
  //'Alerts',
  'Tags',
  'Suppliers',
  'Contacts',
  'Experts',
  'AssetAccessories',
  'ServiceGroup',
  'AssetCat',
  'AssetCatGroup',
  'AvailabilityType',
  'LocationsList',
  'ExtraDDL0',
  'ExtraDDL1',
  'ExtraDDL2',
  'ExtraDDL3',
  'ExtraDDL4',
  'ExtraDDL5',
  'ExtraDDL6',
  'ExtraDDL7',
  'ExtraDDL8',
  'ExtraDDL9',
  'ExtraDDL10',
  'ExtraDDL11',
  'ExtraDDL12',
  'ExtraDDL13',
  'ExtraDDL14',
  'ExtraDDL15',
  'Campus',
  'Range',
  //'Articles',
];
export const DefaultRows: Array<string> = [
  //'ImageName',
  //'DivisionName',
  //'AvailabilityType',
  'Articles',
  'Comments',
];
export const DefaultAttributes: Array<string> = [
  'Id',
  'Name',
  'ImageName',
  'ServiceId',
  'ServiceType',
  'AvailabilityType',
  'CommentsAreStaffonly',
  'ServiceGroup',
];
