import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';
import { getServiceRequestBlockedByInvoicedMessage } from './getServiceRequestBlockedByInvoicedMessage';

/**
 * Warning/Error alert for invoiced service request, e.g. requestblokedbyinvoice
 * @param param0
 * @returns
 */
export function ServiceRequestBlockedByInvoice({
  requestDetails,
}: {
  requestDetails: Pick<IServiceRequestDto, 'Service' | 'Status' | 'InvoiceId'>;
}) {
  const { t } = useTranslation();

  if (requestDetails?.InvoiceId === null || isNaN(requestDetails?.InvoiceId)) {
    return null;
  }

  const { messageKey, variant } =
    getServiceRequestBlockedByInvoicedMessage(requestDetails);

  return (
    <>
      <Alert color={variant}>{t(messageKey)}</Alert>
    </>
  );
}
