import { FormFieldsPerRowVariant } from 'enums/FormFieldsPerRowVariant';
import {
  FormLeftSection,
  StyledFormFieldsContainer,
} from '../Forms/FormsLayout';
import { StyledFormProps } from '../Forms/FormsLayout/StyledForm';

export interface CustomFormAsSectionProps {
  children: React.ReactNode;
  fieldsPerRow?: FormFieldsPerRowVariant;
  asSection?: boolean;
  sectionProps?: Omit<StyledFormProps, 'onSubmit' | 'ref' | 'children'>;
}
export const CustomFormAsSection = ({
  children,
  fieldsPerRow,
  asSection,
  sectionProps,
}: CustomFormAsSectionProps) => {
  return asSection ? (
    <StyledFormFieldsContainer {...sectionProps}>
      <FormLeftSection
        className={
          fieldsPerRow === FormFieldsPerRowVariant.ThreeFields
            ? 'custom-section'
            : undefined
        }
      >
        {children}
      </FormLeftSection>
    </StyledFormFieldsContainer>
  ) : (
    <>{children}</>
  );
};
