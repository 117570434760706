/**
 *
 * ServiceRequestRowsTable
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useDispatch } from 'react-redux';
import { useRequestSamplesSlice } from 'app/pages/Samples/RequestSamplesPage/slice';

import {
  Column,
  Row,
  useExpanded,
  UsePaginationInstanceProps,
  UsePaginationState,
  useTable,
} from 'react-table';
import { AddServiceRequestRowButton } from './components/AddServiceRequestRowButton';
import {
  FieldArray,
  FormikProps,
  ArrayHelpers,
  FastFieldProps,
  FastField,
} from 'formik';
import {
  IResultData,
  IServiceRequestDetailsFormModel,
  IServiceRequestTableRowModel,
  IServiceRequestTableRowValueModel,
} from '../../RequestSamplesPage/slice/types';
import { ColumnTypeExtension, getTableColumns } from './getTableColumns';
import { TableToolbar } from 'app/components/BasicTable/BasicFilter/TableToolbar';
import { UpdateServiceRequestRowValue } from '../ServiceRequestRowEditableCell';
import { serializeCustomFormRowValue } from 'app/components/CustomForm/CustomFormUtils';
import styled from 'styled-components';
import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
} from '@material-ui/core/index';
import TablePagination from '@material-ui/core/TablePagination';
import { usePagination, PluginHook } from 'react-table';
import TablePaginationActions from 'app/components/BasicTable/TablePaginationActions';
import clsx from 'clsx';
import { ServiceRequestRowComments } from './components/ServiceRequestComments';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { ServiceRequestTableColumnsCollection } from '../../RequestSamplesPage/slice/utils/ServiceRequestTableColumn';
import {
  ExportRows,
  ServiceRequestRowsImport,
} from './components/ServiceRequestRowsImport';
import { BulkUpdate } from './components/BulkUpdate';
import { addRowSelect } from 'app/components/BasicTable/ControlledTable/addRowSelect';
import {
  ColumnsSelect,
  getHiddenColumns,
  setHiddenColumns,
} from './components/ColumnsSelect';
import { bookitColors } from 'styles/colors/bookitColors';
import { Body } from 'app/components/Typography';
import { isNullOrUndefined } from 'utils/typeUtils';
import { httpClient } from 'api/HttpClient';
import { AlertContentType } from 'app/components/BasicAlert';
import { Printing } from 'app/components/PrintToPDF';
import { DetectIsMobile } from 'utils/mobileDetect';
import { TableContent, TableRoot } from 'app/components/BasicTable/styled';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { Identifiable } from 'types/common';
import { RowStatusVisibility, RowStatusEditablity } from 'enums/enums';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import { appSettingsActions } from 'app/slice';

export interface IServiceRequestRelated {
  serviceRequestId: number;
}
export interface IServiceRequestTableFormComponent {
  arrayHelpers: ArrayHelpers;
  updateMyData: UpdateServiceRequestRowValue<IServiceRequestTableRowValueModel>;
}

export interface IServiceRequestSelectedRows {
  selectedFlatRows: Array<Row<IServiceRequestTableRowModel>>;
}
export interface ServiceRequestRowsTableProps
  extends IServiceRequestRelated,
    FormikProps<IServiceRequestDetailsFormModel> {
  processing: boolean;
  isEditable: boolean;
  isAdmin: boolean;
  pageSize?: number;
  formFields: ServiceRequestTableColumnsCollection;
  serviceRequestTypeId: number;
  hasErrors?: boolean;
  printing?: Printing;
  reservationEnabled?: boolean;
  openSidePanel: (state: SidePanelOpenState) => void;
  useSidePanel: boolean;
  serviceRequestId: number;
  serviceGroups?: Identifiable<number>[];
  onCreateReservation: (rows: IServiceRequestTableRowModel[]) => void;
  hideRowIdColumn?: boolean;
  rowStatusVisibility?: number;
  rowStatusColumnEditable?: number;
  requestStatusId: InternalServiceRequestStatusesUnion;
}
/**
 * "Custom Form" based table section
 */
export const ServiceRequestRowsTable = React.memo(
  function ServiceRequestRowsTable({
    serviceRequestId,
    isAdmin,
    isEditable,
    formFields: customFormColumns,
    hasErrors,
    openSidePanel,
    requestStatusId,
    hideRowIdColumn,
    rowStatusVisibility,
    rowStatusColumnEditable,
    //pageSize,
    ...props
  }: ServiceRequestRowsTableProps) {
    const { actions } = useRequestSamplesSlice();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const IsStatusColumnVisible = React.useMemo(() => {
      switch (rowStatusVisibility) {
        case RowStatusVisibility.AlwaysVisible:
          return true;
        case RowStatusVisibility.VisibleToAdminOnly:
          return isAdmin;
        case RowStatusVisibility.VisibleToUserOnRequestStepDone:
          return isAdmin || requestStatusId === 'Completed';
        case RowStatusVisibility.Hidden:
        default:
          return false;
      }
    }, [isAdmin, requestStatusId, rowStatusVisibility]);

    const IsStatusColumnEditable = React.useMemo(() => {
      switch (rowStatusColumnEditable) {
        case RowStatusEditablity.AdminsOnly:
          return isAdmin;
        case RowStatusEditablity.UsersAndAdmins:
          return isEditable;
        default:
          return isEditable;
      }
    }, [isAdmin, isEditable, rowStatusColumnEditable]);
    // TODO: server side pagination
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChangeState = React.useCallback(
      changeState => {
        dispatch(
          actions.loadServiceRequestRows({
            serviceRequestId: serviceRequestId,
            state: changeState,
          }),
        );
      },
      [actions, dispatch, serviceRequestId],
    );

    // TODO: server side pagination
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleSelectedRowsChange = React.useCallback(
      rows => {
        dispatch(actions.setSelected(rows));
      },
      [actions, dispatch],
    );
    const print = React.useMemo(() => {
      return props.printing !== undefined && props.printing.printing === true;
    }, [props.printing]);
    React.useEffect(() => {
      if (
        props.printing !== undefined &&
        print &&
        props.printing.onLoading !== undefined
      ) {
        props.printing.onLoading(
          props.printing.id,
          props.values.Rows === undefined || props.values.Rows === null,
        );
      }
    }, [print, props.printing, props.values.Rows]);
    React.useEffect(() => {
      if (print) {
        dispatch(
          actions.loadServiceRequestRows({
            serviceRequestId: serviceRequestId,
            state: {
              pageIndex: 0,
              pageSize: 100,
            },
          }),
        );
      }
    }, [actions, dispatch, print, serviceRequestId]);
    // useEffectOnMount(() => {
    //   if (!!serviceRequestId) {
    //     dispatch(
    //       actions.loadServiceRequestRows({
    //         serviceRequestId: serviceRequestId,
    //         state: {
    //           pageIndex: 0,
    //           pageSize: 10,
    //         },
    //       }),
    //     );
    //   }
    // });

    // const openPanel = React.useCallback(
    //   (state: SidePanelOpenState) => {
    //     props.openSidePanel(state);
    //   },
    //   [props],
    // );

    const tableColumns = React.useMemo<
      Array<Column<IServiceRequestTableRowModel>>
    >(() => {
      return getTableColumns(
        customFormColumns,
        isEditable,
        IsStatusColumnEditable,
        IsStatusColumnVisible,
        hideRowIdColumn ?? false,
        t,
        serviceRequestId,
        props.reservationEnabled,
        props.useSidePanel,
        openSidePanel,
        print,
      );
    }, [
      IsStatusColumnEditable,
      IsStatusColumnVisible,
      customFormColumns,
      hideRowIdColumn,
      isEditable,
      openSidePanel,
      props.reservationEnabled,
      props.useSidePanel,
      serviceRequestId,
      t,
      print,
    ]);

    const updateMyData = React.useCallback<
      UpdateServiceRequestRowValue<IServiceRequestTableRowValueModel>
    >(
      async args => {
        const column = customFormColumns.get(args.columnId);

        // dispatch(
        //   actions.updateServiceRequestRowValue({
        //     serviceRequestId: serviceRequestId,
        //     serviceRequestValue: {
        //       RowId: args.serviceRequestRowId,
        //       ColumnId: column.Id,
        //       ...serializeCustomFormRowValue(args.value),
        //     },
        //   }),
        // );

        const value = serializeCustomFormRowValue(args.value);

        // TODO: migrate value updates to the new api
        // const url = `/api/odata/v4/ServiceRequests(${action.payload.serviceRequestId})/Rows(${action.payload.serviceRequestValue.RowId})/Values`;
        const url = `/api/requests/${serviceRequestId}/tabledata/${args.serviceRequestRowId}/${column.Id}`;
        const payload =
          value.DisplayValue === null
            ? value.Value
            : {
                id: value.Value,
                text: value.DisplayValue,
              };

        try {
          const results = await httpClient.post<IResultData>(
            url,
            JSON.stringify(payload),
            {
              headers: { 'Content-Type': 'application/json' },
            },
          );
          const data: IResultData = results;
          if (!!data.errorMessages && data.errorMessages.length > 0) {
            dispatch(
              appSettingsActions.addNotification({
                variant: 'error',
                message: data.errorMessages[0],
              }),
            );
          }
        } catch (error) {}
      },
      [customFormColumns, dispatch, serviceRequestId],
    );

    if (props.values.Rows === undefined || props.values.Rows === null) {
      return <></>;
    }
    return (
      <>
        <TableRoot
          className={clsx('table-root', {
            'section-table': true,
          })}
          key="table-container"
          id={`rows_table_container`}
        >
          <SRFormContent
            {...props}
            tableColumns={tableColumns}
            isEditable={isEditable}
            isAdmin={isAdmin}
            updateMyData={updateMyData}
            serviceRequestId={serviceRequestId}
            serviceRequestTypeId={props.serviceRequestTypeId}
            onCreateReservation={props.onCreateReservation}
            hasError={hasErrors}
            openSidePanel={openSidePanel}
            print={print}
            onRefresh={state =>
              dispatch(
                actions.loadServiceRequestRows({
                  serviceRequestId: serviceRequestId,
                  state: state,
                }),
              )
            }
          />
        </TableRoot>
      </>
    );
  },
);

interface SRFormContentProps
  extends FormikProps<IServiceRequestDetailsFormModel>,
    IServiceRequestRelated {
  tableColumns: Array<Column<IServiceRequestTableRowModel>>;
  updateMyData: UpdateServiceRequestRowValue<IServiceRequestTableRowValueModel>;
  isEditable: boolean;
  isAdmin: boolean;
  serviceRequestTypeId: number;
  hasError?: boolean;
  reservationEnabled?: boolean;
  openSidePanel: (state: SidePanelOpenState) => void;
  useSidePanel: boolean;
  serviceGroups?: Identifiable<number>[];
  onRefresh: (state: any) => void;
  onCreateReservation: (rows: IServiceRequestTableRowModel[]) => void;
  print: boolean;
}
const SRFormContent = React.memo(function SRFormContent({
  tableColumns,
  updateMyData,
  onCreateReservation,
  print,
  ...props
}: SRFormContentProps) {
  const { t } = useTranslation();
  const isMobile = DetectIsMobile();
  const tablePlugins = React.useMemo(() => {
    const plugins: PluginHook<IServiceRequestTableRowModel>[] = [
      // useSortBy,
      useExpanded,
      usePagination,
    ];
    if (props.isEditable && !print) {
      addRowSelect<IServiceRequestTableRowModel>(plugins);
    }
    return plugins;
  }, [print, props.isEditable]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page,
    prepareRow,
    //pagination
    gotoPage,
    setPageSize,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, pageSize, hiddenColumns },
    selectedFlatRows,
  } = useTable(
    {
      columns: tableColumns,
      data: props.values.Rows ?? [],
      updateMyData: updateMyData,
      autoResetPage: false,
      autoResetExpanded: false,
      initialState: {
        // sortBy: [{ id: 'Id' }],
        hiddenColumns: getHiddenColumns(
          props.serviceRequestTypeId,
          tableColumns,
        ),
      },
    },
    ...tablePlugins,
  );
  React.useEffect(() => {
    setHiddenColumns(props.serviceRequestTypeId, hiddenColumns);
  }, [hiddenColumns, props.serviceRequestTypeId]);
  React.useEffect(() => {
    if (print) {
      setPageSize(1000);
    } else {
      setPageSize(10);
    }
  }, [print, setPageSize]);
  const handleRefresh = () => {
    props.onRefresh({ pageIndex, pageSize });
  };
  return (
    <FieldArray name="Rows" validateOnChange={true}>
      {arrayHelpers => {
        return (
          <>
            <TableToolbar
              screenName={t(translations.ServiceRequestTable)}
              screenNameVariant={'section'}
              showMenu={false}
              titleAsColumn={true}
              onRefresh={!print ? handleRefresh : undefined}
              titleExtended={
                <Body bold size="small">
                  {props.values.Rows !== undefined && (
                    <>
                      {props.values.Rows.length} {t(translations.Records)}
                    </>
                  )}
                </Body>
              }
              alertContent={
                props.hasError
                  ? ({
                      type: 'error',
                      message: t(translations.RequestRows_error) as string,
                      closable: true,
                    } as AlertContentType)
                  : undefined
              }
              showAlert={props.hasError}
            >
              {props.isAdmin &&
                !print &&
                (selectedFlatRows ?? []).length > 0 &&
                props.reservationEnabled && (
                  <TopActionButton
                    variant="white"
                    size="small"
                    icon={'plus'}
                    onClick={() =>
                      onCreateReservation(selectedFlatRows.map(f => f.original))
                    }
                    text={t(translations.CreateReservation)}
                  />
                  // <CreateRequestReservation
                  //   openPanel={props.openSidePanel}
                  //   serviceRequestId={props.serviceRequestId}
                  //   gradient={props.gradient}
                  //   serviceGroupId={props.serviceGroupId}
                  //   serviceRequestRelatedEquipments={
                  //     props.serviceRequestRelatedEquipments
                  //   }
                  //   userName={props.userName}
                  //   budgetId={props.budgetId}
                  //   requestOverheadMinutes={props.requestOverheadMinutes}
                  //   useSidePanel={props.useSidePanel}
                  //   rows={selectedFlatRows.map(f => f.original)}
                  // />
                )}
              {props.isEditable &&
                (selectedFlatRows ?? []).length > 0 &&
                !print && (
                  <BulkUpdate
                    serviceRequestId={props.serviceRequestId}
                    selectedFlatRows={selectedFlatRows ?? []}
                  />
                )}
              {props.isEditable && !isMobile && !print && (
                <ServiceRequestRowsImport
                  serviceRequestId={props.serviceRequestId}
                  arrayHelpers={arrayHelpers}
                  updateMyData={updateMyData}
                />
              )}
              {props.isEditable && !isMobile && !print && (
                <ExportRows
                  url={`/api/requests/${props.serviceRequestId}/exportTable`}
                >
                  {t(translations.Export)}
                </ExportRows>
              )}
              {props.isAdmin && !print && (
                <ColumnsSelect
                  allColumns={allColumns}
                  hiddenColumns={hiddenColumns}
                  toggleHideColumn={toggleHideColumn}
                />
              )}
              {props.isEditable && !print && (
                <AddServiceRequestRowButton
                  serviceRequestId={props.serviceRequestId}
                  disabled={!props.isEditable}
                  arrayHelpers={arrayHelpers}
                  updateMyData={updateMyData}
                />
              )}
            </TableToolbar>
            <TableContent
              className={clsx('table-content', {
                'table-content-short': isMobile,
              })}
            >
              <div className={'table-scrolling'}>
                <Table
                  {...getTableProps()}
                  className={clsx('table', {
                    'with-footer': true,
                  })}
                  size="small"
                >
                  <TableHead>
                    {
                      // Loop over the header rows
                      headerGroups.map(headerGroup => (
                        // Apply the header row props
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map(column => (
                              // Apply the header cell props

                              <TableCell {...column.getHeaderProps()}>
                                {
                                  // Render the header
                                  <TableHeaderCellLabel>
                                    {column.render('Header')}
                                  </TableHeaderCellLabel>
                                }
                              </TableCell>
                            ))
                          }
                        </TableRow>
                      ))
                    }
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                    <SRTableBody
                      page={page}
                      prepareRow={prepareRow}
                      arrayHelpers={arrayHelpers}
                      updateMyData={updateMyData}
                      serviceRequestId={props.serviceRequestId}
                      serviceGroups={props.serviceGroups}
                      print={print}
                    />
                  </TableBody>
                </Table>
              </div>

              {props.values.Rows !== undefined && !print && (
                <div
                  id={`serviceRequest_rows_footer`}
                  className={clsx('table-footer', {
                    'table-footer-border': true,
                  })}
                >
                  <SRTablePagination
                    isEditable={props.isEditable}
                    dataLength={props.values.Rows.length}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    setPageSize={setPageSize}
                  />
                </div>
              )}
            </TableContent>
          </>
        );
      }}
    </FieldArray>
  );
});

interface SRTableBodyProps
  extends IServiceRequestRelated,
    IServiceRequestTableFormComponent {
  page: Array<Row<IServiceRequestTableRowModel>>;
  prepareRow: (row: Row<IServiceRequestTableRowModel>) => void;
  serviceGroups?: Identifiable<number>[];
  print?: boolean;
}
const SRTableBody = React.memo(function SRTableBody({
  page,
  prepareRow,
  arrayHelpers,
  serviceGroups,
  updateMyData,
  print,
}: SRTableBodyProps) {
  return (
    <>
      {page.map(row => {
        prepareRow(row);

        const rowProps = row.getRowProps();
        return (
          <React.Fragment key={`srbody_row_${row.original.Id}`}>
            <TableRow {...rowProps} key={`editable-row-${row.id}`}>
              {row.cells.map((cell, index) => (
                <EditableCellField
                  key={`editable-cell-${row.original.Id}-${index}`}
                  name={(cell.column as ColumnTypeExtension).name?.(cell.row)}
                >
                  {(fieldProps?: FastFieldProps) => {
                    const hasError = !isNullOrUndefined(
                      fieldProps?.meta?.error,
                    );
                    return (
                      <StyledTableCell
                        className={
                          (cell.column as ColumnTypeExtension).name !==
                          undefined
                            ? 'editcell'
                            : undefined
                        }
                        {...cell.getCellProps()}
                        //title={fieldProps?.meta?.error}
                        error={hasError}
                        key={`cell-${row.original.Id}-${index}`}
                      >
                        {cell.render('Cell', {
                          arrayHelpers,
                          updateMyData,
                          fieldProps,
                        })}
                      </StyledTableCell>
                    );
                  }}
                </EditableCellField>
              ))}
            </TableRow>
            {row.isExpanded && !print && (
              <TableRow key={`expand_${rowProps.key}`}>
                <TableCell colSpan={row.cells.length}>
                  <ServiceRequestRowComments
                    commentType={CommentReferenceTypes.ServiceRequestRow}
                    referenceId={row.original.Id}
                    serviceRequestId={row.original.ServiceRequestId}
                    row={row}
                    serviceGroups={serviceGroups}
                  />
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
});

interface SRTableCellProps {
  error?: boolean;
  title?: string;
  key?: string;
  className?: string;
  children: React.ReactNode;
}
function SRTableCell({
  error,
  children,
  title,
  key,
  className,
  ...other
}: React.PropsWithChildren<SRTableCellProps>) {
  return (
    <TableCell key={`cell_${key}`} className={className} {...other}>
      {children}
    </TableCell>
  );
}
const StyledTableCell = styled(SRTableCell)`
  background: ${props =>
    props.error ? bookitColors.danger.background : 'transparent'};

  border-bottom: ${props => (props.error ? '1px solid #F78F8B' : 'unset')};
  &.editcell:hover {
    background: ${props =>
      props.error
        ? bookitColors.danger.bgHover
        : bookitColors.grayscale.grayBackground};
    cursor: pointer;
    & > * {
      cursor: pointer;
      & > * {
        cursor: pointer;
        & > * {
          cursor: pointer;
          & > * {
            cursor: pointer;
          }
        }
      }
    }
  }
  > .Mui-focused & {
    border: 1px solid ${bookitColors.primary.regular};
    background: ${bookitColors.grayscale.grayBackground};
  }
  &:focus-within {
    border: 1px solid ${bookitColors.primary.regular};
    background: ${bookitColors.grayscale.grayBackground};
  }
`;
// box-shadow: ${props =>
//   props.error
//     ? '-1px 0px 0px #e0e5ec, 0px 1px 0px #C42E52'
//     : '-1px 1px 0px #e0e5ec'};
const TableHeaderCellLabel = styled('span')`
  white-space: break-spaces;
  word-break: normal;
`;
interface SRTablePaginationProps
  extends Pick<
      UsePaginationState<IServiceRequestTableRowModel>,
      'pageSize' | 'pageIndex'
    >,
    Pick<
      UsePaginationInstanceProps<IServiceRequestTableRowModel>,
      'gotoPage' | 'setPageSize'
    > {
  isEditable: boolean;
  dataLength: number;
}
const SRTablePagination = React.memo(function SRTablePagination(
  props: SRTablePaginationProps,
) {
  return (
    <TablePagination
      component="div"
      rowsPerPageOptions={[5, 10, 25]}
      /*colSpan={tableColumns.length + (props.isEditable ? 1 : 0)}*/
      count={props.dataLength}
      rowsPerPage={props.pageSize}
      page={props.pageIndex}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      }}
      onChangePage={(event, newPage) => props.gotoPage(newPage)}
      onChangeRowsPerPage={event =>
        props.setPageSize(Number(event.target.value))
      }
      ActionsComponent={TablePaginationActions}
    />
  );
});

interface EditableCellProps {
  name?: string;
  children: (fieldProps?: FastFieldProps) => React.ReactElement;
}
function EditableCellField({
  name,
  ...props
}: EditableCellProps): React.ReactElement {
  if (name === undefined) {
    return props.children(undefined);
  } else {
    return <FastField name={name} key={name} {...props} />;
  }
}
