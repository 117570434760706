import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { GlobalSettingsType } from '../components/useGlobalSettingsHook';
import { IReservationEquipmentDto } from './types';

/**
 * This function determines if the budgets are enabled and should be visible for particular online services selection
 * @param globalSettings global settings
 * @param equipments related online services
 * @returns
 */

export function getBudgetVisible(
  globalSettings: Pick<
    GlobalSettingsType,
    | 'billingModuleEnabled'
    | 'budgetModuleEnabled'
    | 'priceSheetPricing'
    | 'projectsAreOptional'
  >,
  equipments: Array<
    Pick<
      IReservationEquipmentDto,
      'HideProjects' | 'BudgetsTurnedOn' | 'Billable'
    > & { ServiceTypeId: number }
  >,
): boolean {
  if (!globalSettings.budgetModuleEnabled) {
    return false;
  }

  if (globalSettings.billingModuleEnabled) {
    // Billable is only shown on the instrument details when the PriceSheetPricing is off.
    // Otherwise the instruments will with Billable = false, which should be ignored since they are in fact billable
    // on reservationEdit Billable might have "true" which is different from the actual value in db/model
    if (globalSettings.priceSheetPricing === false) {
      if (equipments.every(f => f.Billable === false)) {
        return false;
      }
    }

    // budgets turned off on service group level
    if (equipments.every(f => f.BudgetsTurnedOn === false)) {
      return false;
    }

    // ???
    if (equipments.some(f => f.ServiceTypeId !== ServiceType.Online)) {
      return false;
    }
  }

  // HideProjects is enabled only when ProjectsAreOptional is turned on in the system settings
  if (globalSettings.projectsAreOptional) {
    if (equipments.every(f => f.HideProjects)) {
      return false;
    }
  }
  return true;
}
