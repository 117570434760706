/**
 *
 * ServiceRequestSubmissionDetails
 *
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { ServiceRequestStatusPicker } from 'app/components/pickers/AutocompletePickers/ServiceRequestStatusPicker';
import { useDispatch, useSelector } from 'react-redux';
import { selectRequestDetailsFieldsState } from '../../RequestSamplesPage/slice/selectors';
import { useRequestSamplesSlice } from '../../RequestSamplesPage/slice';
import { EventBudgetPicker } from '../EventBudgetPicker';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { dateUtils } from 'utils/date-utils';
import { ReadonlyField } from '../ReadonlyField';
import { useServiceRequestStatusUpdate } from '../Utils';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import {
  selectAppSettings,
  selectAuthenticatedUser,
} from 'app/slice/selectors';
import { IRequestDetailsModel } from '../../RequestSamplesPage/slice/types';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { IsBudgetFieldEnabled, IsModuleEnabled } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { BudgetFields } from 'enums/BudgetFields';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import {
  FundingTypeIdPicker,
  getSingleInvoiceFundingType,
} from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import BaseTextField from 'app/components/BasicInputs/BaseTextField';
import { appSettingsActions } from 'app/slice';
import { debounce } from 'lodash';
import { InternalServiceRequestStatus } from 'api/odata/generated/enums/InternalServiceRequestStatus';

export interface ServiceRequestSubmissionDetailsProps {
  requestDetails: IRequestDetailsModel;
  isEditable: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  print: boolean;
  fullDetails: boolean;
}

export function ServiceRequestSubmissionDetails({
  isEditable,
  requestDetails,
  print,
  fullDetails,
  ...props
}: ServiceRequestSubmissionDetailsProps) {
  const { t } = useTranslation();
  const fieldsState = useSelector(selectRequestDetailsFieldsState);
  const { actions } = useRequestSamplesSlice();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthenticatedUser);
  const appSettings = useSelector(selectAppSettings);
  const globalSettings = useGlobalSettingsHook();
  const [poLocal, setPoLocal] = React.useState<string | null>(
    requestDetails.PurchaseOrder,
  );
  const [internalLocal, setInternalLocal] = React.useState<string | null>(
    requestDetails.ExternalInternalNumber || null,
  );
  const showProject = React.useMemo(() => {
    return (
      IsModuleEnabled(appSettings, KnownModules.Budgets) &&
      ((IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents) &&
        requestDetails.Service.HideProjects === false) ||
        !IsModuleEnabled(appSettings, KnownModules.DisableBillingEvents))
    );
  }, [appSettings, requestDetails.Service.HideProjects]);
  const projectFieldVisibleToUser = React.useMemo(() => {
    return (
      showProject &&
      (requestDetails.Service.ProjectIsRequired === null ||
      requestDetails.Service.ProjectIsRequired === true
        ? true
        : props.isAdmin &&
          ![
            InternalServiceRequestStatus[InternalServiceRequestStatus.Draft],
            InternalServiceRequestStatus[
              InternalServiceRequestStatus.ReturnedToUser
            ],
            InternalServiceRequestStatus[InternalServiceRequestStatus.Pending],
          ].includes(requestDetails.Status.InternalStatusId))
    );
  }, [
    props.isAdmin,
    requestDetails.Service.ProjectIsRequired,
    requestDetails.Status.InternalStatusId,
    showProject,
  ]);
  const projectIsRequired = React.useMemo(() => {
    return (
      projectFieldVisibleToUser &&
      (requestDetails.Service.ProjectIsRequired === null ||
        requestDetails.Service.ProjectIsRequired === false ||
        (requestDetails.Service.ProjectIsRequired === true &&
          ![
            InternalServiceRequestStatus[InternalServiceRequestStatus.Draft],
            InternalServiceRequestStatus[
              InternalServiceRequestStatus.ReturnedToUser
            ],
            InternalServiceRequestStatus[InternalServiceRequestStatus.Pending],
          ].includes(requestDetails.Status.InternalStatusId)))
    );
  }, [
    projectFieldVisibleToUser,
    requestDetails.Service.ProjectIsRequired,
    requestDetails.Status.InternalStatusId,
  ]);
  const fundingTypeVisible = React.useMemo(() => {
    return (
      IsBudgetFieldEnabled(appSettings, BudgetFields.FundingType) &&
      IsBudgetFieldEnabled(appSettings, BudgetFields.PurchaseOrderOption) &&
      projectFieldVisibleToUser
    );
  }, [appSettings, projectFieldVisibleToUser]);

  const purchaseOrderLength = React.useMemo(() => {
    return globalSettings.purchaseOrderLengthSetting ?? 0;
  }, [globalSettings.purchaseOrderLengthSetting]);

  const purchaseOrderVisible = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      (requestDetails.Budget?.PurchaseOrderOptionId ??
        PurchaseOrderOptions.Hidden) !== PurchaseOrderOptions.Hidden
    );
  }, [
    globalSettings.purchaseOrderBudgetEnabled,
    requestDetails.Budget?.PurchaseOrderOptionId,
  ]);
  const purchaseOrderMandatory = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      requestDetails.Budget?.PurchaseOrderOptionId ===
        PurchaseOrderOptions.Mandatory
    );
  }, [
    globalSettings.purchaseOrderBudgetEnabled,
    requestDetails.Budget?.PurchaseOrderOptionId,
  ]);
  const purchaseLengthInvalid = React.useMemo(() => {
    return (
      purchaseOrderVisible &&
      (purchaseOrderMandatory ||
        (!purchaseOrderMandatory && (poLocal?.length ?? 0) > 0)) &&
      (poLocal?.length ?? 0) < purchaseOrderLength
    );
  }, [
    purchaseOrderLength,
    purchaseOrderMandatory,
    purchaseOrderVisible,
    poLocal?.length,
  ]);
  const purchaseOrderRequired = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      requestDetails.Budget?.PurchaseOrderOptionId ===
        PurchaseOrderOptions.Mandatory &&
      poLocal === null
    );
  }, [
    globalSettings.purchaseOrderBudgetEnabled,
    requestDetails.Budget?.PurchaseOrderOptionId,
    poLocal,
  ]);

  const excludedQuoteStatuses: Array<InternalServiceRequestStatusesUnion> = [
    'QuoteSendToUser',
    'QuoteApproved',
    'WaitingForQuote',
  ];

  useEffectOnMount(() => {
    dispatch(actions.init());
  });
  const { changeStatus, fieldState: statusFieldState } =
    useServiceRequestStatusUpdate({
      serviceRequestStatusId: requestDetails.Id,
    });
  const handleBudgetChange = value => {
    if (value !== null) {
      dispatch(
        actions.updateBudget({
          Id: requestDetails.Id,
          Budget: value,
        }),
      );
      if (
        requestDetails.FundingTypeId === null &&
        value.FundingTypeId !== null &&
        fundingTypeVisible
      ) {
        dispatch(
          actions.patch({
            Id: requestDetails.Id,
            FundingTypeId: value.FundingTypeId,
          }),
        );
      }
    }
  };
  const handleFundingTypeChange = value => {
    value !== null &&
      dispatch(
        actions.patch({
          Id: requestDetails.Id,
          FundingTypeId: value.Id,
          Budget: undefined,
          PurchaseOrder: null,
        }),
      );
    // if (value?.Id !== requestDetails.Budget?.FundingTypeId) {
    //   //setChosenBudget(null);
    // }
  };
  const handlePurchaseOrderSave = React.useCallback(
    value => {
      if (
        (value?.length ?? 0) === 0 ||
        (value?.length ?? 0) >= purchaseOrderLength
      ) {
        dispatch(
          actions.patch({
            Id: requestDetails.Id,
            PurchaseOrder: value,
          }),
        );
        dispatch(
          appSettingsActions.addNotification({
            message: `${t(translations.PurchaseOrder)} updated to: ${
              value ?? 'null'
            }`,
            variant: 'success',
          }),
        );
      }
    },
    [actions, dispatch, purchaseOrderLength, requestDetails.Id, t],
  );
  const debouncedPoChange = React.useMemo(
    () => debounce(handlePurchaseOrderSave, 500),
    [handlePurchaseOrderSave],
  );
  const handlePurchaseOrderChange = value => {
    setPoLocal(value);
    debouncedPoChange(value);
  };

  const handleStatusChange = value => {
    changeStatus({
      status: value,
    });
  };
  const handleExternalInternalNumberSave = React.useCallback(
    value => {
      dispatch(
        actions.patch({
          Id: requestDetails.Id,
          ExternalInternalNumber: value,
        }),
      );
      dispatch(
        appSettingsActions.addNotification({
          message: t(translations.ExternalInternalNumberUpdatedTo, {
            Name: value as string,
          }),
          variant: 'success',
        }),
      );
    },
    [actions, dispatch, requestDetails.Id, t],
  );
  const debouncedInterChange = React.useMemo(
    () => debounce(handleExternalInternalNumberSave, 500),
    [handleExternalInternalNumberSave],
  );
  const handleExternalInternalNumberChange = value => {
    setInternalLocal(value);
    debouncedInterChange(value);
  };
  return (
    <React.Fragment>
      {(fullDetails || print) && (
        <StyledFormFieldsContainer>
          <FormLeftSection>
            <FormFieldsSection
              useRequest={true}
              titleSection={t(translations.ServiceRequestSubmissionDetails)}
            >
              <FormRow>
                <ReadonlyField
                  title={t(translations.UserGroup)}
                  value={requestDetails.UserGroup?.Name}
                />
              </FormRow>
              <FormRow>
                <ReadonlyField
                  title={t(translations.CreatedFor)}
                  value={requestDetails.CreatedFor?.Name}
                />
              </FormRow>
              <FormRow>
                <ReadonlyField
                  title={t(translations.CreatedBy)}
                  value={requestDetails.CreatedBy?.Name}
                />
              </FormRow>
              <FormRow>
                <ReadonlyField
                  title={t(translations.SubmittedBy)}
                  value={requestDetails.SubmittedBy?.Name}
                />
              </FormRow>
              <FormRow>
                <ReadonlyField
                  title={t(translations.SubmittedAt)}
                  value={dateUtils.longDateTimeFormat(
                    requestDetails.SubmittedAt,
                  )}
                />
              </FormRow>
              <FormRow>
                <ReadonlyField
                  title={t(translations.UpdatedBy)}
                  value={requestDetails.UpdatedBy?.Name}
                />
              </FormRow>
              <FormRow>
                <ReadonlyField
                  title={t(translations.UpdatedAt)}
                  value={dateUtils.longDateTimeFormat(requestDetails.UpdatedAt)}
                />
              </FormRow>
            </FormFieldsSection>
          </FormLeftSection>
          <FormRightSection useTopSpacing={false}>
            <FormFieldsSection
              useRequest={true}
              titleSection={t(translations.StatusAndBudget)}
            >
              <FormRow fullRow>
                {props.isAdmin && !print && isEditable ? (
                  <ServiceRequestStatusPicker
                    label={t(translations.Status)}
                    predicates={
                      !requestDetails.Service.SendQuoteToUser
                        ? [
                            new Condition<IServiceRequestStatusDto>(
                              'InternalStatusId',
                              ODataOperators.Excludes,
                              excludedQuoteStatuses,
                            ),
                          ]
                        : undefined
                    }
                    value={requestDetails.Status}
                    disabled={statusFieldState?.status === 'pending'}
                    disableClearable={true}
                    onChange={handleStatusChange}
                    fullWidth
                    variant="filled"
                  />
                ) : (
                  <ReadonlyField
                    title={t(translations.Status)}
                    value={requestDetails.Status?.Name}
                  />
                )}
              </FormRow>
              {fundingTypeVisible && (
                <FormRow fullRow>
                  {(props.isAdmin ||
                    (props.isOwner && projectFieldVisibleToUser)) &&
                  !print ? (
                    <FundingTypeIdPicker
                      name="FundingType"
                      id="request_FundingType"
                      label={t(translations.FundingType)}
                      variant="filled"
                      value={
                        requestDetails.FundingTypeId === null
                          ? requestDetails.Budget === null ||
                            requestDetails.Budget.FundingTypeId === null
                            ? null
                            : getSingleInvoiceFundingType(
                                requestDetails.Budget.FundingTypeId,
                              )
                          : getSingleInvoiceFundingType(
                              requestDetails.FundingTypeId,
                            )
                      }
                      error={Boolean(
                        fundingTypeVisible &&
                          requestDetails.FundingTypeId === null &&
                          projectIsRequired,
                      )}
                      helperText={
                        fundingTypeVisible &&
                        requestDetails.FundingTypeId === null &&
                        projectIsRequired
                          ? t(translations.FundingTypeRequired)
                          : undefined
                      }
                      disabled={
                        fieldsState['FundingTypeId']?.status === 'pending'
                      }
                      onChange={value => {
                        handleFundingTypeChange(value);
                      }}
                      fullWidth
                    />
                  ) : (
                    <ReadonlyField
                      title={t(translations.FundingType)}
                      value={
                        requestDetails.FundingTypeId === null
                          ? requestDetails.Budget === null ||
                            requestDetails.Budget.FundingTypeId === null
                            ? 'N/A'
                            : getSingleInvoiceFundingType(
                                requestDetails.Budget.FundingTypeId,
                              ).Name
                          : getSingleInvoiceFundingType(
                              requestDetails.FundingTypeId,
                            ).Name
                      }
                    />
                  )}
                </FormRow>
              )}
              {projectFieldVisibleToUser && (
                <FormRow fullRow>
                  {(props.isAdmin || props.isOwner) && !print && isEditable ? (
                    <EventBudgetPicker
                      userId={requestDetails.CreatedFor?.Id ?? authUser?.Id}
                      userGroupId={
                        requestDetails.UserGroup?.Id ??
                        authUser?.ActiveUserGroup?.Id
                      }
                      isAdmin={props.isAdmin}
                      service={requestDetails.Service}
                      id="request_Budget"
                      label={t(translations.BudgetNumber)}
                      value={requestDetails.Budget}
                      onChange={handleBudgetChange}
                      disabled={fieldsState['Budget']?.status === 'pending'}
                      fundingType={requestDetails.FundingTypeId}
                      fullWidth
                      variant="filled"
                      error={Boolean(
                        (requestDetails.Budget === null ||
                          requestDetails.Budget === undefined) &&
                          projectIsRequired,
                      )}
                      helperText={
                        (requestDetails.Budget === null ||
                          requestDetails.Budget === undefined) &&
                        projectIsRequired
                          ? t(translations.BudgetIsRequired)
                          : undefined
                      }
                    />
                  ) : (
                    <ReadonlyField
                      title={t(translations.Budget)}
                      value={requestDetails.Budget?.Name}
                    />
                  )}
                </FormRow>
              )}
              {purchaseOrderVisible && (
                <FormRow fullRow>
                  {(props.isAdmin || props.isOwner) && !print ? (
                    <BaseTextField
                      id="requestPurchaseOrder"
                      name="PurchaseOrder"
                      label={t(translations.PurchaseOrder)}
                      fullWidth
                      variant="filled"
                      error={Boolean(
                        purchaseLengthInvalid || purchaseOrderRequired,
                      )}
                      helperText={
                        purchaseLengthInvalid
                          ? (
                              t(
                                translations.Reservation_Error_ShortPurchaseOrderLength,
                              ) as string
                            ).replace('{0}', purchaseOrderLength.toString())
                          : purchaseOrderRequired
                          ? t(
                              translations.Reservation_Error_PurchaseOrderRequired,
                            )
                          : undefined
                      }
                      onChange={ev => {
                        let val = ev.target.value;
                        handlePurchaseOrderChange(val === '' ? null : val);
                      }}
                      value={poLocal ?? ''}
                      disabled={
                        fieldsState['PurchaseOrder']?.status === 'pending'
                      }
                    />
                  ) : (
                    <ReadonlyField
                      title={t(translations.PurchaseOrder)}
                      value={poLocal}
                    />
                  )}
                </FormRow>
              )}
              {requestDetails.ShowInternalNumber && (
                <FormRow fullRow>
                  {props.isAdmin && !print ? (
                    <BaseTextField
                      id="requestExternalInternalNumber"
                      name="ExternalInternalNumber"
                      label={t(translations.ExternalInternalNumber)}
                      fullWidth
                      variant="filled"
                      onChange={ev => {
                        let val = ev.target.value;
                        handleExternalInternalNumberChange(
                          val === '' ? null : val,
                        );
                      }}
                      value={internalLocal ?? ''}
                      disabled={
                        fieldsState['ExternalInternalNumber']?.status ===
                        'pending'
                      }
                    />
                  ) : (
                    <ReadonlyField
                      title={t(translations.ExternalInternalNumber)}
                      value={internalLocal}
                    />
                  )}
                </FormRow>
              )}
            </FormFieldsSection>
          </FormRightSection>
        </StyledFormFieldsContainer>
      )}
    </React.Fragment>
  );
}
