import { createSelector } from '@reduxjs/toolkit';
import {
  selectAuthenticatedUser,
  selectKnownModule,
} from 'app/slice/selectors';
import { Roles } from 'api/odata/generated/enums/Roles';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';

import { RootState } from 'types';
import { initialState } from '.';
import { InternalServiceRequestStatusesUnion } from 'enums/InternalServiceRequestStatuses';
import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { SpecialColumnTypesUnion } from 'api/odata/generated/enums/SpecialColumnTypes';
import { tryParseBool, tryParseFloat, tryParseInt } from 'utils/string-utils';
import { dateUtils } from 'utils/date-utils';
import { assertExhaustive } from 'utils/assertExhaustive';
import {
  isEmptyOrWhitespace,
  toUndefinedIfEmptyOrWhitespace,
} from 'utils/typeUtils';
import { ServiceRequestTableColumnsCollection } from './utils/ServiceRequestTableColumn';
import { IServiceRequestDetailsFormModel } from './types';
import { KnownModules } from 'types/KnownModules';

const selectSlice = (state: RootState) => state.requestSamples || initialState;

export const selectRequestSamples = createSelector(
  [selectSlice],
  state => state,
);
export const selectServiceRequestStatuses = createSelector(
  [selectSlice],
  state => state.ServiceRequestStatuses,
);
export const selectServiceRequestRowStatues = createSelector(
  [selectSlice],
  state => state.ServiceRequestRowStatuses,
);
export const selectSubmitting = createSelector(
  [selectSlice],
  state => state.submitting,
);

export const selectServiceRequestStatusesByInternalId = createSelector(
  [
    selectServiceRequestStatuses,
    (
      state,
      serviceRequestInternalStatus: InternalServiceRequestStatusesUnion,
    ) => serviceRequestInternalStatus,
  ],
  (state, serviceRequestInternalStatus: InternalServiceRequestStatusesUnion) =>
    state.value?.filter(
      status => status.InternalStatusId === serviceRequestInternalStatus,
    ),
);
export const selectServiceRequestId = createSelector(
  [selectRequestSamples],
  state => state.serviceRequestId,
);
export const selectRequestDetails = createSelector(
  [selectRequestSamples],
  state => state.RequestDetails,
);
export const selectCommentIsInternal = createSelector(
  [selectRequestDetails],
  state => state.value?.CommentIsInternal,
);
export const selectRequestDetailsData = createSelector(
  [selectRequestSamples],
  state => state.RequestDetails?.value,
);
export const selectServiceRequestCustomForm = createSelector(
  [selectRequestDetailsData],
  state => state?.FormValues,
);
export const selectServiceRequestCustomTable = createSelector(
  [selectRequestDetailsData],
  state => state?.Rows,
);
export const selectServiceRequestFormData = createSelector(
  [selectServiceRequestCustomForm, selectServiceRequestCustomTable],
  (formValues, rows) => {
    const result: IServiceRequestDetailsFormModel = {
      FormValues: formValues,
      Rows: rows,
    };
    return result;
  },
);
export const selectRequestDetailsFieldsState = createSelector(
  [selectRequestSamples],
  state => state.fieldsState,
);
export const selectServiceRequestDetailsFieldState = createSelector(
  [
    selectRequestDetailsFieldsState,
    (state, fieldName: keyof IServiceRequestDto) => fieldName,
  ],
  (state, fieldName) => state[fieldName],
);
const selectService = createSelector([selectRequestDetailsData], state => {
  return state?.Service;
});
const selectCreatedFor = createSelector([selectRequestDetailsData], state => {
  return state?.CreatedFor;
});
export const selectIsServiceRequestAdmin = createSelector(
  [
    selectService,
    selectCreatedFor,
    selectRequestDetails,
    selectAuthenticatedUser,
  ],
  (Service, CreatedFor, serviceRequestDetails, authenticatedUser) => {
    const isInvoiced = serviceRequestDetails?.value?.InvoiceId !== null;
    const none = { isAdmin: false, isOwner: false, isEditable: false };
    if (Service === undefined) {
      return none;
    }
    if (authenticatedUser === undefined) {
      return none;
    }

    if (authenticatedUser.Roles.includes(Roles.Administrators)) {
      return {
        isAdmin: true,
        isOwner: false,
        isEditable:
          Service.MilestonesAddonsType === 'Milestones' ? !isInvoiced : true,
      };
    }

    const isAdmin = authenticatedUser.IsAdminOf({
      ServiceGroupId: Service.ServiceGroup.Id,
      ServiceId: Service.Id,
      ServiceTypeId: ServiceType.Sample,
    });

    const isOwner = CreatedFor?.Id === authenticatedUser?.Id;
    const userEditableStatuses: Array<InternalServiceRequestStatusesUnion> = [
      'Draft',
      'ReturnedToUser',
      'QuoteSendToUser',
    ];
    const internalStatusId =
      serviceRequestDetails?.value?.Status?.InternalStatusId;
    // todo: check equipment administrator permissions
    const isEditable =
      Service.MilestonesAddonsType === 'Milestones'
        ? !isInvoiced
        : isAdmin ||
          (isOwner &&
            internalStatusId !== undefined &&
            userEditableStatuses.includes(internalStatusId));
    return { isAdmin, isOwner, isEditable };
  },
);
export const selectSamplesTableState = createSelector(
  [selectSlice],
  state => state.SamplesTableState,
);
export const selectSamplesData = createSelector(
  [selectRequestSamples],
  state => state.RequestSamples,
);
export const selectImportState = createSelector(
  [selectRequestSamples],
  state => state.ImportState,
);
export const selectSelectedSamples = createSelector(
  [selectRequestSamples],
  state => state.SamplesSelected,
);
const selectCustomForm = createSelector(
  [selectService],
  state => state?.CustomForm,
);
const selectCustomFormFields = createSelector(
  [selectRequestDetailsData],
  state => state?.Service.CustomForm.FormFields,
);
export const selectServiceRequestCustomFormColumns = createSelector(
  [
    selectCustomForm,
    selectCustomFormFields,
    selectIsServiceRequestAdmin,
    state => selectKnownModule(state, KnownModules.SampleManagementTables),
  ],
  (customForm, state, isAdmin, SampleManagementTables) => {
    return new ServiceRequestTableColumnsCollection(
      state,
      isAdmin.isAdmin,
      customForm?.VisibleToAdminOnly,
      customForm?.LanesEnabled || SampleManagementTables,
    );
  },
);

export const selectServiceRequestCustomFormFields = createSelector(
  [selectRequestDetails],
  state =>
    state.value?.Service?.CustomForm?.FormFields?.filter(
      f => f.Active === true,
    )?.filter(column => column.IsColumn === false) ?? [],
);

export const selectServiceRequestMilestones = createSelector(
  [selectRequestDetails],
  state => state.value?.Milestones,
);
export const selectServiceRequestMilestoneProcessing = createSelector(
  [selectSlice],
  state => state.milestoneProcessing,
);
export const selectLastInsertedMilestoneId = createSelector(
  [selectSlice],
  state => state.lastInsertedMilestoneId,
);

export const selectServiceRequestSidePanel = createSelector(
  [selectSlice],
  state => state.SidePanel,
);

export const selectServiceRequestRows = createSelector(
  [selectSlice],
  state => state.ServiceRequestRows,
);

export function fromDtoValue(
  type: SpecialColumnTypesUnion,
  formValueDto:
    | Pick<IFormValueDto, 'Value' | 'DisplayValue'>
    | null
    | undefined,
) {
  if (formValueDto === null) {
    return null;
  }
  if (formValueDto === undefined) {
    return undefined;
  }
  switch (type) {
    case 'CheckBox':
      return tryParseBool(formValueDto.Value);
    case 'Date':
      return dateUtils.tryFormatIso(dateUtils.tryParse(formValueDto.Value));
    case 'Disclaimer':
    case 'Dictionary':
    case 'Index1':
    case 'Index2':
    case 'Label':
    case 'Lane':
    case 'Plate':
    case 'PlateCell':
    case 'RunLane':
    case 'SequencingModes':
    case 'Name':
    case 'SourceAndLabel':
    case 'Source':
      return undefined;
    case 'UserSelection':
    case 'DropDownList':
      if (isEmptyOrWhitespace(formValueDto.Value)) {
        return undefined;
      } else {
        return {
          Id: formValueDto.Value,
          Name:
            toUndefinedIfEmptyOrWhitespace(formValueDto.DisplayValue) ??
            formValueDto.Value,
        };
      }
    case 'ShortText':
    case 'Serial':
    case 'Email':
      return formValueDto.Value;
    case 'PositiveInteger':
      return tryParseInt(formValueDto.Value);
    case 'Number':
    case 'PositiveDecimal':
      return tryParseFloat(formValueDto.Value);

    default:
      assertExhaustive(type);
  }
}

export const selectServiceRequestRowsForm = createSelector(
  [selectSlice],
  state => state.ServiceRequestRows?.value,
);

export const selectServiceRequestRowInsertStatus = createSelector(
  [selectSlice],
  state => state.createServiceRequestRowStatus,
);
